/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Tooltip,
  Icon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import IconButton from '@material-ui/core/IconButton';
import { Alert } from '@material-ui/lab';
import KeywordsCheckbox from './KeywordsCheckbox.jsx';
import Data from '../../../assets/images/icons/Data.png';
import Print from '../../../assets/images/icons/Print.png';
// import Screening from './Screening.jsx';
// import PhysicianMap from './PhysicianMap.jsx';
// import KBCA from './KBCA.jsx';
// import Despositions from './Despositions.jsx';
// import KnowledgeArticle from './KnowledgeArticle.jsx';
// import Opportunity from './Opportunity.jsx';
import Details from '../Details.jsx';
import {
  fetchCreateKeywords,
  fetchCurrentSim,
  decreaseStep,
  addKeywords,
  reset,
} from '../../../redux/sims/actions';
import { saveKeywords, uploadKeywordExcel } from '../../../services/result.service';
import LoadCase from './LoadCase';
import { sfReset, setDataLoaded } from '../../../redux/sf/actions';
import {
  useNavbarStyles,
  useTabContentStyles,
  useToolbarStyles,
} from './Keywords';

const EnhancedTableToolbar = (props) => {
  const [showModal, setShowModal] = useState(false);
  // const useQuery = () => new URLSearchParams(useLocation().search);
  // const query = useQuery();
  // const history = useHistory();
  const {
    type, typeChange, decreaseStep, sfReset, handleExcelUpload, openAlert,
    alertSeverity, alertMesage,
  } = props;
  const handleClick = () => {
    decreaseStep();
    sfReset();
  };
  const handleNext = async () => {
    props.layoutData.forEach((layout) => {
      delete layout._id;
      layout.tabs.forEach((keyword) => {
        delete keyword._id;
        keyword.sections.forEach((field) => {
          delete field._id;
          field.fields.forEach((element) => {
            delete element._id;
          });
        });
      });
    });

    const requestData = {};
    requestData.sim_id = props.newSim.id;
    requestData.layouts = props.layoutData;

    await saveKeywords(requestData);
    // props.reset();
    // props.sfReset();
    localStorage.setItem('sim_id', requestData.sim_id);
    const data = JSON.stringify({ sim_id: requestData.sim_id });
    localStorage.setItem('state', data);
  };
  const [currentAlignment, setCurrentAlignment] = React.useState('case');
  const classes = useToolbarStyles();
  useEffect(() => {
    setCurrentAlignment(type);
  }, [type]);
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Data Input
      </Typography>
      {openAlert ? (
        <Alert
          variant="filled"
          severity={alertSeverity}
          className={classes.errorAlert}
        >
          {alertMesage}
        </Alert>
      ) : null}
      <ToggleButtonGroup
        value={currentAlignment}
        onChange={(event, newAlignment) => {
          setCurrentAlignment(newAlignment);
        }}
        exclusive
        aria-label="text alignment"
        className={classes.toggleBtn}
      >
        <ToggleButton
          value="case"
          aria-label="left aligned"
          onClick={() => { typeChange('case'); }}
        >
          Case
        </ToggleButton>
        <ToggleButton
          value="account"
          aria-label="right aligned"
          onClick={() => { typeChange('account'); }}
        >
          Account
        </ToggleButton>
      </ToggleButtonGroup>
      <Tooltip
        title="Upload Excel"
        placement="top"
      >
        <IconButton
          onClick={handleExcelUpload}
          color="primary"
          className={classes.icon}
        >
          <ArrowUpwardIcon />
        </IconButton>
      </Tooltip>
      {/* modal to load case from salesforce proxy */}
      {showModal && (
        <LoadCase showModal={showModal} setShowModal={setShowModal} />
      )}
      { type === 'case'
      && (
      <IconButton className={classes.icon} onClick={() => setShowModal(true)}>
        <Icon>
          <img src={Data} height={20} width={20} alt="data" />
        </Icon>
      </IconButton>
      )}

      <IconButton className={classes.icon} style={{ marginRight: '1.5rem' }}>
        <Icon>
          <img src={Print} height={20} width={20} alt="print" />
        </Icon>
      </IconButton>
      <Divider orientation="vertical" flexItem style={{ marginTop: '5px' }} />
      <Button
        variant="outlined"
        color="primary"
        style={{ margin: '0rem 1rem', width: '43px', minWidth: '37px' }}
        disableElevation
        onClick={handleClick}
      >
        <KeyboardBackspaceOutlinedIcon />
      </Button>
      <Link
        to={{
          pathname: '/sims',
        }}
        target="_blank"
      >
        <Tooltip title="Save & Preview">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            className={classes.saveBtn}
            onClick={handleNext}
          >
            PREVIEW
          </Button>
        </Tooltip>
      </Link>
      <Divider />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  decreaseStep: PropTypes.func,
  newSim: PropTypes.object,
  sfReset: PropTypes.func,
  typeChange: PropTypes.func,
  layoutData: PropTypes.array,
  type: PropTypes.string,
  handleExcelUpload: PropTypes.func,
  openAlert: PropTypes.bool,
  alertSeverity: PropTypes.string,
  alertMesage: PropTypes.string,
};

const Navbar = ({
  keywords, addKeywords, layoutData, layoutIndex,
}) => {
  const activeTabColorChange = {
    backgroundColor: 'white',
    color: 'rgb(69, 66, 158)',
    borderRadius: '3px',
  };
  const [value, setValue] = React.useState('0');
  const [tabId, setTabId] = React.useState(keywords[0]._id);
  const handleTabs = (event, value) => {
    setValue(value);
  };
  const handleTabStyle = (tab) => {
    setTabId(tab._id);
  };
  useEffect(() => {
    setValue('0');
    setTabId(keywords[0]._id);
    // eslint-disable-next-line
  }, [layoutIndex]);
  const classes = useNavbarStyles();
  return (
    <TabContext value={keywords.length > parseInt(value, 10) ? value : '0'}>
      <AppBar position="static" elevation={0} className={classes.navbar}>
        <TabList
          onChange={handleTabs}
          TabIndicatorProps={{ style: { height: '0px' } }}
        >
          {keywords.map((tab, index) => (
            <Tab
              key={tab._id}
              label={tab.tab_name}
              value={index.toString()}
              style={tabId === tab._id ? activeTabColorChange : null}
              className={classes.tab}
              onClick={() => handleTabStyle(tab)}
            />
          ))}
        </TabList>
      </AppBar>
      {keywords.length > parseInt(value, 10)
      && (
      <TabPanel value={value} className={classes.tabPanel}>
        <Demographics
          layoutData={layoutData}
          layoutIndex={layoutIndex}
          keywords={keywords}
          value={value}
          addKeywords={addKeywords}
        />
      </TabPanel>
      )}
    </TabContext>
  );
};

Navbar.propTypes = {
  keywords: PropTypes.array,
  addKeywords: PropTypes.func,
  layoutData: PropTypes.array,
  layoutIndex: PropTypes.number,
};

const Demographics = ({
  keywords, value, addKeywords, layoutData, layoutIndex,
}) => {
  const classes = useTabContentStyles();
  const [data, setData] = React.useState(keywords[parseInt(value, 10)].sections);
  const addDataToRedux = () => {
    layoutData[layoutIndex].tabs[parseInt(value, 10)].sections = data;
    addKeywords(layoutData);
  };
  useEffect(() => {
    setData(keywords[parseInt(value, 10)].sections);
  }, [value, keywords]);

  const handleCheckboxChange = async (event, fieldTitleIndex, fieldIndex) => {
    const array = [...data];
    array[fieldIndex].fields[fieldTitleIndex].is_scorable = event.target.checked;
    setData(array);
    addDataToRedux();
  };
  const handleInputChange = async (event, fieldTitleIndex, fieldIndex) => {
    const array = [...data];
    const { value } = event.target;
    array[fieldIndex].fields[fieldTitleIndex].value = value;
    setData(array);
  };
  return (
    <Grid container spacing={3}>
      {data.map((keyword, fieldIndex) => (
        <Grid item xs={6} key={keyword._id}>
          <Typography className={classes.title}>
            <ArrowDropDownIcon className={classes.arrowDropDown} />
            {keyword.section_name}
          </Typography>
          <Grid container spacing={2}>
            {keyword.fields.map((fieldName, fieldTitleIndex) => (
              <Grid item xs={6} key={fieldName.field_title}>
                <KeywordsCheckbox
                  name={fieldName.field_title}
                  value={fieldName.value ? fieldName.value : ''}
                  handleCheckboxChange={handleCheckboxChange}
                  handleInputChange={handleInputChange}
                  fieldTitleIndex={fieldTitleIndex}
                  fieldIndex={fieldIndex}
                  fieldType={fieldName.type}
                  options={fieldName.options}
                  scorable={fieldName.is_scorable}
                  save={addDataToRedux}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

Demographics.propTypes = {
  keywords: PropTypes.array,
  addKeywords: PropTypes.func,
  value: PropTypes.string,
  layoutData: PropTypes.array,
  layoutIndex: PropTypes.number,
};

const Keywords = ({
  fetchCurrentSim,
  fetchCreateKeywords,
  decreaseStep,
  reset,
  currentSim,
  keywords,
  sims,
  caseDetails,
  addKeywords,
  sfReset,
  setDataLoaded,
  dataLoaded,
}) => {
  const [type, setType] = React.useState('case');
  const classes = useToolbarStyles();
  const [openAlert, setOpenAlert] = React.useState(false);
  useEffect(() => {
    if (sims.id && currentSim && Object.keys(currentSim).length === 0) {
      fetchCurrentSim(sims.id);
    }
  }, [fetchCurrentSim, sims.id, currentSim]);
  const [keywordArray, setKeywordArray] = React.useState([]);
  const [toggleIndex, setToggleIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = React.useState('');
  const [alertSeverity, setSeverity] = useState('');
  const [alertMesage, setAlertMessage] = useState('');

  useEffect(() => {
    if (!keywords.length) {
      fetchCreateKeywords();
    } else if (keywords && keywords[0].tabs) {
      setKeywordArray(keywords[0].tabs ? keywords[0].tabs : []);
      setType(keywords[0].type);
    }
  }, [keywords, fetchCreateKeywords]);

  useEffect(() => {
    if (keywords.length && keywords[0].layouts) {
      keywords[0].layouts.filter((keyword, index) => {
        if (keyword.type === type) {
          setToggleIndex(index);
          setKeywordArray(keyword.tabs);
        }
        return true;
      });
    }
  }, [type, keywords]);

  useEffect(() => {
    if (Object.keys(caseDetails).length !== 0 && dataLoaded) {
      const data = [...keywordArray];
      for (const tab of data) {
        for (const keyword of tab.sections) {
          for (const field of keyword.fields) {
            if (field.attribute_name in caseDetails) {
              if (caseDetails[field.attribute_name] !== null) {
                field.is_scorable = !caseDetails[field.attribute_name]
                  ? caseDetails[field.attribute_name]
                  : true;
              }
              field.value = caseDetails[field.attribute_name];
            }
          }
        }
      }
      setKeywordArray(data);
      keywords[0].tabs = data;
      addKeywords(keywords);
      setDataLoaded(false);
    }
    // eslint-disable-next-line
  }, [caseDetails, dataLoaded, addKeywords, keywordArray, setDataLoaded]);
  const typeChange = (type) => {
    setType(type);
    // eslint-disable-next-line array-callback-return
    keywords.filter((keyword, index) => {
      if (keyword.type === type) {
        setToggleIndex(index);
        setKeywordArray(keyword.tabs);
      }
    });
  };
  const handleExcelUpload = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedFileName('');
  };
  const fileSelected = (event) => {
    setSelectedFileName(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    setOpen(false);
    await uploadKeywordExcel(formData, type, sims.id).then((res) => {
      if (res.data.success) {
        fetchCreateKeywords(sims.id);
        setOpenAlert(true);
        setSeverity('success');
        setAlertMessage('Excel Uploaded Successfully ');
      } else {
        setOpenAlert(true);
        setSeverity('error');
        setAlertMessage(res.data.message);
      }
      setTimeout(() => {
        setOpenAlert(false);
      }, 3000);
    });
    handleClose();
  };
  return (
    <Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.uploadDialog} id="form-dialog-title">
          Upload Excel
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the file with xlsx format only
          </DialogContentText>
          <Button color="primary" component="label">
            Browse
            {' '}
            <input
              hidden
              type="file"
              accept=".xlsx, .csv"
              onChange={fileSelected}
            />
          </Button>
          {selectedFileName || (keywords && keywords.length && keywords[toggleIndex].excel_sheet_name)}
          <Divider variant="fullWidth" className={classes.excelDivider} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <EnhancedTableToolbar
        decreaseStep={decreaseStep}
        reset={reset}
        newSim={sims}
        sfReset={sfReset}
        typeChange={typeChange}
        type={type}
        openAlert={openAlert}
        layoutData={keywords}
        handleExcelUpload={handleExcelUpload}
        alertSeverity={alertSeverity}
        alertMesage={alertMesage}
      />
      <Details currentSim={currentSim} title={sims.title} />
      {keywordArray.length > 0 && (
        <Navbar
          keywords={keywordArray}
          layoutData={keywords}
          layoutIndex={toggleIndex}
          addKeywords={addKeywords}
        />
      )}
    </Paper>
  );
};

Keywords.propTypes = {
  fetchCurrentSim: PropTypes.func,
  fetchCreateKeywords: PropTypes.func,
  decreaseStep: PropTypes.func,
  reset: PropTypes.func,
  currentSim: PropTypes.object,
  keywords: PropTypes.array,
  sims: PropTypes.object,
  caseDetails: PropTypes.object,
  addKeywords: PropTypes.func,
  sfReset: PropTypes.func,
  setDataLoaded: PropTypes.func,
  dataLoaded: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  status: state.sim.status,
  sims: state.sim.newSim,
  keywords: state.sim.createKeywords,
  currentSim: state.sim.currentSim,
  caseDetails: state.sf.caseDetails,
  dataLoaded: state.sf.dataLoaded,
});

const mapDispatchToProps = {
  fetchCreateKeywords,
  fetchCurrentSim,
  decreaseStep,
  addKeywords,
  reset,
  sfReset,
  setDataLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(Keywords);
