const drawerWidth = 82;

export const ResponsiveDrawer = (theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    boxShadow: '0px 0px 0px 0px',
    backgroundColor: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 15,
    whiteSpace: 'nowrap',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  downloadBtn: {
    textAlign: 'right',
    width: '68%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hidden: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    justifyContent: 'center',
    width: 56,
    height: 60,
    margin: 'auto',
    marginBottom: '5px',
  },
  menuIcon: {
    color: 'white',
    margin: 'auto',
  },
  adminIcon: {
    margin: 'auto',
  },
  menuText: {
    padding: 5,
    fontSize: '0.5rem',
    textAlign: 'center',
    color: 'white',
  },
  actionContainer: {
    marginLeft: 'auto',
    display: 'flex',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  customToolbar: {
    display: 'relative',
    height: '30px',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#26344d',
    color: 'white',
  },
  drawerBtnPaper: {
    width: 140,
    backgroundColor: '#26344d',
    color: 'white',
    marginLeft: 220,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 2, 3, 2),
    backgroundColor: '#f6f6f6',
    minHeight: '100vh',
  },
  divider: {
    backgroundColor: '#e1e1e1',
  },
  logo: {
    width: drawerWidth,
    textAlign: 'center',
  },
  buttonSqr: {
    padding: '5px',
    minHeight: 0,
    minWidth: 0,
  },
  expand: {
    background: '#f1c5d4',
    color: '#82203b',
  },
  button: {
    color: '#82203b',
    fontSize: 12,
  },
  downloadButtons: {
    backgroundColor: '#264173',
    color: 'white',
    margin: '0px 10px',
    padding: '5px 12px',
    [theme.breakpoints.up('md')]: {
      margin: '0px 2px',
      padding: '5px 8px',
      fontSize: 10,
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0px 5px',
      padding: '5px 12px',
      fontSize: 13,
    },
  },
  listAction: {
    right: 5,
  },
  cancelButton: {
    color: '#313387',
    float: 'right',
  },
  notes: {
    width: '100%',
    border: '1px solid #cdcbcb',
    marginTop: 10,
    borderRadius: 3,
    padding: '10px 14px',
    fontFamily: 'Proxima Nova',
    fontSize: 16,
    color: '#626262',
    '&:focus': {
      outline: 'none !important',
      borderColor: '#9a4d62',
    },
  },
  notificationIcon: {
    color: '#882e47',
    cursor: 'pointer',
  },
  circle: {
    width: 42,
    height: 42,
    borderRadius: '50%',
    fontSize: 22,
    paddingTop: 6,
    color: '#fff',
    textAlign: 'center',
    display: 'inline-block',
  },
  card: {
    margin: 5,
    '&:hover': {
      backgroundColor: '#f8dee7',
    },
  },
  cardContent: {
    padding: 10,
    paddingBottom: '10px !important',
  },
  divContent: {
    display: 'inline-block',
    marginLeft: 5,
    fontWeight: 500,
  },
  dateDiv: {
    marginLeft: 47,
    fontSize: 12,
    marginTop: -5,
    color: 'gray',
  },
  badge: {
    marginLeft: -1,
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      background: 'red',
      color: 'white',
      fontWeight: 600,
      height: 10,
      minWidth: 10,
      padding: 0,
    },
    animation: '$blink 2s linear infinite',

  },
  '@keyframes blink': {
    '50%': {
      opacity: 0,
    },
  },
  notifyDiv: {
    padding: '0.5rem', width: '390px',
  },
  popupHead: {
    backgroundColor: '#882647',
    borderRadius: '3px',
  },
  closeIcon: {
    backgroundColor: '#741C39',
    float: 'left',
    color: 'white',
    padding: 5,
    marginTop: -5,
    borderRadius: '1px',
  },
  heading: {
    display: 'inline-block',
    padding: '4px 12px',
    color: 'white',
    fontSize: '0.8rem',
  },
  cardBg: {
    backgroundColor: '#F1F1F1',
    marginTop: '11px',
    borderRadius: '4px',
    padding: '5px',
  },
  typo: {
    fontSize: 12, color: '#AEADAE',
  },
  iconDownload: {
    width: 20,
    height: 22,
    color: '#f2f4f7',
    cursor: 'pointer',
    marginTop: 5,
    float: 'right',
  },
  captivateTitle: {
    display: 'inline-block',
    padding: '4px 12px',
    fontWeight: 500,
    fontSize: '1.25rem',
  },
  popOver: {
    width: 700,
    zIndex: 0,
    cursor: 'grabbing',
    boxShadow: '2px 4px 5px #bfbdbd',
    background: '#fff',
    top: '50px !important',
    left: '750px !important',
    [theme.breakpoints.down('sm')]: {
      left: '0px !important',
    },
  },
  box: {
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  list: {
    fontSize: 10,
  },
  listItem: {
    padding: '5px 5px',
    cursor: 'pointer',
  },
  logOut: {
    fontWeight: 600,
    padding: '3px 11px',
    backgroundColor: '#F8DEE7',
    float: 'right',
    color: '#A55B73',
    margin: '8px 0px',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  notesPopover: {
    background: '#ffffff',
    marginTop: 25,
    boxShadow: '0px 1px 0px 2px #f6f6fb',
    borderRadius: 5,
    top: '50px !important',
    left: '74% !important',
    maxHeight: 500,
    overflowY: 'scroll',
  },
});

export default ResponsiveDrawer;
