/* eslint-disable react/no-array-index-key */
/* eslint-disable no-continue */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import {
  Grid,
  Toolbar,
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Tooltip,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import MicOutlinedIcon from '@material-ui/icons/MicOutlined';
import { useLocation } from "react-router-dom";
import {
  increaseStep,
  fetchCurrentSim,
  decreaseStep,
  fetchCharacterList,
  addLevels,
  addNewLevels,
  uploadLevels,
  fetchAutoPopulate,
  fetchSimDetails,
} from "../../../redux/sims/actions";
import Characters from "./Characters.jsx";
import CharacterData from "./CharacterData.jsx";
import TopTableToolbar from "./TopTableToolbar.jsx";
import PassFailCriteria from './PassFailCriteria.jsx';
import GenerateSim from './GenerateSim.jsx';
import Levels from './Levels.jsx';
import Details from "../Details.jsx";
import {
  addLevelScript,
  editLevels,
  deleteScript,
  deleteLevel,
  duplicateSim,
  generateAudio,
  downloadExcelLevel,
  realTimeFeedback,
  updateHintText,
  updateKeywordScore,
  copyHintTextFromLevelOne,
  sendToSnowfly,
  toggleScreenRecordingSwitch,
} from "../../../services/result.service";
import { useStyle } from "./Createlevels";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import DropZone from '../dragDrop/DropZone.jsx';
import '../dragDrop/style.css';
import { handleMoveWithinParent, handleMoveSidebarComponentIntoParent } from "../dragDrop/helpers";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ImportExportIcon from '@material-ui/icons/ImportExport';

const useQuery = () => new URLSearchParams(useLocation().search);
const getBackground = (charachter) => {
  let background = "";
  switch (charachter) {
    case "coach":
      background = "#882647";
      break;
    case "agent":
      background = "#EB6D20";
      break;
    case "customer":
      background = "#2694D3";
      break;
    case "other":
      background = "#2CB174";
      break;
    default:
      background = "#882647";
  }
  return background;
};

const CreateLevels = ({
  newSim,
  fetchCurrentSim,
  currentSim,
  decreaseStep,
  increaseStep,
  addLevels,
  uploadLevels,
  fetchAutoPopulate,
  tabs,
  fetchSimDetails,
  status,
}) => {
  const classes = useStyle();
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [simGenerated, setSimGenrated] = useState(false);
  const [data, setData] = useState({ ...newSim });
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [audioOpen, setAudioOpen] = useState(false);
  const [audioCount, setAudioCount] = useState(0);
  const [levelSelect, setLevelSelect] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openSuccessAlert, setSuccessAlert] = React.useState(false);
  const [currentIndex, setIndex] = React.useState(0);
  const [alertMesage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const [load, setLoad] = React.useState(false);
  const [levelOrder, setLevelOrder] = React.useState(0);
  const [flag, setFlag] = useState(true);
  const [totalLevel, setTotalLevel] = useState(0);
  const [deleted, setdeleted] = React.useState(false);
  const [selectedClip, setSelectedClip] = React.useState('');
  const [importLevel, setImportLevel] = React.useState(false);
  const [passDialog, setPassDialog] = React.useState(false);
  const [levelName, setLevelName] = React.useState(
    data.levels[currentIndex] ? data.levels[currentIndex].name : ""
  );
  const [deleteLastLevel, setDeleteLastLevel] = useState(false);
  const [timePlay, setTimePlay] = useState(0);
  const [currentAudio, setCurrentAudio] = useState({});
  const clipRef = useRef(null);
  const handleClickOpen = () => {
    setLevelName(data.levels[currentIndex].name);
    setOpen(true);
  };
  const[sendsAudioToSnowfly, setSendsAudioToSnowfly] = useState(false);

  const handleClose = () => {
    setLevelName('');
    setOpen(false);
  };
  const initialize = () => {
    if (query.get("simId") && !data.id) {
      fetchSimDetails(query.get("simId"));
    } else {
      setIsLoading(false);
    }
  };
  useEffect(initialize, []);

  useEffect(() => {
    if (query.get("simId") && data.fetchStatus === true && data.levels.length) {
      setIsLoading(false);
      localStorage.setItem('sims_id', query.get("simId"))
    }
  }, [data, query]);

  useEffect(() => {
    if (data.id && currentSim && Object.keys(currentSim).length === 0) {
      fetchCurrentSim(data.id);
    }
  }, [fetchCurrentSim, data.id, currentSim]);

  useEffect(() => {
    fetchAutoPopulate(query.get("simId")); // eslint-disable-next-line
  }, [fetchAutoPopulate]);

  useEffect(() => {
    if (selectedClip) {
      clipRef.current.scrollIntoView(false);
    }
  }, [clipRef, selectedClip]);

  useEffect(
    () => {
      let sim = {...data};
      setSendsAudioToSnowfly(sim.levels[currentIndex] && sim.levels[currentIndex].send_to_snowfly);
    },
    [currentIndex, data]
  );

  const initializeLevelArray = React.useCallback(() => {
    const simData = { ...data };
    const simLevels = ["Watch and Learn"];
    if (simData.levels.length === 0) {
      let index = 1;
      for (const level of simLevels) {
        simData.levels.push({
          _id: undefined,
          name: level,
          order: index - 1,
          real_time_feedback: false,
          natural_lenguage: data.natural_lenguage,
          send_to_snowfly: false,
          hint_text: false,
          keyword_score: true,
          scripts: [],
        });
        index += 1;
      }
    }
    setData(simData);
  }, [data]);

  useEffect(() => {
    if (data.id && data.levels.length <= 0) {
      initializeLevelArray();
    }
  }, [data.id, data.levels, initializeLevelArray]);

  useEffect(() => {
    if (data && data.levels.length) {
      setTotalLevel(data.levels.length);
      setLevelSelect(false);
      if (data.levels[currentIndex].scripts.length) {
        data.levels[currentIndex].scripts.map((script) => {
          script.play = true;
          return script;
        });
      }
    }
  }, [data, currentIndex]);
  useEffect(() => {
    setData({ ...newSim });
  }, [newSim]);

  const updateData = async (index, characterData) => {
    const simData = { ...data };
    const requestData = {};
    const addIntialScript = async (requestData) => {
      const response = await addLevelScript(requestData);
      simData.levels[currentIndex].scripts[index]._id = response.sim_script_id;
    };
    simData.levels[currentIndex].scripts[index].script = characterData.script;
    simData.levels[currentIndex].scripts[index].keywords = characterData.keywords;
    simData.levels[currentIndex].scripts[index].hint_text = characterData.hint_text;
    simData.levels[currentIndex].scripts[index].hide_text = characterData.hide_text;
    simData.levels[currentIndex].scripts[index].real_time_feedback = characterData.real_time_feedback;
    simData.levels[currentIndex].scripts[index].natural_lenguage = data.natural_lenguage;
    simData.levels[currentIndex].scripts[index].send_to_snowfly = characterData.send_to_snowfly;
    simData.levels[currentIndex].scripts[index].tests = characterData.tests;
    requestData.is_level = false;
    requestData._id = simData.levels[currentIndex].scripts[index]._id;
    requestData.sim_level_id = simData.levels[currentIndex]._id;
    requestData.sim_id = simData.id;
    requestData.script = characterData.script;
    requestData.sim_character_id = characterData.sim_character_id;
    requestData.keywords = characterData.keywords;
    requestData.tests = characterData.tests;
    requestData.hint_text = characterData.hint_text;
    requestData.hide_text = characterData.hide_text;
    requestData.autopopulate_timer = characterData.autopopulate_timer;
    requestData.autopopulate_data = characterData.autopopulate_data;
    requestData.audio_url = characterData.audio_url;
    requestData.order = simData.levels[currentIndex].scripts[index].order;
    requestData.real_time_feedback = characterData.real_time_feedback;
    requestData.natural_lenguage = data.natural_lenguage;
    requestData.agent_recorded_audio = characterData.agent_recorded_audio;
    requestData.overalConfidence = characterData.overalConfidence;
    addIntialScript(requestData);
    setData(simData);
  };

  const Level = (cLevel) => {
    const simData = { ...data };
    const index = simData.levels.findIndex(
      (level) => level.order === cLevel.order
    );
    setIndex(index);
    setTimePlay(0);
    setSendToSnowfly(simData.levels[currentIndex].send_to_snowfly);
  };
  
  const handleChange = (value, index, name) => {
    const sim = { ...data };
    if (name === "script") {
      sim.levels[currentIndex].scripts[index].script = value;
    } else if (name === "hint_text") {
      sim.levels[currentIndex].scripts[index].hint_text = value;
    } else if (name === "keywords") {
      sim.levels[currentIndex].scripts[index].keywords = value;
    } else if (name === 'captivate_fileName') {
      sim.levels[currentIndex].scripts[index].captivate_folder_name = value;
    } else if (name === 'overalConfidence') {
      sim.levels[currentIndex].scripts[index].overalConfidence = value;
    }
    setData(sim);
  };
  const handleKeywordChange = (index, event) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].keywords = [...event];
    setData(sim);
  };

  const handleTestChange = (index, updatedTests) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].tests = updatedTests;
    setData(sim);
  };

  const handleAudioGenerate = async (index, url, expiry_date, key, type, voice) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].agent_recorded_audio = type;
    sim.levels[currentIndex].scripts[index].audio_url = url;
    sim.levels[currentIndex].scripts[index].expiry_date = expiry_date;
    sim.levels[currentIndex].scripts[index].audio_key = key;
    sim.levels[currentIndex].scripts[index].voice = voice;
    setData(sim);
  };

  const handleEditLevel = (event) => {
    const { value } = event.target;
    setLevelName(value);
  };
  const handleSave = async () => {
    const sim = { ...data };
    data.levels[currentIndex].name = levelName;
    setData(sim);
    setOpen(false);
    editLevels(data.levels[currentIndex]._id, {
      name: data.levels[currentIndex].name,
      sim_id: data.levels[currentIndex].sim_id,
    });
    setLevelName("");
  };

  const handleCheckboxChange = (index, event) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].hide_text = event.target.checked;
    setData(sim);
  };
  const handleRadioChange = (index, event) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].real_time_feedback =
      event.target.value === "yes";
    setData(sim);
  };

  const handleDeleteScript = async (index, script) => {
    const sim = { ...data };
    const requestData = {};
    requestData.script_id = script._id;
    requestData.sim_id = script.sim_id;
    await deleteScript(requestData);
    sim.levels[currentIndex].scripts.splice(index, 1);
    setData(sim);
  };
 
  const handlePassDialog = (text) => {
    if (text === "open") {
      setPassDialog(true);
    }
    if (text === "close") {
      setPassDialog(false);
    }
  };
 
  const handleDeleteLevel = async () => {
    const sim = { ...data };
    if (sim.levels.length === 1) {
      setDeleteLastLevel(true);
      setDeleteOpen(true);
    } else {
      setDeleteOpen(true);
    }
  };

  const deleteConfirm = async () => {
    setdeleted(true);
    const sim = { ...data };
    if(sim.is_pass_fail_criteria_set) {
      sim.is_pass_fail_criteria_set = false;
      sim.pass_fail_id = {};
    }
    if (sim.levels[currentIndex]._id === undefined) {
      sim.levels.splice(currentIndex, 1);
    } else if (deleteLastLevel) {
      const data = {}
      data.sim_level_id = sim.levels[currentIndex]._id;
      await deleteScript(data);
      setDeleteLastLevel(false);
      sim.levels[currentIndex].scripts.splice(0, sim.levels[currentIndex].scripts.length);
      setData(sim);
    } else {
      const requestData = {};
      requestData.level_id = sim.levels[currentIndex]._id;
      requestData.sim_id = sim.levels[currentIndex].sim_id;
      await deleteLevel(requestData);
      sim.levels.splice(currentIndex, 1);
    }
    if (currentIndex > 0) {
      setIndex(currentIndex - 1);
    }
    setDeleteOpen(false);
    setData(sim);
  };

  const handleDuplicateSim = async () => {
    const sim = { ...data };
    const requestData = {};
    requestData.simId = sim.id;
    const response = await duplicateSim(requestData);
    if (response.data.success) {
      setSuccessAlert(true);
    } else {
      setAlertType("error");
      setAlertMessage("Error in creating duplicate sim. Please try again.");
      setOpenAlert(false);
    }
  };
  const downloadLevel = async () => {
    downloadExcelLevel(data.levels[currentIndex]._id).then((response) => {
      const fileName = data.levels[currentIndex].name.replace(/\//g, '_');
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });
  };
  
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleSuccessAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessAlert(false);
    setAlertType("");
    setAlertMessage("");
  };

  const handleAudioChange = (index) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].agent_recorded_audio = false;
    sim.levels[currentIndex].scripts[index].audio_url = "";
    setData(sim);
  };
  const handleAgentRecordedAudio = (index) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].agent_recorded_audio = true;
    setData(sim);
  };

  const handleAutopopulateChange = async (index, array, timer) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].autopopulate_timer = timer;
    sim.levels[currentIndex].scripts[index].autopopulate_data = array.autopopulate_data;
    sim.levels[currentIndex].scripts[index].autopopulate_data.forEach((tab) => {
      delete tab._id;
      tab.sections.forEach((field) => {
        delete field._id;
        field.fields.forEach((element) => {
          delete element._id;
        });
      })
    }
    );
    setData(sim);
  };
  const handleResetAutopopulate = async (index) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].autopopulate_timer = 0;
    sim.levels[currentIndex].scripts[index].autopopulate_data = tabs;
    setData(sim);
  };

  const getAudioCount = () => {
    const result = data.levels[currentIndex].scripts.filter(
      (script) => script.audio_url !== ""
    );
    if (result.length <= 0) {
      handleLevelAudioGenerate();
    } else {
      setAudioCount(result.length);
      setAudioOpen(true);
    }
  };

  const handleLevelAudioGenerate = async () => {
    setAudioOpen(false);
    setLoad(true);
    const sim = { ...data };
    for (let i = 0; i < sim.levels[currentIndex].scripts.length; i++) {
      const character = data.sim_characters.find(
        (item) =>
          item._id === sim.levels[currentIndex].scripts[i].sim_character_id
      );
      if (
        character.type === "agent" &&
        !sim.levels[currentIndex].scripts[i].agent_recorded_audio
      ) {
        if (sim.levels[currentIndex].order === 0 || sim.levels[currentIndex].order === 1) {
          const req = {};
          req.sim_script_id = sim.levels[currentIndex].scripts[i]._id;
          req.sim_id = sim.levels[currentIndex].scripts[i].sim_id;
          req.voice = character.voice;
          const response = await generateAudio(req);
          sim.levels[currentIndex].scripts[i].audio_url = response.audio_url;
          sim.levels[currentIndex].scripts[i].expiry_date = response.expiry_date;
          sim.levels[currentIndex].scripts[i].audio_key = response.audio_key;
          sim.levels[currentIndex].scripts[i].agent_recorded_audio = true;
          sim.levels[currentIndex].scripts[i].voice = character.voice;
          setData(sim);
          await updateData(i, sim.levels[currentIndex].scripts[i]);
        }
        // If the character type is 'agent' and enabled to prompt to record, then do not generate audio for that script
      } else {
        const req = {};
        req.sim_script_id = sim.levels[currentIndex].scripts[i]._id;
        req.sim_id = sim.levels[currentIndex].scripts[i].sim_id;
        req.voice = character.voice;
        const response = await generateAudio(req);
        sim.levels[currentIndex].scripts[i].audio_url = response.audio_url;
        sim.levels[currentIndex].scripts[i].expiry_date = response.expiry_date;
        sim.levels[currentIndex].scripts[i].audio_key = response.audio_key;
        sim.levels[currentIndex].scripts[i].voice = character.voice;
        if (sim.levels[currentIndex].order === 0 || sim.levels[currentIndex].order === 1) {
          sim.levels[currentIndex].scripts[i].agent_recorded_audio = true
        }
        setData(sim);
        await updateData(i, sim.levels[currentIndex].scripts[i]);
      }
    }
    setLoad(false);
  };

  const [hint, setHint] = useState(1);
  const [keywordScore, setKeywordScore] = useState(1);
  const hintTextChange = async(evn) => {
    setHint(hint+1);
    let changeData = {};
    changeData.sim_id = data.id;
    changeData.hint_text =  evn.target.checked;
    changeData.level_id = data.levels[currentIndex]._id;
    data.levels[currentIndex].hint_text =  evn.target.checked;
    await updateHintText(changeData);
  }
  const KeywordChange = async(evn) => {
    setKeywordScore(keywordScore+1);
    let changeData = {};
    changeData.sim_id = data.id;
    changeData.keyword_score =  evn.target.checked;
    changeData.level_id = data.levels[currentIndex]._id;
    data.levels[currentIndex].keyword_score =  evn.target.checked;
    await updateKeywordScore(changeData);
  }
  const enableRealTimeFeedback = async (evn) => {
    let checked = evn.target.checked;
    let count = 0;
    const selectedCharacter = data.sim_characters.find(
      (item) => item.type === "agent"
    );
    const sim = { ...data };
    const requestData = {};
    requestData.scripts = [];
    requestData.sim_id = data.id;
    requestData.real_time_feedback = checked ? true : false;
    requestData.level_id = data.levels[currentIndex]._id;
    if (data.id && data.levels[currentIndex].scripts.length > 0) {
      setLoad(true);
      for (let i = 0; i < sim.levels[currentIndex].scripts.length; i++) {
        if (
          sim.levels[currentIndex].scripts[i].sim_character_id ===
          selectedCharacter._id
        ) {
          count++;
          requestData.scripts.push(sim.levels[currentIndex].scripts[i]._id);
          sim.levels[currentIndex].real_time_feedback = checked ? true : false;
          sim.levels[currentIndex].scripts[i].real_time_feedback = checked ? true : false;
        }
        setData(sim);
      }
      if (count === 0) {
        setLoad(false);
        setAlertType("warning");
        setAlertMessage(
          "No Agent Clip found to enable/disable real time feedback"
        );
        setOpenAlert(true);
      } else {
        const res = await realTimeFeedback(requestData);
        if (res.success) {
          setLoad(false);
          setAlertType("success");
          setAlertMessage("Successfuly updated real time feedback");
          setOpenAlert(true);
        } else {
          setLoad(false);
          setAlertType("error");
          setAlertMessage("Error in changing real time feedback");
          setOpenAlert(true);
        }
      }
    } else {
      setAlertType("warning");
      setAlertMessage("Add scripts to enable/disable real time feedback");
      setOpenAlert(true);
    }
  };
 
  const clipScroll = (id) => {
    setSelectedClip(id);
  }
   // Method to handle drag and drop in scripts
   const handleOnDragEnd = (result) => {
    const levelScript = { ...data };
    const items = Array.from(levelScript.levels[currentIndex].scripts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.map(async (item, index) => {
      item.order = index + 1;
      if (item.newScript) {
        item.newScript = false;
      }
      await addLevelScript(item);
    });
    levelScript.levels[currentIndex].scripts = items;
    setData(levelScript);
  };
  const setTimePlayfun = (audioPlayer, dataa) => {
    data.levels[currentIndex].scripts.map((script) => {
      if (script._id === dataa._id) {
        script.play = false;
      } else {
        script.play = true;
      }
      return data;
    });
    setData(data)
    setCurrentAudio(audioPlayer);
    setTimePlay((prev) => prev + 1)
  }
  const pauseFunction = (dataa) => {
    data.levels[currentIndex].scripts.map((script) => {
      if (script._id === dataa._id) {
        script.play = true;
        setTimePlay(0)
      }
      return data;
    });
    setData(data);
  }
  const handleDrop = async(dropZone, item) => {
    let splitDropZonePath;
    let pathToDropZone;
    if (dropZone.path) {
      splitDropZonePath = dropZone.path.split("-");
      pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
    }
    const levelScript = { ...data };
    // sidebar info
    if (( item === undefined && dropZone.type) || item.character) {
      // 1. Move sidebar item into page
      const scriptData = levelScript.levels[currentIndex];
      const { length } = scriptData.scripts
      if (length === 0 || levelScript.levels[currentIndex]._id) {
        // push the script when double click the character
        if (dropZone.type) {
          levelScript.levels[currentIndex].scripts.push({
            order: length,
            sim_character_id: dropZone._id,
            script: "",
            keywords: [],
            hint_text: "",
            hide_text: false,
            audio_url: "",
            autopopulate_timer: 0,
            autopopulate_data: [...tabs],
            status: "saved",
            real_time_feedback: false,
            natural_lenguage: data.natural_lenguage,
            agent_recorded_audio: false,
            newScript: true,
          });
        }
        const requestData = {};
        if (
          (scriptData.scripts.length === 0 || (dropZone.type && scriptData.scripts.length === 1)) &&
          !levelScript.levels[currentIndex]._id
        ) {
          // request data when initial script is added to rescpective level
          levelScript.levels[currentIndex].scripts = dropZone.type ? levelScript.levels[currentIndex].scripts : [];
          requestData.is_level = true;
          requestData.name = levelScript.levels[currentIndex].name;
          requestData.level_order = levelScript.levels[currentIndex].order;
          requestData.real_time_feedback = levelScript.levels[currentIndex].real_time_feedback;
          requestData.natural_lenguage = data.natural_lenguage;
          requestData.level_hint_text = (levelScript.levels[currentIndex].order === 0 ? false : true);
        } else {
          // request data when a respective level has more than one script
          requestData.is_level = false;
          requestData.sim_level_id = levelScript.levels[currentIndex]._id;
          requestData.newScript = true;
        }
        requestData.sim_id = levelScript.id;
        requestData.script = "";
        requestData.sim_character_id = dropZone.type ? dropZone._id : item.character._id;
        requestData.audio_url = "";
        requestData.order = dropZone.type ? scriptData.scripts.length.toString() : splitDropZonePath[1];
        requestData.status = "saved";
        requestData.real_time_feedback = false;
        requestData.natural_lenguage = data.natural_lenguage;
        requestData.keywords = [];
        requestData.hint_text = "";
        requestData.hide_text = false;
        requestData.agent_recorded_audio = false;
        requestData.autopopulate_timer = 0;
        requestData.autopopulate_data = tabs;
        const response = await addLevelScript(requestData);
        if (response.sim_level_id) {
          levelScript.levels[currentIndex]._id = response.sim_level_id;
        }
        if (!dropZone.type) {
          let items = Array.from(levelScript.levels[currentIndex].scripts);
          items = handleMoveSidebarComponentIntoParent(data.levels[currentIndex], splitDropZonePath, requestData).scripts;
          items[splitDropZonePath[1]]._id = response.sim_script_id;
          items.map(async (item, index) => {
            if (items[splitDropZonePath[1]]._id !== item._id) {
                item.newScript = false;
            }
            item.order = index + 1;
            await addLevelScript(item);
          });
          levelScript.levels[currentIndex].scripts = items;
        } else {
          levelScript.levels[currentIndex].scripts[length]._id = response.sim_script_id;
          setSelectedClip(response.sim_script_id);
        }
        setData(levelScript);
      }
    } else {
      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          let items = Array.from(levelScript.levels[currentIndex].scripts);
          items = handleMoveWithinParent(data.levels[currentIndex], splitDropZonePath, splitItemPath).scripts;

          items.map(async (item, index) => {
            item.order = index + 1;
            await addLevelScript(item);
          });
          levelScript.levels[currentIndex].scripts = items;
          setData(levelScript);
        }
      }
    }
  }
  const copyHintText = async(e) => {
    let importFrom = data.levels[0]._id;
    await copyHintTextFromLevelOne(data.id, importFrom, data.levels[currentIndex]._id);
    setLoad(true);
    await fetchSimDetails(data.id);
    setTimeout(() => {
     setLoad(false);
    }, 3000);
  }

  function setSendToSnowfly(bSend) {
    const sim = { ...data };
    sim.levels[currentIndex].send_to_snowfly = bSend;
    setData(sim);

    setSendsAudioToSnowfly(bSend);

    return sim.levels[currentIndex];
  }

  const toggleSendToSnowfly = async(e) => {
    let currentLevel = setSendToSnowfly(e.currentTarget.checked);

    try {
      const res = await sendToSnowfly({
        simId: currentLevel.sim_id,
        levelId: currentLevel._id,
        sendToSnowfly: currentLevel.send_to_snowfly,
      });
      if (res.success) {
        setLoad(false);
        setAlertType("success");
        setAlertMessage("Successfuly updated Send to Snowfly feature");
        setOpenAlert(true);
      } else {
        setLoad(false);
        setAlertType("error");
        setAlertMessage("Error in changing Send to Snowfly feature");
        setOpenAlert(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const toggleScreenRecording = async(e) => {
    const sim = { ...data };
    sim.levels[currentIndex].screenRecording = e.currentTarget.checked;
    
    try {
      const res = await toggleScreenRecordingSwitch({
        levelId: sim.levels[currentIndex]._id,
        screenRecording: e.currentTarget.checked,
      });
      if (res.success) {
        setLoad(false);
        setAlertType("success");
        setAlertMessage("Successfuly updated Screen Recording");
        setOpenAlert(true);
        setData(sim);
      } else {
        setLoad(false);
        setAlertType("error");
        setAlertMessage("Error in changing Screen Recording");
        setOpenAlert(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const rowPath = 0;
  return (
    <Paper elevation={0}>
      <TopTableToolbar
        updateData={updateData}
        decreaseStep={decreaseStep}
        increaseStep={increaseStep}
        data={data}
        addLevels={addLevels}
        uploadLevels={uploadLevels}
        newSim={newSim}
        setFlag={setFlag}
        status={status}
        importLevel={() => setImportLevel((prev) => !prev)}
      />
      <Details currentSim={currentSim} title={newSim.title} />
      {openAlert ? (
        <Alert
          variant="filled"
          onClose={handleAlertClose}
          severity={alertType ? alertType : 'error'}
          className={alertType === 'error' ? classes.errorAlert : ''}
        >
          {alertMesage}
        </Alert>
      ) : null}
      {openSuccessAlert ? (
        <Alert
          className={classes.alert}
          variant="filled"
          onClose={handleSuccessAlertClose}
          severity="success"
        >
          Success! Duplicate Sim Created.
        </Alert>
      ) : null}
      <div className={classes.mainDiv}>
        {isLoading ? (
          <div className={classes.loaderDiv}>
            <CircularProgress color="primary" />
          </div>
        ) : null}
        <Grid container className={classes.gridContainer}>
          <Grid item xs={2} sm={2} lg={2} xl={2}>
            <Levels
              simGenerated={simGenerated}
              Level={Level}
              levels={data.levels}
              data={data}
              setData={setData}
              newSim={newSim}
              levelOrder={levelOrder}
              setLevelOrder={setLevelOrder}
              deleted={deleted}
              flag={flag}
              setFlag={setFlag}
              setdeleted={setdeleted}
            />
          </Grid>
          <DndProvider backend={HTML5Backend}>
            <Grid item className={classes.editCanvas} sm={8} lg={8} xl={8} xs={7}>
              <Toolbar className={classes.toolBar}>
                <Typography className={classes.canvasLabel}>
                  {currentIndex + 1}.{" "}
                  {data.levels[currentIndex]
                    ? data.levels[currentIndex].name
                    : ""}
                  <Button
                    disabled={
                      !(
                        data.levels &&
                        data.levels.length > 0 &&
                        data.levels[currentIndex]
                      )
                    }
                    className={classes.editBtn}
                    color="default"
                    onClick={handleClickOpen}
                    startIcon={<EditIcon className={classes.editIcon} />}
                  />
                </Typography>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="levelName"
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle id="LevelName">Edit Level Name</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      id="LevelName"
                      margin="dense"
                      name="name"
                      multiline
                      value={levelName}
                      onChange={(e) => handleEditLevel(e)}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} className={classes.cancelButton}>
                      Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
                <PassFailCriteria
                  handlePassDialog={handlePassDialog}
                  passDialog={passDialog}
                  data={data}
                  currentIndex={currentIndex}
                  setAlertType={setAlertType}
                  setAlertMessage={setAlertMessage}
                  setOpenAlert={setOpenAlert}
                />
                <GenerateSim
                  currentIndex={currentIndex}
                  data={data}
                  setTotalLevel={setTotalLevel}
                  totalLevel={totalLevel}
                  setSimGenrated={setSimGenrated}
                  setLevelSelect={setLevelSelect}
                  levelSelect={levelSelect}
                  handleClose={handleClose}
                  setLoad={setLoad}
                  setFlag={setFlag}
                  setAlertType={setAlertType}
                  setAlertMessage={setAlertMessage}
                  setOpenAlert={setOpenAlert}
                />
                <div>
                  <Box className={classes.iconBox}>
                  {data.levels.length && data.levels[currentIndex].order !== 0 ?
                    <Tooltip title={`Import hint-text from Level-1`}>
                      <ImportExportIcon
                        onClick={copyHintText}
                        className={classes.iconImport}
                      />
                    </Tooltip>
                    : null}
                    {data.levels.length && hint ?
                    <Tooltip title={`${data.levels[currentIndex] && data.levels[currentIndex].keyword_score ? "Disable" : "Enable"
                  } Keyword-Score in Agent Result Screen`}>
                    <Checkbox
                    checked={data.levels[currentIndex].keyword_score}
                    onChange={KeywordChange}
                    className={classes.iconFeedback}
                  />
                  </Tooltip>
                    : null}
                  { data.levels.length && hint ?
                    <Tooltip title={`${data.levels[currentIndex] && data.levels[currentIndex].hint_text ? "Disable" : "Enable"
                  } Hint-Text in Level ${currentIndex + 1}`}>
                    <Checkbox
                    checked={data.levels[currentIndex].hint_text}
                    onChange={hintTextChange}
                    className={classes.iconFeedback}
                    />
                    </Tooltip>
                  : null}
                    <span className={classes.verticalDivider} />
                    {data.real_time_feedback && data.levels[currentIndex] ? (
                      <Tooltip
                        title={`${data.levels[currentIndex] && data.levels[currentIndex].real_time_feedback ? "Disable" : "Enable"
                          } Real-Time Feedback`}
                      >
                        <Checkbox
                          checked={data.levels[currentIndex].real_time_feedback}
                          onChange={enableRealTimeFeedback}
                          className={classes.iconFeedback}
                        />
                      </Tooltip>
                    ) : null}
                    {/*Enable/Disable sending the whole session recording to SnowFly*/}
                    {data.levels[currentIndex] ?
                        <Tooltip title={`${data.levels[currentIndex].send_to_snowfly ? "Do NOT send": "Send" } full recording session to SnowFly`}
                        >
                          <Checkbox
                              id="sendToSnowfly"
                              checked={sendsAudioToSnowfly || false}
                              onChange={toggleSendToSnowfly}
                              className={classes.iconFeedback}
                          />
                        </Tooltip>
                        : null}
                    {data.real_time_feedback ? (
                      <span className={classes.verticalDivider} />
                    ) : null}
                    {data.levels.length && data.levels[currentIndex] ? (
                      <Tooltip
                        title={`${data.levels[currentIndex] && data.levels[currentIndex].screenRecording ? "Disable" : "Enable"
                          } Screen Recording`}
                      >
                        <Checkbox
                          checked={data.levels[currentIndex].screenRecording}
                          onChange={toggleScreenRecording}
                          className={classes.iconFeedback}
                        />
                      </Tooltip>
                    ) : null}
                    <span className={classes.verticalDivider} />
                    {data.levels[currentIndex] &&
                      data.levels[currentIndex].scripts.length <= 0 ? (
                      <Tooltip title="Generate Audio">
                        <VolumeUpRoundedIcon className={classes.iconVolume} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Generate Audio">
                        <VolumeUpRoundedIcon
                          className={classes.iconVolume}
                          onClick={getAudioCount}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </div>
                <Dialog
                  open={audioOpen}
                  onClose={() => setAudioOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Generate Audio
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {`There are ${audioCount} audio clips already. Are you sure you want to record over them?`}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setAudioOpen(false)} className={classes.cancelButton}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleLevelAudioGenerate()}
                      className={classes.dialogDeleteBtn}
                      autoFocus
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
                <div>
                  <Box className={classes.iconBox}>
                    <Tooltip title="Duplicate Sim">
                      <FileCopyRoundedIcon
                        className={classes.iconCopy}
                        onClick={handleDuplicateSim}
                      />
                    </Tooltip>
                    <span className={classes.verticalDiv} />
                    <Tooltip title="Download CSV">
                      <GetAppIcon
                        className={classes.iconDownload}
                        onClick={downloadLevel}
                      />
                    </Tooltip>
                    <span className={classes.verticalDivider} />
                    <Tooltip title="Delete Level">
                      <DeleteOutlineIcon
                        className={classes.iconDelete}
                        onClick={handleDeleteLevel}
                      />
                    </Tooltip>
                  </Box>
                </div>
                <Dialog
                  open={deleteOpen}
                  onClose={() => setDeleteOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Delete Level</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {`Do you want to delete the level "${data.levels[currentIndex]
                        ? data.levels[currentIndex].name
                        : ""
                        }"`}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => {
                      setDeleteOpen(false);
                      setDeleteLastLevel(false)
                    }} className={classes.cancelButton}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => deleteConfirm()}
                      className={classes.dialogDeleteBtn}
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Toolbar>
              <div className={classes.progress}>
                {load ? <LinearProgress color="primary" /> : null}
              </div>
              <div>
                {data?.levels[currentIndex]?.scripts.map((clip, i) => {
                  let currentChar;
                  const agentCharIndex = data?.sim_characters?.findIndex(char => char.type === 'agent')
                  const customerCharIndex = data?.sim_characters?.findIndex(char => char.type === 'customer')
                  const otherCharIndex = data?.sim_characters?.findIndex(char => char.type === 'other')
                  if (data?.sim_characters[agentCharIndex]?._id === clip.sim_character_id) {
                    currentChar = (<span key={clip._id}><Tooltip key={clip._id} title={clip.script}><MicOutlinedIcon className={classes.agentMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                      {(i + 1) % 50 === 0 ? <br></br> : ''}
                    </span>)
                    if (clip.agent_recorded_audio) {
                      currentChar = (<span key={clip._id}><Tooltip key={clip._id} title={clip.script}><VolumeUpIcon className={classes.agentMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                        {(i + 1) % 50 === 0 ? <br></br> : ''}
                      </span>)
                    }
                  } else if (data?.sim_characters[customerCharIndex]?._id === clip.sim_character_id) {
                    currentChar = (<span key={clip._id}><Tooltip title={clip.script}><VolumeUpIcon className={classes.customerMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                      {(i + 1) % 50 === 0 ? <br></br> : ''}
                    </span>)
                  } else if (data?.sim_characters[otherCharIndex]?._id === clip.sim_character_id) {
                    currentChar = (<span key={clip._id} ><Tooltip title={clip.script}><VolumeUpIcon className={classes.otherMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                      {(i + 1) % 50 === 0 ? <br></br> : ''}
                    </span>)
                  } else {
                    currentChar = (<span key={clip._id}><Tooltip title={clip.script}><VolumeUpIcon className={classes.coachMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                      {(i + 1) % 50 === 0 ? <br></br> : ''}
                    </span>)
                  }
                  return currentChar
                }
                )}
              </div>
              <div
                className={
                  data.levels.length > 0 &&
                    data.levels[currentIndex] &&
                    data.levels[currentIndex].scripts.length === 0
                    ? classes.scriptDiv1
                    : classes.scriptDiv
                }
              >
                {data.levels.length > 0 &&
                  data.levels[currentIndex] &&
                  data.levels[currentIndex].scripts.length === 0 ? (
                  <section className={classes.backgroundImage} >
                    <DropZone
                        data={{
                          path: `${rowPath}-${data.levels[currentIndex].scripts.length}`,
                          childrenCount: data.levels[currentIndex].scripts.length
                        }}
                        onDrop={handleDrop}
                        isLast
                      />
                      </section>
                ) : null}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <div
                        className="characters"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {data.levels.length > 0 &&
                          data.levels[currentIndex] &&
                          data.levels[currentIndex].scripts.map(
                            (script, index) => {
                              const currentPath = `${rowPath}-${index}`;
                              return (
                                <Draggable
                                  key={script._id}
                                  draggableId={script._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                    <DropZone
                                      data={{
                                        path: currentPath,
                                        childrenCount: data.levels[currentIndex].scripts.length
                                      }}
                                      onDrop={handleDrop}
                                    />
                                    <div ref={selectedClip === script._id ? clipRef : null}>
                                      <CharacterData
                                        path={currentPath}
                                        onlyKeywords={data.levels[currentIndex].only_keywords}
                                        key={index}
                                        index={index}
                                        data={script}
                                        sim={data}
                                        tabs={tabs}
                                        setTimePlayfun={setTimePlayfun}
                                        currentAudio={currentAudio}
                                        timePlay={timePlay}
                                        pauseFunction={pauseFunction}
                                        characterData={data.sim_characters}
                                        handleChange={handleChange}
                                        handleAudioChange={handleAudioChange}
                                        handleKeywordChange={handleKeywordChange}
                                        handleTestChange={handleTestChange}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleAudioGenerate={handleAudioGenerate}
                                        handleRadioChange={handleRadioChange}
                                        handleDeleteScript={handleDeleteScript}
                                        handleAgentRecordedAudio={
                                          handleAgentRecordedAudio
                                        }
                                        handleAutopopulateChange={
                                          handleAutopopulateChange
                                        }
                                        handleResetAutopopulate={
                                          handleResetAutopopulate
                                        }
                                        updateData={updateData}
                                        borderColor={getBackground(script.type)}
                                        levelOrder={data.levels[currentIndex].order}
                                        newSim={newSim}
                                        currentVoice={script.voice
                                          ? [script.voice] : newSim.sim_characters.filter((char) =>
                                            char._id === script.sim_character_id).map(char => char.voice)}
                                      />
                                    </div>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            }
                          )}
                          
                        {provided.placeholder}
                       
                      </div>
                    )}
                     
                  </Droppable>
                </DragDropContext>
                <div>
                  {
                    data.levels.length > 0 &&
                    data.levels[currentIndex] &&
                    data.levels[currentIndex].scripts &&
                    <DropZone
                      data={{
                        path: `${rowPath}-${data.levels[currentIndex].scripts.length}`,
                        childrenCount: data.levels[currentIndex].scripts.length
                      }}
                      onDrop={handleDrop}
                      isLast
                    />
                  }
                </div>
                {
                  importLevel && data.levels[currentIndex].scripts.length === 0 ?
                    <div className={classes.loaderDiv}>
                      <CircularProgress color="primary" />
                    </div> : ''
                }
              </div>
            </Grid>
            <Grid
              className={
                data.levels &&
                  data.levels.length > 0 &&
                  data.levels[currentIndex] &&
                  data.levels[currentIndex].scripts.length === 0
                  ? classes.intialCharacterMainGrid
                  : classes.finalCharacterMainGrid
              }
              item
              xs={2} lg={2} xl={2} sm={2}
              key="card"
            >
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography className={classes.characterLabel}>
                  Characters
                </Typography>
                {data.sim_characters.map((character, index) => (
                  <Characters
                    bgColor={getBackground(character.type)}
                    avatar={character.avatar}
                    key={index}
                    character={character}
                    showDetails={() => handleDrop(character)}
                  />
                ))}
              </Grid>
            </Grid>
          </DndProvider>
        </Grid>
      </div >
    </Paper >
  );
};

CreateLevels.propTypes = {
  newSim: PropTypes.object,
  increaseStep: PropTypes.func,
  decreaseStep: PropTypes.func,
  currentSim: PropTypes.object,
  levels: PropTypes.array,
  fetchCurrentSim: PropTypes.func,
  addLevels: PropTypes.func,
  uploadLevels: PropTypes.func,
  fetchAutoPopulate: PropTypes.func,
  tabs: PropTypes.array,
  fetchSimDetails: PropTypes.func,
};

const mapStateToProps = (state) => ({
  status: state.sim.status,
  newSim: state.sim.newSim,
  currentSim: state.sim.currentSim,
  levels: state.sim.levels,
  characters: state.sim.characters,
  tabs: state.sim.autoPopulate,
});

const mapDispatchToProps = {
  increaseStep,
  decreaseStep,
  fetchCurrentSim,
  fetchCharacterList,
  addLevels,
  addNewLevels,
  uploadLevels,
  fetchAutoPopulate,
  fetchSimDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLevels);
