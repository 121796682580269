import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Tooltip,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useLevelsStyle } from './Createlevels';

const Levels = ({
  simGenerated,
  Level,
  levels,
  data,
  setData,
  levelOrder,
  setLevelOrder,
  deleted,
  flag,
  setFlag,
  setdeleted,
}) => {
  const classes = useLevelsStyle();
  const [currentIndex, setIndex] = React.useState(0);
  const [insertLevel, setInsertLevel] = useState(false);
  const [levelName, setlevelName] = useState('');
  const handleTabs = (level) => {
    const index = levels.findIndex((lvl) => lvl.order === level.order);
    setIndex(index);
    Level(level);
  };
  useEffect(() => {
    if (levels.length && flag) {
      const simData = { ...data };
      const { length } = simData.levels;
      const latestOrder = simData.levels[length - 1].order;
      setLevelOrder(latestOrder);
      setFlag(false);
    }
  }, [levels.length, flag, setLevelOrder, data, setFlag]);

  useEffect(() => {
    if (deleted) {
      setIndex(currentIndex - 1);
      setdeleted(false);
    }
  }, [deleted, setdeleted, currentIndex]);

  const save = () => {
    const simData = { ...data };
    simData.levels.push({
      _id: undefined,
      name: levelName,
      order: Math.max.apply(Math, levels.map((o) => o.order + 1)),
      hint_text: true,
      keyword_score: true,
      send_to_snowfly: false,
      scripts: [],
    });
    setLevelOrder(levelOrder + 1);
    setData(simData);
    setlevelName('');
    setInsertLevel(false);
  };

  const cancel = () => {
    setInsertLevel(false);
    setlevelName('');
  };

  const createNewLevel = () => {
    setInsertLevel(true);
  };
  return (
    <Grid item key="card" className={classes.firstGrid}>
      <Paper elevation={0}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid className={classes.levelHeader}>
            <Typography className={classes.levelLabel}>
              Levels
              <span className={classes.simGeneratedLabel}>
                <CheckCircleIcon className={classes.checkCircleIcon} />
                SIM GENERATED
              </span>
            </Typography>
          </Grid>
          <Grid className={classes.levels} item xs>
            {levels.map((level, index) => (
              <Box
                onClick={() => handleTabs(level)}
                className={
                    levels.length
                      && levels[currentIndex]
                      && levels[currentIndex].order === level.order
                      ? classes.activeCard
                      : classes.inacticeCard
                  }
                key={index}
              >
                <Typography className={classes.label}>
                  {simGenerated ? (
                    <CheckCircleIcon className={classes.checkCircleIcon} />
                  ) : null}
                  LEVEL
                  {' '}
                  {index + 1}
                  :
                  {data.is_pass_fail_criteria_set && data.pass_fail_id.sim_level_id === level._id
                    ? (
                      <Tooltip title="Pass/Fail is enabled for this level">
                        <AssignmentTurnedInIcon className={classes.passfailIcon} />
                      </Tooltip>
                    )
                    : null}
                  {/* {levels[currentIndex] &&
                    levels[currentIndex].name === level.name ? (
                      <RefreshIcon className={classes.refreshIcon} />
                    ) : null} */}
                </Typography>
                <Typography className={classes.levelName}>
                  {level.name}
                </Typography>
              </Box>
            ))}
            {insertLevel ? (
              <Box className={classes.inacticeCard}>
                <Typography className={classes.label}>
                  {simGenerated ? (
                    <CheckCircleIcon className={classes.checkCircleIcon} />
                  ) : null}
                  LEVEL
                  {' '}
                  {data.levels.length + 1}
                  :
                </Typography>
                <TextField
                  placeholder="Enter Level name"
                  className={classes.text}
                  value={levelName}
                  onChange={(e) => setlevelName(e.target.value)}
                />
                <div className={classes.textFielDiv}>
                  <Button
                    name="script"
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.saveBtn}
                    onClick={() => save()}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    size="small"
                    className={classes.cancelBtn}
                    onClick={() => cancel()}
                  >
                    Cancel
                  </Button>
                </div>
                {/* <div className={classes.rightArrow} /> */}
              </Box>
            ) : null}
            <Typography className={classes.addLevel} onClick={createNewLevel}>
              + ADD A LEVEL
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
Levels.propTypes = {
  simGenerated: PropTypes.bool,
  levels: PropTypes.array,
  Level: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
  levelOrder: PropTypes.number,
  setLevelOrder: PropTypes.func,
  deleted: PropTypes.bool,
  flag: PropTypes.bool,
  setFlag: PropTypes.func,
  setdeleted: PropTypes.func,
};
export default Levels;
