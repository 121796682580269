import React, { useEffect, useState } from 'react';
import lodash from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import {
  Divider,
  Toolbar,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useCharacterDataStyle } from './CharacterData';

const Autopopulate = ({
  data,
  tabs,
  handleResetAutopopulate,
  index,
  updateData,
  handleAutopopulateChange,
  caseInformationArray,
  patientInformationArray,
  acmeInformationArray,
  loremIpsumInformationArray,
}) => {
  const classes = useCharacterDataStyle();
  const [autopopulateData, setAutopopulateData] = React.useState([]);
  const [timer, setTimer] = useState(data.autopopulate_timer);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectValue, setSelectValue] = useState('None');

  useEffect(() => {
    if (data && data.autopopulate_data.length
      && data.autopopulate_data[0].tabs && data.autopopulate_data[0].tabs[0].sections) {
      setAutopopulateData(data.autopopulate_data[0].tabs[0].sections);
    }
  }, [data]);
  const openAutopopulateDialog = () => {
    if (data.autopopulate_data.length) {
      setAutopopulateData(data.autopopulate_data[0].tabs[0].sections);
    } else if (tabs.length) {
      setAutopopulateData(tabs[0].sections);
    } else {
      setAutopopulateData([]);
    }
    setTimer(data.autopopulate_timer);
    setOpenDialog(true);
  };
  const closeDialog = async () => {
    setSelectValue('None');
    setTimer(data.autopopulate_timer);
    const dataArray = [...data.autopopulate_data];
    setAutopopulateData(dataArray);
    setOpenDialog(false);
  };
  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };
  const autopopulateChange = async (event, tabIndex, fieldIndex) => {
    const newArray = lodash(autopopulateData);
    newArray[tabIndex].fields[fieldIndex].is_enabled = event.target.checked;
    setAutopopulateData(newArray);
  };
  const resetAutopopulate = async () => {
    await handleResetAutopopulate(index);
    await updateData(index, data);
    const dataArray = [...data.autopopulate_data];
    setAutopopulateData(dataArray);
    setTimer(0);
  };
  const setAutopopulate = async () => {
    await handleAutopopulateChange(index, autopopulateData, timer);
    await updateData(index, data);
    setOpenDialog(false);
    setSelectValue('None');
  };

  return (
    <Box className={classes.bottomBox}>
      <Divider />
      <Toolbar className={classes.toolBar}>
        <div style={{ display: 'contents' }}>
          <Button onClick={openAutopopulateDialog}>
            <Typography className={classes.autopopulateTypo}>
              Autopopulate:
            </Typography>
          </Button>
          <Box>
            {caseInformationArray.length === 0
            && patientInformationArray.length === 0
            && acmeInformationArray.length === 0
            && loremIpsumInformationArray.length === 0 ? (
              <em className={classes.toolBarValue}>(None)</em>
              ) : null}
            {caseInformationArray.length !== 0 ? (
              <Typography className={classes.toolBarLabel}>
                Case Information:&nbsp;
                <em className={classes.toolBarValue}>
                  {caseInformationArray.slice(0, 3).join(', ')}
                  &nbsp;
                  {caseInformationArray.length > 3 ? '...' : null}
                </em>
              </Typography>
            ) : null}
            {patientInformationArray.length !== 0 ? (
              <Typography className={classes.toolBarLabel}>
                Patient Information:&nbsp;
                <em className={classes.toolBarValue}>
                  {patientInformationArray.slice(0, 3).join(', ')}
                  &nbsp;
                  {patientInformationArray.length > 3 ? '...' : null}
                </em>
              </Typography>
            ) : null}
            {acmeInformationArray.length !== 0 ? (
              <Typography className={classes.toolBarLabel}>
                Acme Information:&nbsp;
                <em className={classes.toolBarValue}>
                  {acmeInformationArray.slice(0, 3).join(', ')}
                  &nbsp;
                  {acmeInformationArray.length > 3 ? '...' : null}
                </em>
              </Typography>
            ) : null}
            {loremIpsumInformationArray.length !== 0 ? (
              <Typography className={classes.toolBarLabel}>
                Lorem Ipsum Information:&nbsp;
                <em className={classes.toolBarValue}>
                  {loremIpsumInformationArray.slice(0, 3).join(', ')}
                  &nbsp;
                  {loremIpsumInformationArray.length > 3 ? '...' : null}
                </em>
              </Typography>
            ) : null}
          </Box>
        </div>
        <Dialog
          open={openDialog}
          onClose={closeDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Select Targets to fill in this level
          </DialogTitle>
          <DialogContent style={{ textAlign: 'center' }}>
            <div style={{ float: 'right' }}>
              Start after
              {' '}
              <TextField
                InputProps={{ className: classes.secondsTextField }}
                size="small"
                variant="outlined"
                type="number"
                value={timer}
                onChange={(e) => setTimer(e.target.value)}
              />
              {' '}
              Seconds
            </div>
            <Typography style={{ textAlign: 'left', float: 'left' }}>
              <strong>Select Tab</strong>
            </Typography>
            <br />
            <br />
            <FormControl>
              <Select
                value={selectValue}
                inputProps={{ className: classes.dropdown }}
                onChange={handleSelectChange}
              >
                <MenuItem value="None">None</MenuItem>
                {autopopulateData.map((tab, index) => (
                  <MenuItem key={index} value={tab.name}>
                    {tab.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            {autopopulateData.map((tabs, tabIndex) => (tabs.name === selectValue
              ? tabs.fields.map((field, fieldIndex) => (
                <div
                  key={fieldIndex}
                  style={{ textAlign: 'left', marginLeft: '14px' }}
                >
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            name={autopopulateData.fieldTitle}
                            value={autopopulateData.fieldTitle}
                            color="primary"
                            className={classes.formControlLabel}
                            checked={field.is_enabled}
                            onChange={(e) => autopopulateChange(
                              e,
                              tabIndex,
                              fieldIndex,
                            )}
                          />
                            )}
                        label={(
                          <Typography
                            className={classes.toolBarLabel}
                          >
                            {field.fieldTitle}
                          </Typography>
                            )}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                  <br />
                </div>
              ))
              : null))}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="contained" onClick={closeDialog}>
              Close
            </Button>
            <Button variant="contained" onClick={resetAutopopulate}>
              Reset
            </Button>
            <Button variant="contained" onClick={setAutopopulate}>
              Set auto-populate
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
    </Box>
  );
};
Autopopulate.propTypes = {
  data: PropTypes.object,
  tabs: PropTypes.array,
  handleResetAutopopulate: PropTypes.func,
  index: PropTypes.number,
  updateData: PropTypes.func,
  handleAutopopulateChange: PropTypes.func,
  caseInformationArray: PropTypes.array,
  patientInformationArray: PropTypes.array,
  acmeInformationArray: PropTypes.array,
  loremIpsumInformationArray: PropTypes.array,
};
export default Autopopulate;
