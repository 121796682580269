import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import background from '../assets/images/settingsbg.png';

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {},
  },
  input: {
    width: '14.5vw',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 13,
    fontWeight: '500',
    padding: 10,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(100),
      height: theme.spacing(100),
    },
  },
  profileCard: {
    height: 180,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${background})`,
    padding: 30,
  },
  avatar: {
    width: 85,
    border: '5px solid #1B325D',
    height: 85,
    margin: 'auto',
  },
  formControl: {
    '& .MuiInputBase-input': {
      backgroundColor: '#fff',
      fontSize: 13,
    },
    '& .MuiTypography-body1': {
      fontSize: 13,
      fontWeight: '500',
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.1rem',
    },
    width: '78%',
    margin: 'auto',
    paddingBottom: theme.spacing(2),
    fontSize: 13,
    fontWeight: '500',
  },
  label: {
    padding: theme.spacing(0, 2, 1, 0),
    marginTop: theme.spacing(4),
    color: '#000',
    fontSize: 'small',
    fontWeight: '500',
  },
  currentPwdLabel: {
    padding: theme.spacing(0, 2, 1, 0),
    marginTop: 8,
    color: '#000',
    fontSize: 'small',
    fontWeight: '500',
  },
  statusLabel: {
    padding: theme.spacing(0, 2, 1, 0),
    marginTop: 9,
    color: '#000',
    fontSize: 'small',
    fontWeight: '500',
  },
  cssOutlinedInput: {},
  cssFocused: {},
  notchedOutline: {},
  btnDiv: {
    textAlign: 'center',
    height: 200,
  },
  updateBtn: {
    color: 'rgb(136,38,71)',
    border: '2px solid rgb(136,38,71)',
    fontSize: 12,
    fontWeight: '600',
    background: 'none !important',
    width: '78%',
    marginTop: 20,
  },
  profileBtnDiv: {
    textAlign: 'center',
    padding: 10,
  },
  updateProfileBtn: {
    color: '#1B325D',
    fontSize: 12,
    fontWeight: '600',
    background: '#fff !important',
  },
  passwordBtnDiv: {
    textAlign: 'center',
    height: 70,
  },
  title: {
    padding: '20px 0 8px 28px',
    color: '#000',
    fontSize: 16,
    fontWeight: '700',
  },
  radioGroup: {
    marginLeft: 28,
    paddingBottom: theme.spacing(2),
  },
  height: {
    height: 208,
    boxShadow: 'none !important',
  },
  padding: {
    padding: '0 20px 20px 20px',
    boxShadow: 'none !important',
  },
  pwdError: {
    fontSize: 10,
  },
  paper: {
    boxShadow: 'none !important',
  },
  numFormat: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#fff',
      fontSize: 13,
      height: '2px !important',
    },
  },
  agentDisplay: {
    display: 'none',
  },
  errorAlert: {
    marginTop: -15,
    marginBottom: 6,
  },
}));
