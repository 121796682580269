export const ScoreFilterStyle = () => ({
  select: {
    height: 38,
    fontSize: 13,
  },
  option: {
    cursor: 'pointer',
  },
  toolIcon: {
    color: '#284375',
    minWidth: '25px !important',
    '& .MuiSvgIcon-root': {
      width: 21,
    },
  },
  texts: {
    '&  .MuiTypography-body1': {
      fontSize: 13,
      marginTop: 5,
    },
  },
  textool: {
    '&  .MuiTypography-body1': {
      fontSize: 13,
      marginTop: 2,
    },
  },
  listItem: {
    height: 28,
    marginLeft: 10,
  },
  chkbox: {
    '& .MuiSvgIcon-root': {
      height: 20,
      color: '#4165a6',
    },
  },
  showHide: {
    marginLeft: 15,
    borderBottom: '1px solid #827f7f',
    fontSize: 13,
    cursor: 'pointer',
  },
  nested: {
    height: 25,
    marginLeft: 38,
    padding: 10,
  },
  font: {
    '& .MuiTypography-displayBlock': {
      fontSize: 13,
    },
  },
  scroll: {
    height: 890,
    overflowX: 'hidden',
  },
  toolIconAssignment: {
    color: '#284375',
    minWidth: '25px !important',
    '& .MuiSvgIcon-root': {
      width: 18,
    },
  },
  assignmentTxt: {
    '& .MuiTypography-body1': {
      fontSize: '14px',
    },
  },
});

export default ScoreFilterStyle;
