export const ScoreTable = (theme) => ({
  thead: {
    backgroundColor: '#e1e1e1',
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    whiteSpace: 'nowrap',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '0.7rem',
  },
  bold: {
    fontWeight: 'bold',
  },
  built: {
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  tableRow: {
    cursor: 'pointer',
  },
  edit: {
    float: 'left',
  },
  delete: {
    float: 'right',
  },
  name: {
    color: '#882e47',
    fontWeight: 600,
  },
  overflow: {
    width: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
  tableSpan: {
    borderBottom: '1px solid #6d6c6c',
    paddingBottom: 2,
    color: '#212121',
  },
  rfrTableRow: {
    cursor: 'pointer',
    background: '#f8dee7',
  },
  assessor: {
    display: 'block',
    marginLeft: 'auto',
    color: 'white',
    fontSize: 15,
    fontWeight: 600,
    marginRight: 'auto',
    width: '18%',
    cursor: 'pointer',
    background: '#555555e8',
    textAlign: 'center',
    borderRadius: '50%',
  },
  dialog: {
    minHeight: '39vh',
    maxHeight: '26vh',
    maxWidth: '30vw',
  },
  date: {
    whiteSpace: 'nowrap',
  },
  txtAlign: {
    textAlign: 'center',
  },
  editSpan: {
    float: 'right',
  },
  button: {
    fontWeight: 600,
    padding: '3px 11px',
    backgroundColor: '#F8DEE7 !important',
    color: '#A55B73',
    margin: '8px 5px',
  },
  textArea: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
    backgroundColor: '#F1F1F1',
    marginTop: '-18px',
    borderRadius: '4px',
    height: '21vh',
  },
  noData: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    textAlign: 'center',
  },
  tableContainer: {
    transform: 'rotateX(180deg)',
  },
  table: {
    transform: 'rotateX(180deg)',
  },
  pagination: {
    padding: 13,
    float: 'right',
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      background: '#26344D',
    },
  },
  paginationLabel: {
    padding: 17,
    float: 'left',
  },
});

export default ScoreTable;
