import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  InputLabel,
  Paper,
  TextField,
  Typography,
  Dialog,
  IconButton,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loginUser, fetchPermissionDetails, updateLoginStatus } from '../../redux/authentication/action';
import { resetPassword } from '../../services/result.service';
import loginBg from '../../assets/images/login/Login_Image_noBG.png';
import iLearnLogo from '../../assets/images/icons/iLearnLogo.png';
import LoginStyle from './forgotpassword';

const ForgotPassword = ({
  isAuth,
  fetchPermissionDetails,
  errMessage,
  loginStatus,
  updateLoginStatus,

}) => {
  const useStyles = makeStyles((theme) => LoginStyle(theme));
  const classes = useStyles();
  const [confirmpassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [openRegistration, setOpenRegistration] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMesage, setAlertMessage] = React.useState('');
  const [alertSeverity, setSeverity] = React.useState('');
  const [flag, setFlag] = React.useState(false);

  const submitForm = async (e) => {
    setFlag(false);
    e.preventDefault();
    if (password.trim() !== '' && confirmpassword.trim() !== '') {
      const passcheck = confirmpassword;
      if (password !== passcheck) {
        setAlertMessage('Password doesn\'t match');
        setSeverity('error');
        setOpenAlert(true);
      } else {
        //alert('correct');
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('email');

        resetPassword({ email: myParam, password });
        setAlertMessage('Password has been reset successfully. Go back to Sign in page for signing in');
        setOpenAlert(true);
        setSeverity('success');
      }
    } else {
      alert('Please enter the Credentials');
    }
  };

  if (isAuth) {
    fetchPermissionDetails();
    return (
      <Redirect to={localStorage.getItem('redirectUrl') || '/dashboard'} />
    );
  }

  if (!isAuth && loginStatus === false && !flag) {
    setFlag(true);
    updateLoginStatus(null);
    setAlertMessage(errMessage);
    setOpenAlert(true);
    setSeverity('error');
  }

  const handleClose = () => {
    setOpenRegistration(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const loginPage = () => {
    window.location.replace('/login')
  }

  return (
    <div className={classes.root}>
      {openAlert ? (
        <Alert
          variant="filled"
          onClose={handleAlertClose}
          severity={alertSeverity}
          className={alertSeverity === 'error' ? classes.errorAlert : ''}
        >
          {alertMesage}
        </Alert>
      ) : null}
      <Dialog open={openRegistration} fullWidth maxWidth="xs" onClose={handleClose}>
        <div>
          <IconButton onClick={() => setOpenRegistration(false)} style={{ float: 'right' }}>
            <CloseIcon
              style={{
                transform: 'scale(4)',
                backgroundColor: '#E9E9E9',
                color: '#B1B1B1',
                margin: '7px 5px 0px 0px',
                width: '4px',
                height: '4px',
                padding: 1,
                borderRadius: '1px',
              }}
            />
          </IconButton>
        </div>
      </Dialog>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Box component="div" ml={5}>
            <img alt="logo" src={iLearnLogo} width="85vw" />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            container="true"
            component="div"
            borderRadius="borderRadius"
            className={classes.navContainer}
            mr={5}
            fontWeight="fontWeightLight"
          >
            <Button className={classes.linkFont} size="small">
              CONTACT US
            </Button>
            <span>|</span>
            <Button className={classes.linkFont} size="small">
              ABOUT US
            </Button>
            <span>|</span>
            <Button className={classes.linkFont} size="small">
              HELP
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Box mt={5}>
            <Grid className={classes.ligthBg} container>
              <Grid item xs={7}>
                <Box component="div" mt={8}>
                  <img alt="logo" src={loginBg} width="90%" />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box component="div">
                  <Paper
                    className={`${classes.root} ${classes.loginCardContainer}`}
                    elevation={0}
                  >
                    <Typography
                      variant="subtitle1"
                      align="center"
                      className={classes.boldFont}
                    >
                      Change Password
                    </Typography>
                    <form className={classes.formControl} onSubmit={submitForm}>
                      <InputLabel className={classes.label}>
                        New Password
                      </InputLabel>
                      <TextField
                        id="password-input"
                        className={classes.loginInputFieldPadding}
                        style={{ width: '100% !important' }}
                        required
                        type="password"
                        placeholder="Enter New Password"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                          inputMode: 'string',
                        }}
                        variant="filled"
                        size="small"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputLabel className={classes.label}>
                        Confirm Password
                      </InputLabel>
                      <TextField
                        className={classes.loginInputFieldPadding}
                        id="confirm-password-input"
                        required
                        type="password"
                        placeholder="Re-enter password"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                          inputMode: 'string',
                        }}
                        variant="filled"
                        size="small"
                        fullWidth
                        value={confirmpassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <Box mt={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          color="primary"
                          disableElevation
                        >
                          SAVE PASSWORD
                        </Button>
                      </Box>
                      <Box className={classes.checkBoxContainer}>
                        <Button color="primary">
                          <Typography variant="caption" style={{ whiteSpace: 'noWrap' }}>
                            <Button
                              className={classes.boldFont}
                              onClick={loginPage}
                            >
                              Go Back To Sign in Page
                            </Button>
                          </Typography>
                        </Button>
                      </Box>
                      <Box my={3}>
                        <Typography
                          className={classes.borderLine}
                          type="title"
                          color="inherit"
                        />
                      </Box>
                    </form>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

ForgotPassword.propTypes = {
  isAuth: PropTypes.bool,
  loginUser: PropTypes.func,
  fetchPermissionDetails: PropTypes.func,
  errMessage: PropTypes.string,
  loginStatus: PropTypes.any,
  updateLoginStatus: PropTypes.func,
};
const mapStateToProps = (state) => ({
  isAuth: state.login.isAuth,
  errMessage: state.login.errMessage,
  loginStatus: state.login.loginStatus,
});

const mapDispatchToProps = {
  loginUser,
  fetchPermissionDetails,
  updateLoginStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
