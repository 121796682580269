import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {
  Grid,
  Paper,
  Typography,
  InputLabel,
  TextField,
  NativeSelect,
  Radio,
  Button,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InputBase from '@material-ui/core/InputBase';
import NumberFormat from 'react-number-format';
import StyledLink from '../common/StyledLink';
import {
  fetchRoleData,
  fetchSelectGroups,
} from '../../redux/admin/action';
import {
  saveUser,
  fetchCurrentUserData,
} from '../../redux/sims/actions';
import TagInput from '../settings/TagInput';
import { duplicateEmailCheck } from '../../services/result.service';
import CreateUserStyle from './CreateUser';

const useQuery = () => new URLSearchParams(useLocation().search);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
    },
  },
  input: {
    width: '14.5vw',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 13,
    fontWeight: '500',
    padding: 10,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const Settings = ({
  userData,
  selectRoles,
  selectGroups,
  fetchRoleData,
  fetchSelectGroups,
  saveUser,
  createUserData,
  fetchCurrentUserData,
}) => {
  const query = useQuery();
  const useStyles = makeStyles((theme) => CreateUserStyle(theme));
  const classes = useStyles();
  const [subStatus, setSubStatus] = useState(['Candidate', 'Offered', 'Refused', 'No Offer', 'Hired']);
  const [empty, setEmpty] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [state, setState] = useState({
    first_name: userData.first_name,
    last_name: userData.last_name,
    nick_name: userData.nick_name,
    title: userData.title,
    email_id: userData.email_id,
    phone_number: userData.phone_number,
    password: userData.password,
    status: 'Applicant',
    sub_status: 'Candidate',
    role: userData.role,
    schedule: userData.schedule,
    duration: userData.duration,
    employment: userData.employment,
    workplace: userData.workplace,
    joined_company: userData.joined_company,
    probation: userData.probation,
    promoted: userData.promoted,
    groups: userData.groups,
    outsourcer: userData.outsourcer,
  });
  const [confirmPwd, setConfirmPwd] = useState('');
  const [matchFlag, setMatchFlag] = useState(false);
  const [duplicateMail, setDuplicateMail] = useState(false);
  const [email, setEmail] = useState(userData.email_id);
  const [newPassword, setNewPassword] = useState(false);
  useEffect(() => {
    if (query.get('userId')) {
      fetchCurrentUserData(query.get('userId'));
      fetchSelectGroups();
    }
  }, [fetchCurrentUserData, fetchSelectGroups]);// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (userData._id) {
      if (userData.status === 'active') {
        setSubStatus(['Training', 'Nesting', 'Operations']);
      } else if (userData.status === 'inActive') {
        setSubStatus(['Suspended', 'Leave of Absence']);
      } else if (userData.status === 'postEmployment') {
        setSubStatus(['Promotion Non-CC', 'Voluntary Separation', 'Termination']);
      }
      setState({
        _id: userData._id,
        first_name: userData.first_name,
        last_name: userData.last_name,
        nick_name: userData.nick_name,
        title: userData.title,
        email_id: userData.email_id,
        phone_number: userData.phone_number,
        password: '',
        status: userData.status,
        sub_status: userData.sub_status,
        role: userData.role,
        schedule: userData.schedule,
        duration: userData.duration,
        employment: userData.employment,
        workplace: userData.workplace,
        joined_company: userData.joined_company,
        probation: userData.probation,
        promoted: userData.promoted,
        groups: userData.groups,
        outsourcer: userData.outsourcer,
      });
      setEmail(userData.email_id);
    }
  }, [userData]);
  useEffect(() => {
    if (Object.keys(selectRoles).length === 0) {
      fetchRoleData();
    } else {
      setState({
        ...state,
        role: selectRoles[0]._id,
      });
    }
  }, [fetchRoleData, selectRoles]);// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (Object.keys(selectGroups).length === 0) {
      fetchSelectGroups();
    }
  }, [fetchSelectGroups, selectGroups]);
  const handleChange = (evt) => {
    const { value } = evt.target;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    if (evt.target.name === 'password' && newPassword) {
      setNewPassword(false);
    } else if (evt.target.name === 'password' && evt.target.value === '' && state.email_id !== email) {
      setNewPassword(true);
    }
  };
  const confirmPasswordChange = (event) => {
    const { value } = event.target;
    setConfirmPwd(value);
    if (event.target.name === 'confirm_password') {
      setMatchFlag(true);
      if (state.password === value) {
        setMatchFlag(false);
      }
    }
  };
  const handleStatusChange = (evt) => {
    const { value } = evt.target;
    if (value === 'active') {
      setSubStatus(['Training', 'Nesting', 'Operations']);
      setState({
        ...state,
        [evt.target.name]: value,
        sub_status: 'Training',
      });
    } else if (value === 'inActive') {
      setSubStatus(['Suspended', 'Leave of Absence']);
      setState({
        ...state,
        [evt.target.name]: value,
        sub_status: 'Suspended',
      });
    } else if (value === 'postEmployment') {
      setSubStatus(['Promotion Non-CC', 'Voluntary Separation', 'Termination']);
      setState({
        ...state,
        [evt.target.name]: value,
        sub_status: 'Promotion Non-CC',
      });
    } else {
      setSubStatus(['Candidate', 'Offered', 'Refused', 'No Offer', 'Hired']);
      setState({
        ...state,
        [evt.target.name]: value,
        sub_status: 'Candidate',
      });
    }
  };
  const handleGroupChange = (value) => {
    setState({
      ...state,
      groups: value,
    });
  };
  const history = useHistory();
  const onSave = async () => {
    if ((!query.get('userId') && (Object.entries(state).some((ele) => (ele[0] !== 'nick_name' && (ele[1] === undefined || ele[1].length <= 0))) || state.groups.length === 0))
    || (query.get('userId') && Object.entries(state).some((ele) => (ele[0] !== 'password' && ele[0] !== 'nick_name' && (ele[1] === undefined || ele[1].length <= 0))))) {
      setEmpty(true);
    } else if ((query.get('userId') && state.password !== '' && confirmPwd === '')) {
      setEmpty(true);
    } else if ((query.get('userId') && state.password === '' && newPassword)) {
      setEmpty(true);
    } else if (query.get('userId') && !duplicateMail) {
      state.url = window.location.origin;
      await saveUser(state);
      alert('No Email will be sent, That service is temporally unavailable');
      history.push('/admin');
    } else if (!duplicateMail) {
      state.url = window.location.origin;
      await saveUser(state);
      alert('No Email will be sent, That service is temporally unavailable');
      createUserData(false);
    }
  };

  const emailCheck = () => {
    const id = state._id ? state._id : '';
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidEmail(re.test(String(state.email_id).toLowerCase()));
    duplicateEmailCheck(state.email_id, id).then((res) => {
      if (res.data.success) {
        setDuplicateMail(true);
      } else {
        setDuplicateMail(false);
      }
    });
    if (email !== '' && email !== state.email_id) {
      setNewPassword(true);
    }
  };

  return (
    <>
      {query.get('userId')
        ? (
          <Breadcrumbs aria-label="breadcrumb" className={classes.bcBlock}>
            <StyledLink
              to="/dashboard"
              className={classes.breadcrumbs}
            >
              Dashboard
            </StyledLink>

            <StyledLink
              color="inherit"
              underline="always"
              className={classes.breadcrumbs}
              to="/admin"
              onClick={createUserData}
            >
              Employee list
            </StyledLink>
          </Breadcrumbs>
        )
        : ''}
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper elevation={2} className={classes.paper}>
            <div className={classes.formControl}>
              <InputLabel className={classes.initialLabel}>
                First Name
                <span className={classes.asterisk}> *</span>
              </InputLabel>
              <TextField
                id="firstName"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  inputMode: 'string',
                }}
                variant="outlined"
                size="small"
                fullWidth
                name="first_name"
                value={state.first_name}
                error={!state.first_name && empty}
                onChange={handleChange}
              />
              <InputLabel className={classes.label}>
                Last Name
                <span className={classes.asterisk}> *</span>
              </InputLabel>
              <TextField
                id="lastName"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  inputMode: 'string',
                }}
                variant="outlined"
                size="small"
                fullWidth
                name="last_name"
                value={state.last_name}
                error={!state.last_name && empty}
                onChange={handleChange}
              />
              <InputLabel className={classes.label}>Nickname</InputLabel>
              <TextField
                required
                variant="outlined"
                size="small"
                fullWidth
                name="nick_name"
                value={state.nick_name}
                onChange={handleChange}
              />
              <InputLabel className={classes.label}>
                Title
                <span className={classes.asterisk}> *</span>
              </InputLabel>
              <TextField
                id="title"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  inputMode: 'string',
                }}
                variant="outlined"
                size="small"
                fullWidth
                name="title"
                value={state.title}
                error={!state.title && empty}
                onChange={handleChange}
              />
              <InputLabel className={classes.label}>
                Email Address
                <span className={classes.asterisk}> *</span>
              </InputLabel>
              <TextField
                id="email"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.email}
                variant="outlined"
                size="small"
                fullWidth
                name="email_id"
                helperText={duplicateMail ? 'Duplicate Email !'
                  : !validEmail ? 'Invalid Email Address' : ''}
                value={state.email_id}
                error={!state.email_id && empty}
                onChange={handleChange}
                onBlur={emailCheck}
              />
              <InputLabel className={classes.label}>
                Phone number
                <span className={classes.asterisk}> *</span>
              </InputLabel>
              <NumberFormat
                variant="outlined"
                onChange={handleChange}
                customInput={TextField}
                name="phone_number"
                value={state.phone_number}
                error={!state.phone_number && empty}
                required
                className={classes.numFormat}
                format="+1 (###) ###-####"
                mask="_"
              />
              <InputLabel className={classes.label}>
                Password
                <span className={classes.asterisk}>
                  {query.get('userId') ? '' : '*'}
                  {' '}
                </span>
              </InputLabel>
              <TextField
                id="newPassword"
                required
                placeholder="Enter new password"
                className={classes.pwd}
                variant="outlined"
                name="password"
                value={state.password}
                type="text"
                helperText={newPassword ? 'Please re-enter the password.' : ''}
                error={!query.get('userId') && !state.password && empty}
                onChange={handleChange}
                size="small"
                fullWidth
              />
              {query.get('userId')
                ? (
                  <>
                    <InputLabel className={classes.label}>
                      Confirm Password
                      <span className={classes.asterisk}>
                        {query.get('userId') ? '' : '*'}
                        {' '}
                      </span>
                    </InputLabel>
                    <TextField
                      id="confirm_password"
                      required
                      error={!query.get('userId') && !confirmPwd && empty}
                      placeholder="Re-enter new password"
                      name="confirm_password"
                      helperText={matchFlag ? 'Password mismatch !' : ''}
                      onChange={confirmPasswordChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      value={confirmPwd}
                      className={classes.helperText}
                    />
                  </>
                ) : ''}

            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>
              Status details
            </Typography>
            <form className={classes.formControl}>
              <InputLabel id="selectLable" className={classes.statusLabel}>
                Status
                <span className={classes.asterisk}> *</span>
              </InputLabel>
              <NativeSelect
                lableid="selectLable"
                id="selectStatus"
                name="status"
                input={<BootstrapInput />}
                value={state.status}
                error={!state.status && empty}
                onChange={handleStatusChange}
                variant="outlined"
                required
                IconComponent={KeyboardArrowDownIcon}
              >
                <option value="applicant">Applicant</option>
                <option value="active">Active</option>
                <option value="inActive">Inactive</option>
                <option value="postEmployment">Post-employment</option>
              </NativeSelect>
              <InputLabel id="selectLable" className={classes.label}>
                Sub-status
                {' '}
                <span className={classes.asterisk}> *</span>
              </InputLabel>
              <NativeSelect
                lableid="selectLable"
                id="selectSubStatus"
                name="sub_status"
                input={<BootstrapInput />}
                value={state.sub_status}
                error={!state.sub_status && empty}
                onChange={handleChange}
                variant="outlined"
                required
                IconComponent={KeyboardArrowDownIcon}
              >
                {subStatus.map((sub) => (<option key={sub}>{sub}</option>))}
              </NativeSelect>
              <InputLabel id="selectLable" className={classes.label}>
                Role
                {' '}
                <span className={classes.asterisk}> *</span>
              </InputLabel>
              <NativeSelect
                lableid="selectLable"
                id="selectRole"
                name="role"
                input={<BootstrapInput />}
                value={state.role}
                error={!state.role && empty}
                onChange={handleChange}
                variant="outlined"
                required
                IconComponent={KeyboardArrowDownIcon}
              >
                {selectRoles.map((role) => (
                  <option key={role._id} value={role._id}>
                    {role.name}
                  </option>
                ))}
              </NativeSelect>
            </form>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>
              Schedule
              {!state.schedule && empty ? (
                <span className={classes.error}>
                  { ' '}
                  {' '}
                  Schedule is required
                </span>
              )
                : <span className={classes.asterisk}>  *</span> }
            </Typography>
            <FormControl
              component="fieldset"
              error={state.schedule === '' && empty}
              className={classes.formControl}
            >
              <RadioGroup
                className={classes.radioGroup}
                aria-label="schedule"
                name="schedule"
                value={state.schedule}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Full time"
                  control={<Radio color="primary" size="small" />}
                  label="Full Time"
                />
                <FormControlLabel
                  value="Part time"
                  control={<Radio color="primary" size="small" />}
                  label="Part time"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>
              Duration
              {!state.duration && empty ? (
                <span className={classes.error}>
                  { ' '}
                  {' '}
                  Duration is required
                </span>
              )
                : <span className={classes.asterisk}>  *</span> }
            </Typography>
            <FormControl
              component="fieldset"
              error={state.duration === '' && empty}
              className={classes.formControl}
            >
              <RadioGroup
                className={classes.radioGroup}
                aria-label="duration"
                name="duration"
                value={state.duration}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Permanent"
                  control={<Radio color="primary" size="small" />}
                  label="Permanent"
                />
                <FormControlLabel
                  value="Temporary"
                  control={<Radio color="primary" size="small" />}
                  label="Temporary"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>
              Employment
              {!state.employment && empty ? (
                <span className={classes.error}>
                  { ' '}
                  {' '}
                  Employment is required
                </span>
              )
                : <span className={classes.asterisk}>  *</span> }
            </Typography>
            <FormControl
              component="fieldset"
              error={state.employment === '' && empty}
              className={classes.formControl}
            >
              <RadioGroup
                className={classes.radioGroup}
                aria-label="employment"
                name="employment"
                value={state.employment}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Employee"
                  control={<Radio color="primary" size="small" />}
                  label="Employee"
                />
                <FormControlLabel
                  value="Contract"
                  control={<Radio color="primary" size="small" />}
                  label="Contract"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>
              Workplace
              {!state.workplace && empty ? (
                <span className={classes.error}>
                  { ' '}
                  {' '}
                  Workplace is required
                </span>
              )
                : <span className={classes.asterisk}>  *</span> }
            </Typography>
            <FormControl
              component="fieldset"
              error={state.workplace === '' && empty}
              className={classes.formControl}
            >
              <RadioGroup
                className={classes.radioGroup}
                aria-label="workplace"
                name="workplace"
                value={state.workplace}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Premise"
                  control={<Radio color="primary" size="small" />}
                  label="Premise"
                />
                <FormControlLabel
                  value="WAH"
                  control={<Radio color="primary" size="small" />}
                  label="WAH"
                />
                <FormControlLabel
                  value="off site"
                  control={<Radio color="primary" size="small" />}
                  label="off site"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>
              Joined company
              {!state.joined_company && empty ? (
                <span className={classes.error}>
                  { ' '}
                  {' '}
                  Joined company is required
                </span>
              )
                : <span className={classes.asterisk}>  *</span> }
            </Typography>
            <FormControl
              component="fieldset"
              error={state.joined_company === '' && empty}
              className={classes.formControl}
            >
              <RadioGroup
                className={classes.radioGroup}
                aria-label="joined_company"
                name="joined_company"
                value={state.joined_company}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="New hire"
                  control={<Radio color="primary" size="small" />}
                  label="New hire"
                />
                <FormControlLabel
                  value="Transfer"
                  control={<Radio color="primary" size="small" />}
                  label="Transfer"
                />
                <FormControlLabel
                  value="Rehire"
                  control={<Radio color="primary" size="small" />}
                  label="Rehire"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.padding}>
            <Typography className={classes.groupTitle}>
              Groups
              {state.groups.length === 0 && empty ? (
                <span className={classes.error}>
                  { ' '}
                  {' '}
                  Group is required
                </span>
              )
                : <span className={classes.asterisk}>  *</span> }
            </Typography>
            {
            query.get('userId')
              ? userData.groups.length && selectGroups.length
                ? (
                  <TagInput
                    selectGroups={selectGroups}
                    userData={userData}
                    handleGroupChange={handleGroupChange}
                  />
                ) : null
              : userData.groups && selectGroups
                ? (
                  <TagInput
                    selectGroups={selectGroups}
                    userData={userData}
                    handleGroupChange={handleGroupChange}
                  />
                ) : null
          }
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>
              Probation
              {!state.probation && empty ? (
                <span className={classes.error}>
                  { ' '}
                  {' '}
                  Probation is required
                </span>
              )
                : <span className={classes.asterisk}>  *</span> }
            </Typography>
            <FormControl
              component="fieldset"
              error={state.probation === '' && empty}
              className={classes.formControl}
            >
              <RadioGroup
                className={classes.radioGroup}
                aria-label="probation"
                name="probation"
                value={state.probation}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="New hire"
                  control={<Radio color="primary" size="small" />}
                  label="New hire"
                />
                <FormControlLabel
                  value="Performance"
                  control={<Radio color="primary" size="small" />}
                  label="Performance"
                />
                <FormControlLabel
                  value="NA"
                  control={<Radio color="primary" size="small" />}
                  label="NA"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.height}>
            <Typography className={classes.title}>
              Promoted in CC
              {!state.promoted && empty ? (
                <span className={classes.error}>
                  { ' '}
                  {' '}
                  Promoted in CC is required
                </span>
              )
                : <span className={classes.asterisk}>  *</span> }
            </Typography>
            <FormControl component="fieldset" error={state.promoted === '' && empty} className={classes.formControl}>
              <RadioGroup
                className={classes.radioGroup}
                aria-label="promoted"
                name="promoted"
                value={state.promoted}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Leadership"
                  control={<Radio color="primary" size="small" />}
                  label="Leadership"
                />
                <FormControlLabel
                  value="Support"
                  control={<Radio color="primary" size="small" />}
                  label="Support"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>
              Outsourcer
              {!state.outsourcer && empty ? (
                <span className={classes.error}>
                  { ' '}
                  {' '}
                  Outsourcer is required
                </span>
              )
                : <span className={classes.asterisk}>  *</span> }
            </Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                className={classes.radioGroup}
                name="outsourcer"
                value={state.outsourcer}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="TTEC"
                  control={<Radio color="primary" size="small" />}
                  label="TTEC"
                />
                <FormControlLabel
                  value="Alta"
                  control={<Radio color="primary" size="small" />}
                  label="Alta"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio color="primary" size="small" />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
        </Grid>
        <div className={classes.buttonDiv}>
          <Button
            className={classes.saveBtn}
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
          { query.get('userId')
            ? (
              <StyledLink
                color="inherit"
                to="/admin"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.cancelBtn}
                >
                  Cancel
                </Button>
              </StyledLink>
            )
            : ''}

        </div>
      </Grid>
    </>
  );
};
Settings.propTypes = {
  userData: PropTypes.object,
  selectRoles: PropTypes.array,
  selectGroups: PropTypes.array,
  fetchRoleData: PropTypes.func,
  fetchSelectGroups: PropTypes.func,
  saveUser: PropTypes.func,
  createUserData: PropTypes.func,
  fetchCurrentUserData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  userData: state.sim.currentUserData ? state.sim.currentUserData : state.admin.userData,
  selectRoles: state.admin.selectRoles,
  selectGroups: state.admin.selectGroups,
  result: state.admin.result,
});

const mapDispatchToProps = {
  fetchRoleData,
  fetchSelectGroups,
  saveUser,
  fetchCurrentUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
