// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); // inserting task in new index
  return result;
};

export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

export const reorderChildren = (children, splitDropZonePath, splitItemPath) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    const itemIndex = Number(splitItemPath[0]);
    return reorder(children, itemIndex, dropZoneIndex);
  }

  // Update the specific node's children
  const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
  const splitItemChildrenPath = splitItemPath.slice(1);
  const abc = reorderChildren(
    children.scripts,
    splitDropZoneChildrenPath,
    splitItemChildrenPath,
  );

  const newData = {
    createdAt: children.createdAt,
    deleted: children.deleted,
    hint_text: children.hint_text,
    keyword_score: children.keyword_score,
    name: children.name,
    order: children.order,
    real_time_feedback: children.real_time_feedback,
    sim_id: children.sim_id,
    updatedAt: children.updatedAt,
    _id: children._id,
    scripts: abc,
  };
  return newData;
};

export const addChildToChildren = (layout, splitDropZonePath, item) => {

  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    return insert(layout.scripts, dropZoneIndex, item);
  }

  // Update the specific node's children
  const splitItemChildrenPath = splitDropZonePath.slice(1);

  const data = addChildToChildren(layout, splitItemChildrenPath, item);
  const newData = {
    scripts: data,
  };
  return newData;
};

export const handleMoveWithinParent = (
  layout,
  splitDropZonePath,
  splitItemPath,
) => reorderChildren(layout, splitDropZonePath, splitItemPath);

export const handleMoveSidebarComponentIntoParent = (
  layout,
  splitDropZonePath,
  item,
) => {
  let newLayoutStructure;
  switch (splitDropZonePath.length) {
    case 2: {
      newLayoutStructure = item;
      break;
    }
    default: {
      newLayoutStructure = item;
    }
  }
  return addChildToChildren(layout, splitDropZonePath, newLayoutStructure);
};
