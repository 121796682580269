import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import avatar1 from '../../assets/images/avatars/avatar-1.png';
import avatar2 from '../../assets/images/avatars/avatar-2.png';
import avatar3 from '../../assets/images/avatars/avatar-3.png';
import avatar4 from '../../assets/images/avatars/avatar-4.png';
import avatar5 from '../../assets/images/avatars/avatar-5.png';
import avatar6 from '../../assets/images/avatars/avatar-6.png';
import avatar7 from '../../assets/images/avatars/avatar-7.png';
import avatar8 from '../../assets/images/avatars/avatar-8.png';
import avatar9 from '../../assets/images/avatars/avatar-9.png';
import avatar10 from '../../assets/images/avatars/avatar-10.png';
import avatar11 from '../../assets/images/avatars/avatar-11.png';
import avatar12 from '../../assets/images/avatars/avatar-12.png';
import avatar13 from '../../assets/images/avatars/avatar-13.png';
import avatar14 from '../../assets/images/avatars/avatar-14.png';
import avatar16 from '../../assets/images/avatars/avatar-16.png';
import avatar17 from '../../assets/images/avatars/avatar-17.png';
import avatar18 from '../../assets/images/avatars/avatar-18.png';
import avatar19 from '../../assets/images/avatars/avatar-19.png';
import avatar15 from '../../assets/images/avatars/avatar-15.png';
import avatar20 from '../../assets/images/avatars/avatar-20.png';
import avatar21 from '../../assets/images/avatars/avatar-21.png';
import avatar22 from '../../assets/images/avatars/avatar-22.png';
import avatar23 from '../../assets/images/avatars/avatar-23.png';
import avatar24 from '../../assets/images/avatars/avatar-24.png';

const GetAvatar = ({
  avatar,
  borderColor,
  classes,
  alt,
}) => {
  let pic = '';
  switch (avatar) {
    case 'avatar-1':
      pic = (<Avatar variant="circular" className={classes} src={avatar1} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-2':
      pic = (<Avatar variant="circular" className={classes} src={avatar2} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-3':
      pic = (<Avatar variant="circular" className={classes} src={avatar3} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-4':
      pic = (<Avatar variant="circular" className={classes} src={avatar4} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-5':
      pic = (<Avatar variant="circular" className={classes} src={avatar5} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-6':
      pic = (<Avatar variant="circular" className={classes} src={avatar6} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-7':
      pic = (<Avatar variant="circular" className={classes} src={avatar7} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-8':
      pic = (<Avatar variant="circular" className={classes} src={avatar8} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-9':
      pic = (<Avatar variant="circular" className={classes} src={avatar9} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-10':
      pic = (<Avatar variant="circular" className={classes} src={avatar10} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-11':
      pic = (<Avatar variant="circular" className={classes} src={avatar11} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-12':
      pic = (<Avatar variant="circular" className={classes} src={avatar12} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-13':
      pic = (<Avatar variant="circular" className={classes} src={avatar13} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-14':
      pic = (<Avatar variant="circular" className={classes} src={avatar14} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-15':
      pic = (<Avatar variant="circular" className={classes} src={avatar15} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-16':
      pic = (<Avatar variant="circular" className={classes} src={avatar16} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-17':
      pic = (<Avatar variant="circular" className={classes} src={avatar17} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-18':
      pic = (<Avatar variant="circular" className={classes} src={avatar18} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-19':
      pic = (<Avatar variant="circular" className={classes} src={avatar19} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-20':
      pic = (<Avatar variant="circular" className={classes} src={avatar20} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-21':
      pic = (<Avatar variant="circular" className={classes} src={avatar21} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-22':
      pic = (<Avatar variant="circular" className={classes} src={avatar22} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-23':
      pic = (<Avatar variant="circular" className={classes} src={avatar23} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    case 'avatar-24':
      pic = (<Avatar variant="circular" className={classes} src={avatar24} alt={alt} style={{ border: `1px solid ${borderColor}` }} />);
      break;
    default:
      pic = (<Avatar variant="circular" className={classes} src={avatar1} alt="alt" style={{ border: `1px solid ${borderColor}` }} />);
  }
  return pic;
};

GetAvatar.propTypes = {
  avatar: PropTypes.string,
  borderColor: PropTypes.string,
  classes: PropTypes.string,
  alt: PropTypes.string,
};

export default GetAvatar;
