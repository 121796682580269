import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

const ACCEPTS = ['sidebarItem', 'component'];

const DropZone = ({ data, onDrop, isLast }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item) => {
      onDrop(data, item);
    },
    canDrop: (item) => {
      const itemPath = item.path;

      // sidebar items can always be dropped anywhere
      if (!itemPath) {
        return true;
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop;
  return (
    <div
      className={classNames(
        'dropZone',
        { active: isActive, isLast },
      )}
      ref={drop}
    />
  );
};
DropZone.propTypes = {
  data: PropTypes.object,
  onDrop: PropTypes.func,
  isLast: PropTypes.bool,
};

export default DropZone;
