/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel, Grid, Select, TextField, Button, Tooltip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getSims } from '../../services/result.service';
import {
  addAssignment,
  fetchProductList,
  fetchUserList,
  fetchGroupList,
} from '../../redux/sims/actions';
import AddAssignmentSyle from './AddAssignment';

const AddAssignment = ({
  open, handleClose,
  addAssignment, fetchProductList, products, fetchUserList, users,
  fetchGroupList, groups,
}) => {
  const [state, setState] = useState({
    tool: '',
    assigned_sim: '',
    user_id: '',
    group_id: ' ',
    // limit: '',
    assignment_notes: ' ',
  });
  const [sims, setSims] = useState([]);
  const [assignClick, setAssignClick] = useState(false);
  const getSimsOfProduct = (productId) => {
    getSims(productId)
      .then((res) => {
        setSims(res.data);
        setState((prevData) => ({
          ...prevData,
          assigned_sim: res.data.length > 0
            ? res.data[0]._id : '',
        }));
      });
  };
  useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  useEffect(() => {
    if (products.length > 0) {
      getSimsOfProduct(products[0]._id);
    }
  }, [products]);

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  const useStyles = makeStyles((theme) => AddAssignmentSyle(theme));
  const classes = useStyles();
  const [isGroup, setIsGroup] = useState(false);

  if ((products.length > 0 && state.tool === '')
   || (sims.length > 0 && state.assigned_sim === '')
   || (users.length > 0 && state.user_id === '')) {
    setState({
      ...state,
      tool: products.length > 0 ? products[0]._id : '',
      assigned_sim: sims.length > 0 ? sims[0]._id : '',
      user_id: users.length > 0 ? users[0]._id : '',
      group_id: groups.length > 0 ? groups[0]._id : '',
    });
  }
  useEffect(() => {
    setState({
      ...state,
      group_id: groups.length > 0 ? groups[0]._id : '',
    });
    // eslint-disable-next-line
  }, [groups]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === 'tool') {
      getSimsOfProduct(event.target.value);
    }
  };
  const handleNext = async () => {
    if (isGroup) {
      state.user_id = '';
      setState(state);
    } else {
      state.group_id = '';
      setState(state);
    }
    setAssignClick(true);
    await addAssignment(state);
    handleClose(false);
  };
  const groupChange = () => {
    if (isGroup === false) {
      setIsGroup(true);
      fetchGroupList();
    } else {
      setIsGroup(false);
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => handleClose(false)}
        aria-labelledby="details-screen"
        open={open}
        fullWidth
        className={classes.root}
      >
        <div className={classes.modalBody}>
          <DialogTitle id="details-screen" className={classes.title}>
            <br />
            Add New Assignment
          </DialogTitle>
          <InputLabel className={classes.labelFont}>
            (Fill the following details in the order shown below)
          </InputLabel>
          <Grid container className={classes.container}>
            <Grid item xs={12} lg={12}>
              <InputLabel className={classes.label}>1. Select Tool</InputLabel>
              <Select
                variant="outlined"
                name="tool"
                size="small"
                value={state.tool}
                onChange={handleChange}
                className={classes.select}
                native
              >
                {products && products.map(
                  (product) => (
                    <option key={product._id} value={product._id}>
                      {product.name}
                    </option>
                  ),
                )}
              </Select>
            </Grid>
            <Grid item xs={12} lg={12}>
              <InputLabel className={classes.label}>2. Select Assignment</InputLabel>
              <Select
                variant="outlined"
                name="assigned_sim"
                size="small"
                value={state.assigned_sim}
                onChange={handleChange}
                className={classes.select}
                native
              >
                {sims && sims.map(
                  (sim) => (
                    <Tooltip key={sim._id} title={sim.title.length === 0 ? 'None' : sim.title}>
                      <option key={sim._id} value={sim._id}>
                        {sim.title.length > 50 ? `${sim.title.slice(0, 50)}  ...`
                          : sim.title}
                      </option>
                    </Tooltip>
                  ),
                )}
              </Select>
            </Grid>
            {
              isGroup ? (

                <Grid item xs={12} lg={12}>
                  <InputLabel className={classes.label}>
                    3. Select a Group
                    <span onClick={groupChange} className={classes.link}>
                      (Or assign to a member)
                    </span>
                  </InputLabel>
                  <Select
                    variant="outlined"
                    name="group_id"
                    size="small"
                    value={state.member}
                    onChange={handleChange}
                    className={classes.select}
                    native
                  >
                    {groups && groups.map(
                      (group) => (
                        <option key={group._id} value={group._id}>
                          {group.name}
                        </option>
                      ),
                    )}
                  </Select>
                </Grid>

              ) : (
                <Grid item xs={12} lg={12}>
                  <InputLabel className={classes.label}>
                    3. Select member
                    <span onClick={groupChange} className={classes.link}>
                      (Or assign to a group)
                    </span>
                  </InputLabel>
                  <Select
                    variant="outlined"
                    name="user_id"
                    size="small"
                    value={state.member}
                    onChange={handleChange}
                    className={classes.select}
                    native
                  >
                    {users && users.map(
                      (user) => (
                        <option key={user._id} value={user._id}>
                          {`${user.first_name} ${user.last_name}`}
                        </option>
                      ),
                    )}
                  </Select>
                </Grid>

              )
            }

            {/* <Grid item xs={12} lg={12}>
              <InputLabel className={classes.label}>
                4.Include Limit?
                <span className={classes.link}> (Limits explained)</span>
              </InputLabel>
              <TextField
                variant="filled"
                name="limit"
                label="Limit"
                size="small"
                type="number"
                className={classes.text}
                onChange={handleChange}
              />
            </Grid> */}
            <Grid item xs={12} lg={12}>
              <InputLabel className={classes.label}>
                4. Assignment Instruction
              </InputLabel>
              <TextField
                fullWidth
                name="assignment_notes"
                multiline
                rows={4}
                InputProps={{
                  disableUnderline: true,
                }}
                className={classes.notes}
                placeholder="Type notes here"
                variant="filled"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                variant="outlined"
                className={classes.cancel}
                disableElevation
                color="primary"
                onClick={() => handleClose(false)}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                variant="contained"
                color="primary"
                className={classes.assignButton}
                disableElevation
                onClick={handleNext}
                disabled={!(state.tool && state.assigned_sim
                  && state.user_id) || assignClick}
                // disabled={!(state.tool && state.assigned_sim
                //     && state.user_id && state.limit)}
              >
                ASSIGN
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

AddAssignment.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  addAssignment: PropTypes.func,
  fetchProductList: PropTypes.func,
  fetchUserList: PropTypes.func,
  fetchGroupList: PropTypes.func,
  products: PropTypes.array,
  users: PropTypes.array,
  groups: PropTypes.array,
};

const mapStateToProps = (state) => ({
  newAssignment: state.sim.newAssignment,
  products: state.sim.products ? state.sim.products : [],
  users: state.sim.users ? state.sim.users : [],
  groups: state.sim.groups ? state.sim.groups : [],
});

const mapDispatchToProps = {
  addAssignment, fetchProductList, fetchUserList, fetchGroupList,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAssignment);
