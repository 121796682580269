/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import {
  Divider,
  Toolbar,
  Icon,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Link,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useToolbarStyles } from './Createlevels';
import { downloadTemplate } from '../../../services/result.service';
import StyledLink from '../../common/StyledLink';
import Download from '../../../assets/images/icons/Download.png';
import Print from '../../../assets/images/icons/Print.png';

const TopTableToolbar = ({
  decreaseStep,
  data,
  increaseStep,
  addLevels,
  uploadLevels,
  newSim,
  setFlag,
  status,
  importLevel,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedFileName, setSelectedFileName] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState('');
  const classes = useToolbarStyles();
  const [excelStatus, setExcelStatus] = React.useState(status);
  const handleNext = async () => {
    console.log('Data = %o', data);

    await addLevels(data);
    increaseStep();
  };

  const handlePreview = async () => {
    await addLevels(data);
    localStorage.setItem('sim_id', data.id);
    const simdata = JSON.stringify({ sim_id: data.id });
    localStorage.setItem('state', simdata);
  };
  useEffect(() => {
    if (status === '400') {
      setExcelStatus(status);
    }
  }, [status]);
  useEffect(() => {
    if (excelStatus === '400' && selectedFileName !== '') {
      alert('More than 5 levels are not allowed for a sim.');
    }
  }, [selectedFileName, excelStatus]);

  const HandleExcelUpload = () => {
    setExcelStatus('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fileSelected = (event) => {
    setSelectedFileName(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    importLevel();
    const formData = new FormData();
    formData.append('file', selectedFile);
    setOpen(false);
    await uploadLevels(formData, newSim.id, data.levels.length,
      data.levels[data.levels.length - 1].scripts.length
        ? data.levels[data.levels.length - 1].order + 1 : 0);
    setFlag(true);
    importLevel();
    handleClose();
  };

  const templateDownload = () => {
    downloadTemplate().then((response) => {
      const fileName = 'Excel Template';
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });
  };
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Create Levels
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.uploadDialog} id="form-dialog-title">
          Upload Excel
          <Link
            variant="subtitle1"
            underline="always"
            className={classes.templateLink}
            onClick={templateDownload}
          >
            Download Template
          </Link>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the file with xlsx format only
          </DialogContentText>
          <Button color="primary" component="label">
            Browse
            {' '}
            <input
              hidden
              type="file"
              accept=".xlsx, .csv"
              onChange={fileSelected}
            />
          </Button>
          {selectedFileName || data.sheet_name}
          <Divider variant="fullWidth" className={classes.excelDivider} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <StyledLink
        to="/sims"
        target="_blank"
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.previewBtn}
          disableElevation
          size="small"
          onClick={handlePreview}
          disabled={
            data && (!data.keywords || !data.keywords.length)
          }
        >
          PREVIEW
        </Button>
      </StyledLink>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Tooltip title="Import">
        <IconButton className={classes.icon} onClick={HandleExcelUpload}>
          <Icon>
            <img src={Download} height={20} width={20} alt="download" />
          </Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title="Print">
        <IconButton className={classes.printIcon}>
          <Icon>
            <img src={Print} height={20} width={20} alt="print" />
          </Icon>
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Button
        variant="outlined"
        color="primary"
        className={classes.previousBtn}
        disableElevation
        onClick={() => decreaseStep()}
      >
        <ArrowBackIcon />
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.nextBtn}
        disableElevation
        size="small"
        onClick={handleNext}
      >
        NEXT
      </Button>
      <Divider />
    </Toolbar>
  );
};
TopTableToolbar.propTypes = {
  decreaseStep: PropTypes.func,
  data: PropTypes.object,
  increaseStep: PropTypes.func,
  addLevels: PropTypes.func,
  uploadLevels: PropTypes.func,
  newSim: PropTypes.object,
  setFlag: PropTypes.func,
  importLevel: PropTypes.func,
  status: PropTypes.any,
};
export default TopTableToolbar;
