export const simWizard = (theme) => ({
  mainDiv: {
    width: '100%',
  },
  characterDiv: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5vw 0vw',
  },
  root: {
    float: 'right',
  },
  mobileRoot: {
    display: 'grid',
    justifyContent: 'space-evenly',
  },
  mobileStepper: {
    justifyContent: 'space-evenly',
  },
  centerElement: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  levelButton: {
    margin: '20px 25px 18px !important',
    backgroundColor: '#3061b9',
    color: '#fff',
    width: 130,
    height: 35,
    borderRadius: 15,
    // maxWidth: '3vw',
    fontSize: '0.8vw',
    fontWeight: 600,
  },
  restartButton: {
    margin: '1vw 0.5vw 1vw 0',
    backgroundColor: '#D8FAE3',
    color: '#37A559',
    minWidth: '6vw',
    maxWidth: '5vw',
    fontSize: '0.8vw',
    fontWeight: 600,
  },
  exitIcon: {
    width: 15,
    height: 15,
    marginRight: -5,
  },
  restartIcon: {
    width: 11,
    height: 11,
    marginRight: -3,
  },
  exitButton: {
    margin: '1vw 0vw',
    backgroundColor: '#FFE7E7',
    color: '#99455F',
    minWidth: '2vw',
    maxWidth: '5vw',
    fontSize: '0.8vw',
    fontWeight: 600,
  },
  avatarDiv: {
    width: '12%',
    margin: '0px auto',
    backgroundColor: '#fff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '1vw',
  },
  avatar: {
    '& .MuiAvatar-img': {
      marginBottom: 7,
    },
    width: '100%',
    height: '100%',
  },
  characterName: {
    backgroundColor: '#3061b9',
    WebkitTextFillColor: '#FFFFFF',
    flexDirection: 'row',
    flexWrap: 'wrap',
    textTransform: 'capitalize',
    borderRadius: 20,
    fontSize: 12,
  },
  dots: {
    width: '100%',
    justifyContent: 'space-evenly',
  },
  stepperRoot: {
    padding: '0px',
    justifyContent: 'space-evenly',
    backgroundColor: 'white',
  },
  dot: {
    width: '3vw',
    height: '0.5vw',
    borderRadius: '28%',
    color: '##8B888B',
    margin: '0px 5px',
  },
  dotActive: {
    backgroundColor: '#882647',
  },
  characterTypo: {
    display: 'contents',
    fontSize: '0.8vw',
    cursor: 'pointer',
  },
  radioTypo: {
    color: 'black',
    display: 'contents',
    fontSize: '0.8vw',
  },
  characterSpan: {
    color: '#7484a1',
    fontSize: '0.8vw',
    marginTop: 'auto',
    cursor: 'pointer',
  },
  rightTriangle: {
    width: '`10px',
    height: '10px',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid white',
    position: 'absolute',
    top: '25px',
    right: '-10px',
    zIndex: '1000',
  },
  instructionDiv: {
    minHeight: '75%',
    width: 600,
    marginBlockEnd: '8.5vw',
    display: 'grid',
    justifyContent: 'center',
    backgroundColor: '#f0f5ff',
  },
  instructionTypo: {
    padding: '1vw 1.5vw',
    backgroundColor: '#f0f5ff',
    fontSize: 15,
    flexWrap: 'wrap',
    borderRadius: 10,
    width: 600,
    minHeight: 150,
  },
  mainInfoDiv: {
    marginBottom: '15px',
    justifyContent: 'end',
  },
  hintText: {
    color: '#999999',
  },
  nextBackDiv: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
    width: '10%',
  },
  nextBackAutoplayDiv: {
    margin: '0vw 9.5vw',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
  },
  backButton: {
    backgroundColor: '#F0F0F0',
    color: '#A8A8A8',
    fontSize: '12px',
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    minWidth: 65,
  },
  activeBackButton: {
    backgroundColor: '#F0F0F0',
    color: '#892848',
    fontSize: '12px',
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    minWidth: 65,
  },
  pauseButton: {
    '& hover': {
      backgroundColor: '#882647',
    },
    backgroundColor: '#882647',
    borderRadius: '50%',
    width: '70%',
    color: '#FFFFFF',
    height: '2vw',
    margin: '0.5vw',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
      height: '5vw',
    },
  },
  pauseAutoplayButton: {
    '& hover': {
      backgroundColor: '#882647',
    },
    backgroundColor: '#882647',
    borderRadius: '6%',
    color: '#FFFFFF',
    height: '2vw',
    marginLeft: 60,
    marginTop: 10,
    marginBottom: 10,
  },
  recButton: {
    backgroundColor: '#fc031c',
    borderRadius: '6%',
    color: '#FFFFFF',
    height: '2vw',
    margin: '0.5vw',
  },
  nextButton: {
    backgroundColor: '#F0F0F0',
    color: '#892848',
    fontSize: '12px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    minWidth: 65,
  },
  nextButtonWithAnimation: {
    backgroundColor: '#F0F0F0',
    color: '#892848',
    fontSize: '12px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    minWidth: 65,
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: 'sans-serif',
    padding: '5px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    animation: '$glowing 1300ms infinite',
  },
  '@keyframes glowing': {
    '0%': {
      backgroundColor: '#f9f0f3',
      boxShadow: '0px 0px 5px #f9f0f3',
    },
    '50%': {
      backgroundColor: '#ffeaf1',
      boxShadow: '0px 0px 15px #ffeaf1',
    },
    '100%': {
      backgroundColor: '#f1c5d4',
      boxShadow: '0px 0px 5px #f1c5d4',
    },
  },
  restartDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  footerDiv: {
    margin: 10,
    width: '100%',
  },
  simAuthor: {
    width: '100%',
    fontSize: '11px',
    color: '#818181',
  },
  databaseDiv: {
    display: 'flex',
    margin: '0vw 0.5vw 0vw 0.5vw',
    padding: '0 0.5vw 2vw 0.5vw',
    backgroundColor: '#F3F3F3',
  },
  databaseTypo: {
    fontSize: '11px',
    flexGrow: 1,
    color: '#818181',
    marginLeft: '0.5vw',
  },
  databaseSpan: {
    fontSize: '11px',
    marginTop: 'auto',
    color: 'black',
    fontWeight: 500,
  },
  practiceTypo: {
    fontSize: '11px',
    color: '#818181',
    marginRight: '0.5vw',
    float: 'right',
  },
  practiceSpan: {
    fontSize: '11px',
    marginTop: 'auto',
    color: 'black',
    fontWeight: 500,
  },
  characterInfo: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '-0.7vw',
  },
  modal: {
    '& .MuiDialog-paperFullWidth': {
      marginLeft: 'auto',
    },
  },
  label: {
    textAlign: 'center',
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
  },
  missedKeywords: {
    fontSize: 15,
    fontWeight: 600,
    color: 'red',
  },
  correctKeywords: {
    fontSize: 15,
    fontWeight: 600,
    color: 'green',
  },
  list: {
    color: 'black',
    listStyleType: 'none'
  },
  player: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hr: {
    width: 'inherit',
  },
  modalContent: {
    textAlign: 'center',
  },
  playerPlayBtn: {
    color: 'rgb(136,38,71)',
    padding: 3,
  },
  loader: {
    width: '100%',
  },
  radioBtnDiv: {
    display: 'contents',
    '& .MuiFormControlLabel-root': {
      marginRight: 10,
    },
  },
  radioYes: {
    color: '#4185EA !important',
    marginRight: -7,
    marginInlineStart: '10px',
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: 15,
    },
  },
  radioNo: {
    color: '#4185EA !important',
    marginRight: -7,
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: 15,
    },
  },
  radioBtn: {
    '& .MuiTypography-body1': {
      fontSize: 15,
    },
  },
  radioDiv: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5vw 0vw',
  },
  warnTypo: {
    fontWeight: [700],
    margin: '10px 0px',
  },
  info: {
    marginBottom: '-5px',
    height: '24px',
    width: '28px',
    color: 'orange',
  },
  sfDiv: {
    textAlign: 'center',
    paddingBottom: 13,
  },
  menu: {
    marginTop: 40,
    '& .MuiList-padding': {
      paddingTop: 0,
    },
  },
  menuDiv: {
    display: 'grid',
  },
  paper: {
    borderRadius: 5,
    boxShadow: 'none',
    height: '100%',
  },
  levelDiv: {
    margin: '25px 25px 0px',
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      margin: '30px 35px 0px',
    },
  },
  lvlName: {
    fontWeight: 600,
  },
  mobileProgress: {
    '& .MuiMobileStepper-progress': {
      width: '91%',
      backgroundColor: '#d8d8d8',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#3061b9',
    },
    '& .MuiMobileStepper-dotActive': {
      backgroundColor: '#3061b9',
    },
  },
  activeClip: {
    fontSize: 15,
    padding: 5,
  },
  totalClip: {
    fontSize: 15,
    fontWeight: 300,
  },
  scriptGrid: {
    display: 'grid',
    justifyContent: 'center',
  },
  arrowBack: {
    fontSize: 20,
    marginLeft: 20,
  },
  arrowForward: {
    fontSize: 20,
    marginRight: 20,
  },

  scriptTag: {
    position: 'relative',
    width: '22em',
    background: '#f0f5ff',
    // looks
    padding: '1.125em 1.5em',
    fontSize: 15,
    borderRadius: 8,
    minHeight: '8em',
    '&:before': {
      // layout
      left: '2.5em',
      width: 0,
      border: 'solid transparent',
      borderRightWidth: 0,
      borderBottomWidth: 20,
      borderLeftWidth: 30,
      bottom: '98%',
      content: 'close-quote',
      position: 'absolute',
      height: 0,
      borderTop: 'none',
      borderBottomColor: '#f0f5ff',
      filter: 'drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1))',
    },
    [theme.breakpoints.up('md')]: {
      width: '35em',
    },
    [theme.breakpoints.up('lg')]: {
      width: '22em',
    },
    [theme.breakpoints.down('sm')]: {
      width: '20em',
    },

  },
  footerContainer: {
    padding: '10px 25px',
  },
  divider: {
    height: 2,
    width: '100%',
  },
  menuItem: {
    background: '#FFF1F6',
    '&.Mui-selected': {
      backgroundColor: '#f1c5d4',
      fontWeight: 600,
    },
  },
  takesimGrid: {
    position: 'relative',
  },
});

export const simWizardWithInternalData = (theme) => ({
  mainDiv: {
    width: '100%',
  },
  characterDiv: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5vw 0vw',
  },
  root: {
    float: 'right',
  },
  mobileRoot: {
    display: 'grid',
    justifyContent: 'space-evenly',
  },
  mobileStepper: {
    justifyContent: 'space-evenly',
  },
  centerElement: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  levelButton: {
    margin: '20px 25px 18px !important',
    backgroundColor: '#3061b9',
    color: '#fff',
    width: 130,
    height: 35,
    borderRadius: 15,
    // maxWidth: '3vw',
    fontSize: '0.8vw',
    fontWeight: 600,
  },
  restartButton: {
    margin: '1vw 0.5vw 1vw 0',
    backgroundColor: '#D8FAE3',
    color: '#37A559',
    minWidth: '6vw',
    maxWidth: '5vw',
    fontSize: '0.8vw',
    fontWeight: 600,
  },
  exitIcon: {
    width: 15,
    height: 15,
    marginRight: -5,
  },
  restartIcon: {
    width: 11,
    height: 11,
    marginRight: -3,
  },
  exitButton: {
    margin: '1vw 0vw',
    backgroundColor: '#FFE7E7',
    color: '#99455F',
    minWidth: '2vw',
    maxWidth: '5vw',
    fontSize: '0.8vw',
    fontWeight: 600,
  },
  avatarDiv: {
    width: '12%',
    margin: '0px auto',
    backgroundColor: '#fff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '1vw',
  },
  avatar: {
    '& .MuiAvatar-img': {
      marginBottom: 7,
    },
    width: '100%',
    height: '100%',
  },
  characterName: {
    backgroundColor: '#3061b9',
    WebkitTextFillColor: '#FFFFFF',
    flexDirection: 'row',
    flexWrap: 'wrap',
    textTransform: 'capitalize',
    borderRadius: 20,
    fontSize: 12,
  },
  dots: {
    width: '100%',
    justifyContent: 'space-evenly',
  },
  stepperRoot: {
    padding: '0px',
    justifyContent: 'space-evenly',
    backgroundColor: 'white',
  },
  dot: {
    width: '3vw',
    height: '0.5vw',
    borderRadius: '28%',
    color: '##8B888B',
    margin: '0px 5px',
  },
  dotActive: {
    backgroundColor: '#882647',
  },
  characterTypo: {
    display: 'contents',
    fontSize: '0.8vw',
    cursor: 'pointer',
  },
  radioTypo: {
    color: 'black',
    display: 'contents',
    fontSize: '0.8vw',
  },
  characterSpan: {
    color: '#7484a1',
    fontSize: '0.8vw',
    marginTop: 'auto',
    cursor: 'pointer',
  },
  rightTriangle: {
    width: '`10px',
    height: '10px',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid white',
    position: 'absolute',
    top: '25px',
    right: '-10px',
    zIndex: '1000',
  },
  instructionDiv: {
    minHeight: '75%',
    width: 600,
    marginBlockEnd: '8.5vw',
    display: 'grid',
    justifyContent: 'center',
    backgroundColor: '#f0f5ff',
  },
  instructionTypo: {
    padding: '1vw 1.5vw',
    backgroundColor: '#f0f5ff',
    fontSize: 15,
    flexWrap: 'wrap',
    borderRadius: 10,
    width: 600,
    minHeight: 150,
  },
  mainInfoDiv: {
    marginBottom: '15px',
    justifyContent: 'end',
  },
  hintText: {
    color: '#999999',
  },
  nextBackDiv: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
    width: '10%',
  },
  nextBackAutoplayDiv: {
    margin: '0vw 9.5vw',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
  },
  backButton: {
    backgroundColor: '#F0F0F0',
    color: '#A8A8A8',
    fontSize: '12px',
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    minWidth: 65,
  },
  activeBackButton: {
    backgroundColor: '#F0F0F0',
    color: '#892848',
    fontSize: '12px',
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    minWidth: 65,
  },
  pauseButton: {
    '& hover': {
      backgroundColor: '#882647',
    },
    backgroundColor: '#882647',
    borderRadius: '50%',
    width: '70%',
    color: '#FFFFFF',
    height: '2vw',
    margin: '0.5vw',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
      height: '5vw',
    },
  },
  pauseAutoplayButton: {
    '& hover': {
      backgroundColor: '#882647',
    },
    backgroundColor: '#882647',
    borderRadius: '6%',
    color: '#FFFFFF',
    height: '2vw',
    marginLeft: 60,
    marginTop: 10,
    marginBottom: 10,
  },
  recButton: {
    backgroundColor: '#fc031c',
    borderRadius: '6%',
    color: '#FFFFFF',
    height: '2vw',
    margin: '0.5vw',
  },
  nextButton: {
    backgroundColor: '#F0F0F0',
    color: '#892848',
    fontSize: '12px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    minWidth: 65,
  },
  nextButtonWithAnimation: {
    backgroundColor: '#F0F0F0',
    color: '#892848',
    fontSize: '12px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    minWidth: 65,
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: 'sans-serif',
    padding: '5px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    animation: '$glowing 1300ms infinite',
  },
  '@keyframes glowing': {
    '0%': {
      backgroundColor: '#f9f0f3',
      boxShadow: '0px 0px 5px #f9f0f3',
    },
    '50%': {
      backgroundColor: '#ffeaf1',
      boxShadow: '0px 0px 15px #ffeaf1',
    },
    '100%': {
      backgroundColor: '#f1c5d4',
      boxShadow: '0px 0px 5px #f1c5d4',
    },
  },
  restartDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  footerDiv: {
    margin: 10,
    width: '100%',
  },
  simAuthor: {
    width: '100%',
    fontSize: '11px',
    color: '#818181',
  },
  databaseDiv: {
    display: 'flex',
    margin: '0vw 0.5vw 0vw 0.5vw',
    padding: '0 0.5vw 2vw 0.5vw',
    backgroundColor: '#F3F3F3',
  },
  databaseTypo: {
    fontSize: '11px',
    flexGrow: 1,
    color: '#818181',
    marginLeft: '0.5vw',
  },
  databaseSpan: {
    fontSize: '11px',
    marginTop: 'auto',
    color: 'black',
    fontWeight: 500,
  },
  practiceTypo: {
    fontSize: '11px',
    color: '#818181',
    marginRight: '0.5vw',
    float: 'right',
  },
  practiceSpan: {
    fontSize: '11px',
    marginTop: 'auto',
    color: 'black',
    fontWeight: 500,
  },
  characterInfo: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '-0.7vw',
  },
  modal: {
    '& .MuiDialog-paperFullWidth': {
      marginLeft: 'auto',
    },
  },
  label: {
    textAlign: 'center',
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
  },
  missedKeywords: {
    fontSize: 15,
    fontWeight: 600,
    color: 'red',
  },
  correctKeywords: {
    fontSize: 15,
    fontWeight: 600,
    color: 'green',
  },
  list: {
    color: 'black',
  },
  player: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hr: {
    width: 'inherit',
  },
  modalContent: {
    textAlign: 'center',
  },
  playerPlayBtn: {
    color: 'rgb(136,38,71)',
    padding: 3,
  },
  loader: {
    width: '100%',
  },
  radioBtnDiv: {
    display: 'contents',
    '& .MuiFormControlLabel-root': {
      marginRight: 10,
    },
  },
  radioYes: {
    color: '#4185EA !important',
    marginRight: -7,
    marginInlineStart: '10px',
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: 15,
    },
  },
  radioNo: {
    color: '#4185EA !important',
    marginRight: -7,
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: 15,
    },
  },
  radioBtn: {
    '& .MuiTypography-body1': {
      fontSize: 15,
    },
  },
  radioDiv: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5vw 0vw',
  },
  warnTypo: {
    fontWeight: [700],
    margin: '10px 0px',
  },
  info: {
    marginBottom: '-5px',
    height: '24px',
    width: '28px',
    color: 'orange',
  },
  sfDiv: {
    textAlign: 'center',
    paddingBottom: 13,
  },
  menu: {
    marginTop: 40,
    '& .MuiList-padding': {
      paddingTop: 0,
    },
  },
  menuDiv: {
    display: 'grid',
  },
  paper: {
    borderRadius: 5,
    boxShadow: 'none',
    height: '100%',
  },
  levelDiv: {
    margin: '25px 25px 0px',
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      margin: '30px 35px 0px',
    },
  },
  lvlName: {
    fontWeight: 600,
  },
  mobileProgress: {
    '& .MuiMobileStepper-progress': {
      width: '91%',
      backgroundColor: '#d8d8d8',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#3061b9',
    },
    '& .MuiMobileStepper-dotActive': {
      backgroundColor: '#3061b9',
    },
  },
  activeClip: {
    fontSize: 15,
    padding: 5,
  },
  totalClip: {
    fontSize: 15,
    fontWeight: 300,
  },
  scriptGrid: {
    display: 'grid',
    justifyContent: 'center',
  },
  arrowBack: {
    fontSize: 20,
    marginLeft: 20,
  },
  arrowForward: {
    fontSize: 20,
    marginRight: 20,
  },

  scriptTag: {
    position: 'relative',
    width: '22em',
    background: '#f0f5ff',
    // looks
    padding: '1.125em 1.5em',
    fontSize: 15,
    borderRadius: 8,
    minHeight: '8em',
    '&:before': {
      // layout
      left: '2.5em',
      width: 0,
      border: 'solid transparent',
      borderRightWidth: 0,
      borderBottomWidth: 20,
      borderLeftWidth: 30,
      bottom: '98%',
      content: 'close-quote',
      position: 'absolute',
      height: 0,
      borderTop: 'none',
      borderBottomColor: '#f0f5ff',
      filter: 'drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1))',
    },
    [theme.breakpoints.up('md')]: {
      width: '35em',
    },
    [theme.breakpoints.up('lg')]: {
      width: '22em',
    },
    [theme.breakpoints.up('sm')]: {
      width: '20em',
    },

  },
  footerContainer: {
    padding: '10px 25px',
  },
  divider: {
    height: 2,
    width: '100%',
  },
  menuItem: {
    background: '#FFF1F6',
    '&.Mui-selected': {
      backgroundColor: '#f1c5d4',
      fontWeight: 600,
    },
  },
  takesimGrid: {
    position: 'relative',
  },
});

export const simWizardActiveLevelButton = {
  margin: '1rem 0rem',
  backgroundColor: '#3061b9',
  color: '#fff',
  // minWidth: '3.5vw',
  // maxWidth: '3vw',
};
export const simWizardActiveLevel = {
  fontSize: '11px',
  flexGrow: 1,
  color: '#818181',
  marginLeft: '0.5vw',
};
export const simWizardHideLevelButton = {
  display: 'none',
};
