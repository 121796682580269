import { makeStyles } from '@material-ui/core';
import WhiteDottedBackground from '../../../assets/images/takeSim/whitedotted.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // borderRadius: "1.5%",
    padding: theme.spacing(1, 2.5),
  },
  userDetails: {
    width: '100%',
    backgroundColor: '#264172',
    padding: theme.spacing(2, 3),
    borderRadius: '2%',
  },
  userImage: {
    display: 'inline-block',
    width: '4rem',
    height: '4rem',
    float: 'left',
    marginRight: '1.5rem',
    borderRadius: '8%',
  },
  name: {
    color: '#fff',
    fontSize: '1.5em',
  },
  details: {
    color: '#fff',
    fontSize: '0.8em',
  },
  divider: {
    backgroundColor: '#39527F',
    margin: theme.spacing(2, 0),
  },
  select: {
    backgroundColor: '#3D588A',
    paddingLeft: '1rem',
    color: '#fff',
    fontSize: '1em',
    '& .MuiSelect-icon': {
      color: '#fff',
    },
  },
  timeDiv: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1, 0),
    width: '100%',
    backgroundColor: '#162C55',
    display: 'flex',
    justifyContent: 'space-around',
  },
  circProg: {
    textAlign: 'center',
    backgroundImage: `url(${WhiteDottedBackground})`,
    backgroundSize: 'cover',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1, 0),
    width: '100%',
    backgroundColor: '#162C55',
    height: '13rem',
  },
  accuracyPaper: {
    width: '100%',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2.5),
  },
  accuracySection: {
    width: '100%',
    backgroundColor: '#F1F5FB',
    padding: '1.5rem',
    textAlign: 'center',
    lineHeight: '2.5rem',
    borderRadius: '2%',
    marginBottom: '1rem',
  },
  numbers: {
    width: '100%',
    fontWeight: [700],
  },
  numberColor: {
    color: '#989999',
    fontSize: '1.7em',
    marginTop: '1em',
  },
  blackColor: {
    color: 'black',
  },
  priorityIcon: {
    float: 'left',
    background: '#B5E8D1',
    color: '#037240',
    borderRadius: 5,
    width: 32,
    height: 32,
    margin: 14,
    padding: 3,
    marginBottom: '2rem',
  },
  agent: {
    borderLeft: '3px solid #7B95C5',
    backgroundColor: '#EEF3FC',
    width: '100%',
    borderRadius: '2%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  customer: {
    borderLeft: '3px solid #882647',
    backgroundColor: '#FCF1F5',
    width: '100%',
    borderRadius: '2%',
    marginBottom: theme.spacing(2),
  },
  patientData: {
    borderLeft: '3px solid #882647',
    backgroundColor: '#F6F6F6',
    width: '100%',
    borderRadius: '2%',
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1.5),
  },
  scriptTypo: {
    paddingTop: theme.spacing(1.5),
  },
  bold: {
    fontWeight: [700],
  },
  script: {
    fontSize: '0.8em',
  },
  highlight: {
    backgroundColor: '#ADBBD4',
  },
  checkCircleIcon: {
    color: '#2CB174',
    height: 15,
    marginBottom: -5,
  },
  checkCircle: {
    float: 'right',
    color: '#2CB174',
    fontSize: '0.7em',
  },
  circularProg: {
    '& .MuiCircularProgress-svg': {
      color: '#09C68F',
    },
  },
  prog: {
    marginTop: '1.5rem',
  },
  progTypo: {
    color: 'white',
    fontSize: '1rem',
  },
  keyTypo: {
    color: 'white',
    fontSize: '1rem',
  },
  scriptStyle: {
    fontSize: '.9rem',
  },
  scriptGrid: {
    padding: 8,
  },
  index: {
    color: '#949393',
    width: '6%',
    fontSize: 25,
    background: '#dddddd',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
