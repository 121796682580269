/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f1f1f1',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.9em',
    },
  },
  flexItem1: {
    width: '40%',
    borderRight: '1px solid lightgrey',
    [theme.breakpoints.down('lg')]: {
      width: '40%',
    },
    padding: theme.spacing(2),
  },
  flexItem2: {
    width: '35%',
  },
  flexItem3: {
    alignSelf: 'center',
    justifySelf: 'center',
    padding: theme.spacing(0, 2),
  },
  status: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
    padding: theme.spacing(2),
  },
  startBtn: {
    fontSize: '0.8em',
    alignSelf: 'center',
    padding: theme.spacing(1, 4, 1, 4),
    fontWeight: 'bold',
  },
  practiceBtn: {
    fontSize: '0.8em',
    marginBottom: theme.spacing(1),
    backgroundColor: '#eedee3',
    '& .MuiButton-label': {
      color: '#882647',
      fontWeight: 'bold',
    },
  },
  autoplayBtn: {
    fontSize: '0.8em',
    backgroundColor: '#dde7fa',
    '& .MuiButton-label': {
      color: '#264172',
      fontWeight: 'bold',
    },
  },
  textBold: {
    fontWeight: 'bold',
  },
  radio: {
    color: theme.palette.grey[300],
    backgroundColor: 'white',
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    marginRight: '10px',
  },
  flex2item1: {
    alignSelf: 'flex-start',
  },

  popper: {
    zIndex: 9000,
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(0.3),
  },
  popperPaper: {
    padding: theme.spacing(2),
  },
  rightTriangle: {
    width: '`10px',
    height: '10px',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid white',
    position: 'absolute',
    top: '25px',
    right: '-10px',
    zIndex: '1000',
  },
  paper: {
    position: 'absolute',
    top: '40%',
    right: '120px',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  chckBoxFilled: {
    color: '#5bbb5f !important',
  },
  checkBox: {
    '& .MuiSvgIcon-root': {
      width: 20,
    },
  },
  levelDiv: {
    marginLeft: 38,
  },
}));
