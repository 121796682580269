import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import { saveElapsedTime } from '../../redux/sims/actions';

const Timer = ({ saveElapsedTime }) => {
  useEffect(() => {
    const startTime = new Moment();
    return () => {
      const endTime = new Moment();
      const duration = Moment.duration(endTime.diff(startTime));
      saveElapsedTime(duration.get('hour') > 0 ? `${duration.get('hour')}h ${duration.get('minutes')}m` : `${duration.get('minutes')}m ${duration.get('seconds')}s`);
    };
  }, [saveElapsedTime]);
  return (
    <div>
      {}
    </div>
  );
};

Timer.propTypes = {
  saveElapsedTime: PropTypes.func,
};

const mapDispatchToProps = {
  saveElapsedTime,
};
export default connect(null, mapDispatchToProps)(Timer);
