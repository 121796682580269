import React from 'react';
import { useStyles } from "./TakeSimResult";
import {
    Grid,
    Paper,
    Divider,
} from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { connect } from "react-redux";

const TypingAndSelection = ({
    userResult,
}) => {
    const classes = useStyles();
    const numberList = "0123456789";
    const checkNumber = (str) => {
        let x = str.split("").every((ele) => numberList.includes(ele));
        return x && str.length > 9;
    };
    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = match[1] ? "+1 " : "";
          return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
        }
        return null;
    };
    return(
        <Grid container className={classes.girdContainer} item md={4}>
        <Paper style={{ width: "100%", borderRadius: "1%" }}>
          <div className={classes.root}>
            <h2>
              {userResult?.scripts?.length &&
                userResult?.scripts[0]?.sim_id?.title}
            </h2>
          </div>
          <Divider variant="fullWidth" />
          <div className={classes.root}>
            <h2 style={{ marginBottom: "0.7rem", fontSize: '1.2rem' }}>Typing</h2>
            {userResult.typing_inputs?.map((ele, idx) => (
              <div
                key={idx}
                className={
                  ele.input_value === ele.target_value
                    ? classes.patientDataMatched
                    : classes.patientData
                }
              >
                {ele.input_value === ele.target_value ? (
                    <>
                      <span>{ele.field_title}</span><br />
                      <div className={classes.flex}>
                        <CheckCircleRoundedIcon
                          className={classes.success}
                        ></CheckCircleRoundedIcon>{" "}
                        <span className={classes.bold}>
                          {ele.target_value.startsWith("<a ") ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: ele.target_value,
                              }}
                            ></span>
                          ) : checkNumber(ele.target_value) ? (
                            formatPhoneNumber(ele.target_value)
                          ) : (
                            ele.target_value
                          )}
                        </span>
                      </div>
                    </>
                ) : (
                  <>
                    <span>{ele.field_title}</span><br />
                    <div className={classes.flex}>
                      <CancelRoundedIcon
                        className={classes.failed}
                      ></CancelRoundedIcon>
                      <span className={classes.script}>
                        {ele.input_value}
                      </span>
                    </div>
                    <span
                      className={classes.bold}
                      style={{
                        marginTop: ".5rem",
                        display: "inline-block",
                        wordBreak: "break-all",
                      }}
                    >
                      {ele.target_value.startsWith("<a ") ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ele.target_value,
                          }}
                        ></span>
                      ) : checkNumber(ele.target_value) ? (
                        formatPhoneNumber(ele.target_value)
                      ) : (
                        ele.target_value
                      )}
                    </span>
                  </>
                )}
              </div>
            ))}
            <h4 style={{ marginBottom: "0.7rem", fontSize: '1.2rem' }}>Data Selection</h4>
            {userResult.data_inputs?.map((ele, i) => (
              <div
                key={i}
                className={
                  ele.input_value === ele.target_value
                    ? classes.patientDataMatched
                    : classes.patientData
                }
              >
                {ele.input_value === ele.target_value ? (
                  <>
                    <span>{ele.field_title}</span><br />
                    <div className={classes.flex}>
                      <CheckCircleRoundedIcon
                        className={classes.success}
                      ></CheckCircleRoundedIcon>
                      <span className={classes.bold}>{ele.target_value}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <span>{ele.field_title}</span><br />
                    <div className={classes.flex}>
                      <CancelRoundedIcon
                        className={classes.failed}
                      ></CancelRoundedIcon>{" "}
                      {ele.input_value}
                      <span className={classes.script}></span>
                    </div>
                    <span
                      className={classes.bold}
                      style={{ marginTop: ".5rem", display: "inline-block" }}
                    >
                      {ele.target_value}
                    </span>
                  </>
                )}
              </div>
            ))}
          </div>
        </Paper>
      </Grid>
    )
}
const mapStateToProps = (state) => ({
    userResponse: state.sim.userResponse,
    userResult: state.sim.userResult,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TypingAndSelection);
