/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Grid,
  InputLabel,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Card,
  Typography,
  CardContent,
} from '@material-ui/core';
import moment from 'moment';
import TagInput from '../settings/TagInput';
import NotificationStyle from './Notification';
import {
  fetchUserList,
  fetchGroupList,
} from '../../redux/sims/actions';
import { fetchNotification, reset } from '../../redux/admin/action';
import { SaveNotification } from '../../services/result.service';

const Notification = ({
  notification,
  notificationClose,
  fetchGroupList,
  fetchUserList,
  groups,
  users,
  fetchNotification,
  notificationList,
  total,
  reset,
}) => {
  const useStyles = makeStyles((theme) => NotificationStyle(theme));
  const [isGroup, setIsGroup] = useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);
  const [messages, setMessages] = useState([]);
  const [state, setState] = useState({
    notification: '',
    groups: [],
    receipts: [],
  });
  const groupChange = () => {
    if (isGroup === false) {
      setIsGroup(true);
      fetchGroupList();
    } else {
      setIsGroup(false);
      fetchUserList();
    }
  };
  useEffect(() => {
    if (notificationList && notificationList.length) {
      setMessages((prev) => [...prev, ...notificationList]);
    }
  }, [notificationList]);
  useEffect(() => {
    if (notification) {
      fetchUserList();
    }
  }, [notification, fetchUserList]);
  useEffect(() => {
    fetchNotification(pageNumber);
  }, [fetchNotification, pageNumber]);
  const handleGroupChange = (value) => {
    if (isGroup) {
      setState({
        ...state,
        groups: value,
        receipts: [],
      });
    } else {
      setState({
        ...state,
        groups: [],
        receipts: value,
      });
    }
  };
  const handleChange = (evt) => {
    const { value } = evt.target;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };
  const addNotification = async () => {
    if (state.notification && (state.groups.length || state.receipts.length)) {
      setMessages([]);
      setPageNumber(0);
      const pageNum = 0;
      await SaveNotification(state);
      await reset();
      await fetchNotification(pageNum);
      setState({
        ...state,
        notification: '',
        receipts: [],
        groups: [],
      });
      setIsGroup(false);
      notificationClose();
    }
  };
  const handlePageChange = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };
  return (
    <div>
      <Dialog
        open={notification}
        onClose={notificationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">Add New Notification</DialogTitle>
        <DialogContent>
          <Grid container className={classes.container}>
            <Grid item xs={12} lg={12}>
              <InputLabel className={classes.label}>Enter Notification Message</InputLabel>
              <TextareaAutosize
                minRows={5}
                data-enable-grammarly="false"
                name="notification"
                onChange={handleChange}
                className={!state.notification ? classes.error : classes.textArea}
              />
            </Grid>
            {
              isGroup ? (
                <Grid item xs={12} lg={12}>
                  <InputLabel className={classes.label}>
                    3. Select Groups
                    {' '}
                    <span onClick={groupChange} className={classes.link}>
                      (Or assign to a member)
                    </span>
                  </InputLabel>
                </Grid>
              ) : (
                <Grid item xs={12} lg={12}>
                  <InputLabel className={classes.label}>
                    3. Select Members
                    {' '}
                    <span onClick={groupChange} className={classes.link}>
                      (Or assign to a group)
                    </span>
                  </InputLabel>
                </Grid>
              )
              }
            {
                isGroup
                  && (
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      className={!state.groups.length ? classes.error : ''}
                      style={{ padding: 0 }}
                    >
                      <TagInput
                        selectGroups={groups}
                        name="groups"
                        handleGroupChange={handleGroupChange}
                        width="380px"
                      />
                    </Grid>
                  )
              }
            {
                !isGroup
                && (
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    className={!state.receipts.length ? classes.error : ''}
                    style={{ padding: 0 }}
                  >
                    <TagInput
                      selectGroups={users}
                      name="receipts"
                      handleGroupChange={handleGroupChange}
                      width="380px"
                    />
                  </Grid>
                )
              }
            <Grid item xs={12} lg={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.notifyButton}
                onClick={addNotification}
              >
                SEND NOTIFICATION
              </Button>
              <Button
                variant="outlined"
                className={classes.cancel}
                disableElevation
                color="primary"
                onClick={notificationClose}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <InfiniteScroll
        dataLength={messages.length}
        next={handlePageChange}
        hasMore={messages.length < total}
        loader={<p>Loading...</p>}
        style={{
          height: 'none',
          overflow: 'none',
        }}
      >
        <Grid container spacing={2} style={{ marginTop: 12 }}>
          {
          messages.length > 0 && messages.map((notify) => (
            <Grid item xs={12} key={notify._id}>
              <Card className={classes.card}>
                <CardContent className={classes.content}>
                  <Typography className={classes.message}>
                    {notify.message}
                  </Typography>
                  <Grid container>
                    <Grid item xs={2} className={classes.subDetails}>
                      Date :
                      {' '}
                      {moment(notify.created_date).format('DD-MM-YYYY')}
                    </Grid>
                    <Grid item xs={2} className={classes.subDetails}>
                      Sender :
                      {' '}
                      { notify.created_by}
                    </Grid>
                    <Grid item xs={4} className={classes.subDetails}>
                      {notify.groups.length ? 'Receiver groups' : 'Recipient\'s Name'}
                      {' '}
                      :
                      {' '}
                      { notify.groups.length ? (notify.groups.toString()).replace(/,/g, ', ')
                        : (notify.recipients.toString()).replace(/,/g, ', ')}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
          }
        </Grid>
      </InfiniteScroll>
      {
        messages.length === 0 && <h3>No Notification's added in your list yet!</h3>
      }
    </div>
  );
};
Notification.propTypes = {
  notification: PropTypes.bool,
  notificationClose: PropTypes.func,
  fetchGroupList: PropTypes.func,
  fetchUserList: PropTypes.func,
  groups: PropTypes.array,
  users: PropTypes.array,
  fetchNotification: PropTypes.func,
  notificationList: PropTypes.array,
  total: PropTypes.number,
  reset: PropTypes.func,
};
const mapStateToProps = (state) => ({
  groups: state.sim.groups ? state.sim.groups : [],
  users: state.sim.users ? state.sim.users : [],
  notificationList: state.admin.notifications ? state.admin.notifications.notifList : [],
  total: state.admin.notifications ? state.admin.notifications.total : 0,
});

const mapDispatchToProps = {
  fetchGroupList,
  fetchUserList,
  fetchNotification,
  reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
