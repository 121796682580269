export const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';
export const FETCH_COMPANY_ROLES_REQUEST = 'FETCH_COMPANY_ROLES_REQUEST';
export const FETCH_COMPANY_ROLES_SUCCESS = 'FETCH_COMPANY_ROLES_SUCCESS';
export const FETCH_COMPANY_ROLES_FAIL = 'FETCH_COMPANY_ROLES_FAIL';
export const DELETE_COMPANY_ROLES_REQUEST = 'DELETE_COMPANY_ROLES_REQUEST';
export const DELETE_COMPANY_ROLES_SUCCESS = 'DELETE_COMPANY_ROLES_SUCCESS';
export const DELETE_COMPANY_ROLES_FAIL = 'DELETE_COMPANY_ROLES_FAIL';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const FETCH_STATUS = 'FETCH_STATUS';
export const FETCH_SUB_STATUS = 'FETCH_SUB_STATUS';
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_GROUPS = 'FETCH_GROUPs';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_GROUP = 'SAVE_GROUP';
export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const ADD_ROLES = 'ADD_ROLES';
export const EDIT_GROUP_IN_USER = 'EDIT_GROUP_IN_USER';
export const RESET = 'RESET';
export const SAVE_TOOLTIP = 'SAVE_TOOLTIP';
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
