/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Popover from '@material-ui/core/Popover';
import { Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DashboardStyle from '../../pages/dashboard/Dashboard';

const DateFilter = ({
  dateRange,
  handleClose,
  handleDateRange,
  filterDatas,
  filter,
  apply,
  cancelDate,
}) => {
  const useStyles = makeStyles((theme) => DashboardStyle(theme));
  const classes = useStyles();
  return (
    <Popover
      open={dateRange}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 160, left: 856 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div style={{ padding: '0.5rem', width: '600px' }}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={3} className={classes.centerLabel}>
                  Start Date
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id="date"
                    onChange={handleDateRange}
                    type="date"
                    name="startDate"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue={filterDatas.startDate ? filterDatas.startDate : ''}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} className={classes.centerLabel}>
                  End Date
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id="date"
                    onChange={handleDateRange}
                    type="date"
                    name="endDate"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue={filterDatas.endDate ? filterDatas.endDate : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} className={classes.borderLeft}>
              <span onClick={() => filter('today')} className={classes.filterSpan}>Today</span>
              <br />
              <span onClick={() => filter('yesterday')} className={classes.filterSpan}>Yesterday</span>
              <br />
              <span onClick={() => filter('last_7_days')} className={classes.filterSpan}>Last 7 days</span>
              <br />
              <span onClick={() => filter('last_week')} className={classes.filterSpan}>Last Week (Mo-Su)</span>
              <br />
              <span onClick={() => filter('month_to_date')} className={classes.filterSpan}>Month to Date</span>
              <br />
              <span onClick={() => filter('previous_month')} className={classes.filterSpan}>Previous Month</span>
              <br />
              <span onClick={() => filter('year_to_date')} className={classes.filterSpan}>Year to Date</span>
              <br />
            </Grid>
          </Grid>
        </div>

        <Button
          size="small"
          style={{
            fontWeight: 600,
            padding: '3px 11px',
            backgroundColor: '#F8DEE7',
            float: 'right',
            color: '#A55B73',
            margin: '8px 0px',
          }}
          onClick={apply}
        >
          Apply
        </Button>
        <Button
          onClick={cancelDate}
          size="small"
          style={{
            fontWeight: 600,
            padding: '2px 11px',
            float: 'right',
            color: '#A55B73',
            border: '1px solid',
            margin: '8px 5px',
          }}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );
};
DateFilter.propTypes = {
  dateRange: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDateRange: PropTypes.func,
  filterDatas: PropTypes.object,
  filter: PropTypes.func,
  apply: PropTypes.func,
  cancelDate: PropTypes.func,
};
export default DateFilter;
