import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import {
  AppBar,
  Tooltip,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Redirect } from 'react-router-dom';
import StyledLink from '../../components/common/StyledLink';
import EmployeeList from '../../components/admin/EmployeeList.jsx';
import Roles from '../../components/admin/Roles.jsx';
import Groups from '../../components/admin/Groups.jsx';
import { fetchEmployeeList, fetchGroupsList } from '../../redux/sims/actions';
import Permissions from '../../components/admin/Permissions.jsx';
import CreateUser from '../../components/admin/CreateUser.jsx';
import CreateGroup from '../../components/admin/CreateGroup.jsx';
import { savePermission } from '../../services/result.service';
import { fetchPermissionDetails } from '../../redux/authentication/action';
import { reset } from '../../redux/admin/action';
import AdminsPermissions from '../../permissions/admin';
import { permissionCheck } from '../../utils/permissionCheck';
import AdminStyle from './Admin';
import Notification from '../../components/admin/Notification.jsx';

const Admin = ({
  fetchEmployeeList, employeeList, employeeTotal,
  fetchGroupsList, groups, groupTotal, prop, fetchPermissionDetails, reset, userDetails,
}) => {
  const [value, setValue] = React.useState('0');
  const [enableCreateUser, setEnableCreateUser] = React.useState(false);
  const [empList, setEmpList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [textSearch, setTextSearch] = useState('');
  const [createNewGroup, setCreateNewGroup] = React.useState(false);
  const [groupList, setGroupList] = useState([]);
  const [flag, setFlag] = useState(true);
  const [text, setText] = useState('');
  const [refreshPermission, setRefreshPermission] = useState(false);
  const [save, setSave] = useState(false);
  const [permissionData, setPermissionData] = useState({});
  const [tst, setTst] = useState(false);
  const [saveTmpRole, setTmpRole] = useState(false);
  const [roles, setRoles] = useState([]);
  const [edit, setEdit] = useState(false);
  const [notification, setNotification] = useState(false);
  const [sortBy, setSortBy] = React.useState(null);
  const [order, setOrder] = React.useState(null);
  localStorage.setItem('sims_id', '');

  const editClick = () => {
    setEdit(!edit);
  };

  const handleTabs = (event, val) => {
    setRoles([]);
    reset();
    if (Object.keys(permissionData).length !== 0) {
      const confirm = window.confirm('Changes that you made may not be saved.');
      if (confirm) {
        setValue(val);
        setPermissionData({});
        setTst(false);
      }
    } else {
      setEmpList([]);
      setGroupList([]);
      setCreateNewGroup(false);
      setPageNumber(0);
      setFlag(true);
      setTextSearch('');
      setText('');
      setValue(val);
    }
  };
  useEffect(() => {
    reset();
  }, [reset, value]);
  const employeeListEnable = permissionCheck(
    AdminsPermissions.employee_list.view.task,
    AdminsPermissions.employee_list.view.type,
  );
  const groupListEnable = permissionCheck(
    AdminsPermissions.groups.view.task,
    AdminsPermissions.groups.view.type,
  );
  const permissionsListEnable = permissionCheck(
    AdminsPermissions.permissions.view.task,
    AdminsPermissions.permissions.view.type,
  );
  const roleListEnable = permissionCheck(
    AdminsPermissions.roles.view.task,
    AdminsPermissions.roles.view.type,
  );
  useEffect(() => {
    if (permissionCheck(
      AdminsPermissions.employee_list.view.task,
      AdminsPermissions.employee_list.view.type,
    )) {
      setValue('0');
    } else if (permissionCheck(
      AdminsPermissions.groups.view.task,
      AdminsPermissions.groups.view.type,
    )) {
      setValue('2');
    } else if (permissionCheck(
      AdminsPermissions.permissions.view.task,
      AdminsPermissions.permissions.view.type,
    )) {
      setValue('3');
    } else if (permissionCheck(
      AdminsPermissions.roles.view.task,
      AdminsPermissions.roles.view.type,
    )) {
      setValue('4');
    }
  }, [setValue, employeeListEnable, groupListEnable, roleListEnable, permissionsListEnable]);

  useEffect(() => {
    if (textSearch) {
      setPageNumber(0);
      if (value === '2') {
        fetchGroupsList(pageNumber, textSearch);
      } else {
        fetchEmployeeList(pageNumber, textSearch, sortBy, order);
      }
    } else if (value === '2') {
      fetchGroupsList(pageNumber);
    } else if (permissionCheck(
      AdminsPermissions.employee_list.view.task,
      AdminsPermissions.employee_list.view.type,
    )) {
      fetchEmployeeList(pageNumber, textSearch, sortBy, order);
    }
  }, [pageNumber,
    textSearch,
    fetchEmployeeList,
    fetchGroupsList,
    employeeListEnable,
    value,
    sortBy,
    order]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (value === '2') {
      setGroupList((prev) => [...prev, ...groups]);
    } else if (employeeList.length > 0 && flag) {
      setFlag(false);
      setEmpList((prev) => [...prev, ...employeeList]);
    } else if (saveTmpRole) {
      setEmpList((prev) => [...prev, ...employeeList]);
    }
  }, [employeeList, groups]); // eslint-disable-line react-hooks/exhaustive-deps
  const searchData = (event) => {
    if (event.key === 'Enter' || (event.type === 'mousedown')) {
      setTextSearch(text);
      setFlag(true);
      setEmpList([]);
      setGroupList([]);
    }
  };
  const handleNext = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setFlag(true);
  };
  useEffect(() => {
    if (prop) {
      setCreateNewGroup(true);
    }
  }, [prop]);
  const useStyles = makeStyles((theme) => AdminStyle(theme));
  const classes = useStyles();
  let title;
  if (value === '0' || value === '1') {
    if (enableCreateUser) {
      title = 'Create New User';
    } else {
      const compname = JSON.parse(localStorage.getItem('user'));
      // eslint-disable-next-line no-useless-concat
      title = `Manage Members - ${compname.company_name}`;
    }
  }
  if (value === '2') {
    title = 'Manage Groups';
  }
  if (value === '3') {
    title = 'Manage Permissions';
  }
  if (value === '4') {
    title = 'Manage Roles';
  }
  const createUser = () => {
    setEmpList([]);
    setValue('0');
    setEnableCreateUser(true);
    setPageNumber(0);
  };
  const createGroup = () => {
    setGroupList([]);
    setPageNumber(0);
    setCreateNewGroup(false);
    fetchGroupsList(pageNumber);
  };
  const createUserData = () => {
    // save new user data to backend
    setFlag(true);
    setEmpList([]);
    setValue('0');
    setEnableCreateUser(false);
    fetchEmployeeList(pageNumber, textSearch, sortBy, order);
  };
  const data = [];
  const handleChange = (role, task, permission) => {
    setTst(true);
    const perm = task.permissions.find((obj) => obj.permission_type === permission);
    const viewData = task.permissions.find((obj) => obj.permission_type === 'view');
    if (data.length) {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].role_id === role._id) {
          const index = data[i].permissions.indexOf(perm.permission_id);
          if (index > -1) {
            data[i].permissions.splice(index, 1);
          } else {
            data[i].permissions.push(perm.permission_id);
            if (viewData && data[i].permissions.indexOf(viewData.permission_id) === -1) {
              data[i].permissions.push(viewData.permission_id);
            }
          }
        } else {
          const permissions = [];
          // eslint-disable-next-line array-callback-return
          role.permission_details.map((perm) => {
            if (Object.keys(perm).length !== 0) {
              permissions.push(perm.permission_id);
              if (viewData && permissions.indexOf(viewData.permission_id) === -1) {
                permissions.push(viewData.permission_id);
              }
            }
          });
          data.push({
            role_id: role._id,
            permissions,
          });
        }
      }
    } else {
      const permissions = [];
      // eslint-disable-next-line array-callback-return
      role.permission_details.map((perm) => {
        if (Object.keys(perm).length !== 0) {
          permissions.push(perm.permission_id);
        }
      });
      data.push({
        role_id: role._id,
        permissions,
      });
      if (data.length) {
        const index = data[0].permissions.indexOf(perm.permission_id);
        if (index > -1) {
          data[0].permissions.splice(index, 1);
        } else {
          data[0].permissions.push(perm.permission_id);
          if (viewData && data[0].permissions.indexOf(viewData.permission_id) === -1) {
            data[0].permissions.push(viewData.permission_id);
          }
        }
      }
    }
    setPermissionData(data);
  };
  const savePermissions = () => {
    setTst(false);
    const header = {
      task: 'Permissions',
      type: 'create',
    };
    const body = {
      permission_data: permissionData,
    };
    savePermission(body, header).then((res) => {
      if (res) {
        setRefreshPermission(true);
        fetchPermissionDetails();
        setSave(true);
        setPermissionData({});
        setValue('3');
      }
    });
  };
  setTimeout(() => {
    setSave(false);
  }, 5000);
  useEffect(() => {
    // const componentDidUpdate = () => {
    if (tst) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  // }
  }, [tst]);
  const addTempRole = () => {
    setEmpList([]);
    setTmpRole(true);
    fetchEmployeeList(0);
  };
  const viewPermission = (row) => {
    const data = [];
    data.push(row.role_id);
    if (row.tempRoles && row.tempRoles.length) {
      // eslint-disable-next-line array-callback-return
      row.tempRoles.map((role) => {
        data.push((role.role).toString());
      });
    }
    setRoles(data);
    setValue('3');
  };
  const deleteEmployee = () => {
    setEmpList([]);
    setTmpRole(true);
    fetchEmployeeList(0);
  };
  if (userDetails.role.toLowerCase() === 'agent') {
    return (
      <Redirect to="/dashboard" />
    );
  }
  const notificationClose = () => {
    setNotification(false);
  };
  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    return isAsc ? 'desc' : 'asc';
  };
  const sortProperties = (property) => {
    handleRequestSort(property);
    setPageNumber(0);
    setSortBy(property);
    setEmpList([]);
    setFlag(true);
  };
  return (
    <div>
      <>
        {value === '3' && !edit ? (
          <Tooltip
            title={
              !permissionCheck(
                AdminsPermissions.permissions.create.task,
                AdminsPermissions.permissions.create.type,
              )
                ? "You don't have permission to Enable/Disable Permissions"
                : ''
            }
            style={{ float: 'right' }}
            placement="top"
          >
            <span className={classes.editSpan}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={permissionCheck(
                  AdminsPermissions.permissions.create.task,
                  AdminsPermissions.permissions.create.type,
                ) ? classes.saveBtn : classes.saveDisableBtn}
                onClick={savePermissions}
              >
                Save
              </Button>
            </span>
          </Tooltip>
        ) : ''}

        {/*
        <Prompt
          when={tst}
          message="Changes that you made may not be saved."
        />
        */}

        <Paper className={classes.paper}>
          <Toolbar className={classes.root}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {enableCreateUser
                ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <StyledLink
                      to="/dashboard"
                      className={classes.breadcrumbs}
                    >
                      Dashboard
                    </StyledLink>

                    <StyledLink
                      color="inherit"
                      underline="always"
                      className={classes.breadcrumbs}
                      to="/admin"
                      onClick={createUserData}
                    >
                      Employee list
                    </StyledLink>
                  </Breadcrumbs>
                )
                : '' }
              {title}
            </Typography>
            {((!enableCreateUser && !createNewGroup) && (value === '0' || value === '2'))
              ? (
                <>
                  <div className={classes.search}>
                    <InputBase
                      placeholder={value === '0' ? 'Search available members' : 'Search group names'}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      value={text}
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={(e) => setText(e.target.value)}
                      onKeyDown={(e) => searchData(e)}
                    />
                    <div>
                      <Button onMouseDown={(e) => searchData(e)}><SearchIcon /></Button>
                    </div>
                  </div>
                </>
              )
              : null}
            <StyledLink to="#">
              {value === '2'
                ? (
                  permissionCheck(
                    AdminsPermissions.groups.create.task,
                    AdminsPermissions.groups.create.type,
                  )
                    ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => setCreateNewGroup(true)}
                      >
                        + ADD NEW GROUP
                      </Button>
                    )
                    : ''
                ) : (value === '0' && !enableCreateUser)
                  ? (
                    <Tooltip
                      title={
                  !permissionCheck(
                    AdminsPermissions.employee_list.create.task,
                    AdminsPermissions.employee_list.create.type,
                  )
                    ? "You don't have permission to create new user"
                    : ''
                }
                      placement="top"
                    >
                      <span className={classes.editSpan}>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          className={permissionCheck(
                            AdminsPermissions.employee_list.create.task,
                            AdminsPermissions.employee_list.create.type,
                          ) ? classes.addButton : classes.disabledButton}
                          onClick={createUser}
                        >
                          + ADD NEW USER
                        </Button>
                      </span>
                    </Tooltip>

                  )

                  : value === '3'
                    ? (
                      <div className={classes.alertBlock}>
                        {save ? (
                          <Alert className={classes.alert} variant="filled" severity="success">
                            Permissions Saved Successfully!
                          </Alert>
                        ) : ''}
                      </div>
                    ) : value === '5'
                      ? (
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={() => setNotification(true)}
                        >
                          + ADD NEW NOTIFICATION
                        </Button>
                      ) : '' }

            </StyledLink>
          </Toolbar>
        </Paper>
        <Paper className={empList.length > 0 ? '' : classes.paperHeight}>
          {enableCreateUser
            ? (
              <div className={classes.createUserDiv}>
                <CreateUser createUserData={createUserData} />
              </div>
            )
            : (
              <TabContext value={value}>
                <AppBar position="static" elevation={0} className={classes.navbar}>
                  <TabList
                    className={classes.tabList}
                    onChange={handleTabs}
                    TabIndicatorProps={{ style: { backgroundColor: '#B07086' } }}
                  >
                    <Tab
                      label="EMPLOYEE LIST"
                      value="0"
                      className={
                        permissionCheck(
                          AdminsPermissions.employee_list.create.task,
                          AdminsPermissions.employee_list.create.type,
                        ) || permissionCheck(
                          AdminsPermissions.employee_list.view.task,
                          AdminsPermissions.employee_list.view.type,
                        ) || permissionCheck(
                          AdminsPermissions.employee_list.update.task,
                          AdminsPermissions.employee_list.update.type,
                        )
                          ? value === '0' ? classes.activeTab : classes.tab
                          : classes.groupHide
                      }
                    />
                    {/* <Tab
                    label="Product Access"
                    value="1"
                    className={value === '1' ? classes.activeTab : classes.tab}
                  /> */}

                    <Tab
                      label="GROUPS"
                      value="2"
                      className={
                       (permissionCheck(
                         AdminsPermissions.groups.create.task,
                         AdminsPermissions.groups.create.type,
                       ) || permissionCheck(
                         AdminsPermissions.groups.view.task,
                         AdminsPermissions.groups.view.type,
                       ))
                         ? value === '2' ? classes.activeTab : classes.tab : classes.groupHide
                      }
                    />

                    <Tab
                      label="PERMISSIONS"
                      value="3"
                      className={
                        permissionCheck(
                          AdminsPermissions.permissions.create.task,
                          AdminsPermissions.permissions.create.type,
                        ) || permissionCheck(
                          AdminsPermissions.permissions.view.task,
                          AdminsPermissions.permissions.view.type,
                        ) || permissionCheck(
                          AdminsPermissions.permissions.update.task,
                          AdminsPermissions.permissions.update.type,
                        )
                          ? value === '3' ? classes.activeTab : classes.tab
                          : classes.groupHide
                      }
                    />
                    <Tab
                      label="ROLES"
                      value="4"
                      className={(permissionCheck(
                        AdminsPermissions.roles.create.task,
                        AdminsPermissions.roles.create.type,
                      ) || permissionCheck(
                        AdminsPermissions.roles.view.task,
                        AdminsPermissions.roles.view.type,
                      ) || permissionCheck(
                        AdminsPermissions.roles.update.task,
                        AdminsPermissions.roles.update.type,
                      ) || permissionCheck(
                        AdminsPermissions.roles.delete.task,
                        AdminsPermissions.roles.delete.type,
                      ))
                        ? value === '4' ? classes.activeTab : classes.tab : classes.groupHide}
                    />
                    <Tab
                      label="NOTIFICATIONS"
                      value="5"
                      className={value === '5' ? classes.activeTab : classes.tab}
                    />
                  </TabList>
                </AppBar>
                <TabPanel key={0} value="0" className={classes.tabPanel}>
                  <EmployeeList
                    empList={empList}
                    total={employeeTotal}
                    addTempRole={addTempRole}
                    handleNext={handleNext}
                    createUser={createUser}
                    deleteEmployee={deleteEmployee}
                    viewPermission={viewPermission}
                    sortProperties={sortProperties}
                    sortBy={sortBy}
                    order={order}
                  />
                </TabPanel>
                <TabPanel key={2} value="2" className={classes.tabPanel}>
                  {createNewGroup ? <CreateGroup createGroup={createGroup} prop={prop} />
                    : (
                      permissionCheck(
                        AdminsPermissions.groups.view.task,
                        AdminsPermissions.groups.view.type,
                      )
                        ? (
                          <Groups
                            groupList={groupList}
                            total={groupTotal}
                            handleNext={handleNext}
                          />
                        )
                        : ''
                    )}
                </TabPanel>
                <TabPanel key={4} value="4" className={classes.tabPanel}>
                  <Roles />
                </TabPanel>
                <TabPanel value="3" className={classes.tabPanel}>
                  <Permissions
                    handleChange={handleChange}
                    editClick={editClick}
                    roles={roles}
                    refreshPermission={refreshPermission}
                  />
                </TabPanel>
                <TabPanel value="5" className={classes.tabPanel}>
                  <Notification
                    notification={notification}
                    notificationClose={notificationClose}
                  />
                </TabPanel>
              </TabContext>
            )}
        </Paper>
      </>
    </div>
  );
};

Admin.propTypes = {
  fetchEmployeeList: PropTypes.func,
  employeeList: PropTypes.array,
  employeeTotal: PropTypes.number,
  fetchGroupsList: PropTypes.func,
  groups: PropTypes.array,
  groupTotal: PropTypes.number,
  prop: PropTypes.object,
  reset: PropTypes.func,
  fetchPermissionDetails: PropTypes.func,
  userDetails: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  employeeList: state.sim.employeeList.result ? state.sim.employeeList.result : [],
  employeeTotal: state.sim.employeeList.total,
  groups: state.sim.result && state.sim.result.groups ? state.sim.result.groups : [],
  groupTotal: state.sim.result ? state.sim.result.total : 0,
  prop: props ? props.location.state : {},
  permissions: state.login.permissions,
  userDetails: state.login.user,
});

const mapDispatchToProps = {
  fetchEmployeeList, fetchGroupsList, fetchPermissionDetails, reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
