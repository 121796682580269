import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-scrollPaper': {
      alignItems: 'normal',
      justifyContent: 'flex-end',
    },
    '& .MuiDialog-paper': {
      margin: 0,
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: 'max-content',
      minWidth: '42%',
      // [theme.breakpoints.down('lg')]: {
      //   minWidth: '60%',
      // },
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[300],
    minHeight: 0,
    minWidth: 0,
    margin: 11,
    padding: 2,
    '& .MuiSvgIcon-fontSizeSmall': {
      padding: 3,
    },
  },
  title: {
    '& .MuiTypography-h6': {
      fontSize: '1.6rem',
      fontWeight: '700',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.1rem',
      },
    },
    padding: 0,
  },
  modalBody: {
    margin: theme.spacing(1, 7, 7, 7),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3, 3, 3),
    },
  },
  divider: {
    margin: theme.spacing(3, 0, 3, 0),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  testSpeakerBtn: {
    fontSize: '0.8em',
    backgroundColor: '#dde7fa',
    '& .MuiButton-label': {
      color: '#264172',
      fontWeight: 'bold',
    },
  },
  textBold: {
    fontWeight: '900',
    fontSize: '11px !important',
    color: '#A8657B',
  },
  description: {
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      fontSize: 15,
    },
  },
  loader: {
    textAlign: 'center',
  },
  tutorialBtn: {
    backgroundColor: '#264173',
    color: 'white',
    margin: '0px 10px',
    padding: '5px 12px',
    fontSize: '0.8em',
  },
}));
