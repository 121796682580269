import { makeStyles, alpha } from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  search: {
    // position: "relative",
    display: 'flex',
    borderRadius: '5px',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: 10,
    width: 300,
    border: '2px solid #e1e1e1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    // position: "absolute",
    pointerEvents: 'none',
    // display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '7px',
  },
  inputRoot: {
    color: 'inherit',
    flexGrow: 1,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  select: {
    minWidth: 200,
    padding: theme.spacing(0, 1, 0, 1),
    marginRight: 10,
  },
  plusIcon: {
    margin: '0 5px 3px 0',
  },
  addNewButton: {
    paddingLeft: '10px',
    color: 'white',
    minWidth: '10%',
    height: 36,
    fontSize: 12,
    whiteSpace: 'nowrap',
    paddingRight: 12,
    backgroundColor: '#284375',
    margin: '0px 5px',
    '&:hover': {
      backgroundColor: '#647087',
    },
  },
}));

export const useTableStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1,
    position: 'inherit',
  },
  cancelButton: {
    color: '#313387',
  },
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  thead: {
    backgroundColor: '#e1e1e1',
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '0.7rem',
  },
  bold: {
    fontWeight: 'bold',
    minWidth: '12rem',
    maxWidth: '12rem',
  },
  built: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '7rem',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  buttonSqr: {
    padding: '5px',
    marginRight: '5px',
    minHeight: 0,
    minWidth: 0,
  },
  buttonDelete: {
    padding: '5px',
    marginRight: '5px',
    minHeight: 0,
    minWidth: 0,
    background: '#DC004E',
    color: '#fff',
  },
  tableRow: {
    cursor: 'pointer',
  },
  dialogDeleteBtn: {
    color: 'red',
  },
  admin_note: {
    maxWidth: '18rem',
    minWidth: '18rem',
  },
  noData: {
    position: 'absolute',
    top: '50%',
    width: '90%',
    textAlign: 'center',
  },
  plus: {
    fontSize: 16,
    fontWeight: 600,
    paddingRight: 5,
  },
  noAssign: {
    marginBottom: 0,
  },
  noAssignPara: {
    fontSize: 12,
  },
  addButton: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    height: 34,
  },
  paper: {
    height: '95%',
  },
  hidePage: {
    height: '92%',
    visibility: 'hidden',
  },
  errorAlert: {
    backgroundColor: '#600727',
  },
  type: {
    minWidth: '7rem',
    maxWidth: '7rem',
  },
  created: {
    minWidth: '5rem',
    maxWidth: '5rem',
  },
  pagination: {
    padding: 13,
    float: 'right',
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      background: '#26344D',
    },
  },
  paginationLabel: {
    padding: 17,
    float: 'left',
  },
  noDataFound: {
    margin: 18,
    color: '#646462',
    fontWeight: 500,
  }
}));
