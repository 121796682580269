export const Login = (theme) => ({
  textfieldRoot: {
    borderRadius: '10',
    '& .MuiFilledInput-root': {
      '& fieldset': {
        borderRadius: '4px',
      },
    },
  },
  root: {
    flexGrow: 1,
    marginTop: '1.4rem',
  },
  selectRoot: {
    backgroundColor: '#DEDEDE',
    padding: '8px 10px',
    fontSize: '12px',
    // '& .MuiSelectInput-root': {
    //   borderRadius: 5,
    // },
    borderRadius: 5,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  palette: {
    primary: {
      color: '#F4F4F4',
      background: '#F4F4F4',
    },
  },
  label: {
    padding: theme.spacing(0, 2, 1, 0),
    marginTop: theme.spacing(2),
    color: '#000',
    fontSize: 'small',
  },
  loginCard: {
    margin: 'auto',
    maxWidth: '21vw',
    borderRadius: '1%',
  },
  bgColor: {
    background: '#fff',
  },
  checkBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  ligthBg: {
    background: '#F4F4F4',
    borderRadius: '5px',
  },
  smallFont: {
    '& .MuiTypography-body1': {
      fontSize: '12px !important',
      whiteSpace: 'noWrap',
    },
  },
  navContainer: {
    background: '#eee',
    color: '#a8a4a4',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  linkFont: {
    fontSize: '.65rem !important',
    color: '#555',
  },
  inputFieldPadding: {
    '& .MuiFilledInput-inputMarginDense': {
      padding: '.7rem .9rem !important',
      fontSize: '12px',
      width: '200px',
    },
    '& .MuiFilledInput-underline:before': {
      border: 0,
    },
    '& .MuiFilledInput-root': {
      borderRadius: 5,
    },
  },
  passwordField: {
    '& .MuiFilledInput-inputMarginDense': {
      padding: '.7rem .9rem !important',
      fontSize: '12px',
    },
    '& .MuiFilledInput-underline:before': {
      border: 0,
    },
    '& .MuiFilledInput-root': {
      borderRadius: 5,
    },
  },
  loader: {
    textAlign: 'center',
  },
  loaderIcon: {
    position: 'fixed',
    zIndex: 1,
  },
  passwordIcon: {
    width: 19,
  },
  numFormat: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#E8E8E8',
      fontSize: 13,
      height: '23px !important',
      paddingLeft: 14,
      borderRadius: 4,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
      content: 'none',
    },
  },
  registerAlert: {
    fontSize: 12,
  },
  errorAlert: {
    backgroundColor: '#600727',
  },
  loginInputFieldPadding: {
    '& .MuiFilledInput-inputMarginDense': {
      padding: '.7rem .9rem !important',
      fontSize: '12px',
    },
    '& .MuiFilledInput-underline:before': {
      border: 0,
    },
    '& .MuiFilledInput-root': {
      '& fieldset': {
        borderRadius: '4px',
      },
    },
  },
  boldFont: {
    fontWeight: '700',
  },
  loginCardContainer: {
    backgroundColor: '#fff',
    padding: '5rem 2rem 2rem 2rem',
    margin: '2.5rem',
    height: '27rem',
  },
  borderLine: {
    borderBottom: '0.1px solid #d8d8d8',
    padding: '0.5em',
  },
  button: {
    fontSize: '12px',
    padding: '7px 49px',
  },
  btnDiv: {
    margin: 10,
  },
});

export default Login;
