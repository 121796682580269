import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Typography,
  withStyles,
  Paper,
  Button,
  InputBase,
  MuiThemeProvider,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { createTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { deleteRole } from '../../services/result.service';
import { fetchCompany, fetchCompanyRoles, saveRole } from '../../redux/admin/action';
import AdminsPermissions from '../../permissions/admin';
import { permissionCheck } from '../../utils/permissionCheck';
import RolesStyle from './Roles';

const globalTheme = createTheme({
  palette: {
    primary: {
      main: '#832544',
      light: '#832544',
      dark: '#832544',
      contrastText: '#fff',
    },
    secondary: {
      main: '#264172',
      light: '#264172',
      dark: '#264172',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: 'Proxima Nova',
  },
});

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: '2em',
    height: '1.2em',
    padding: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(11px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#0E8F66',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: '0.5em',
    height: '0.5em',
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#D8D8D8',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

const Roles = ({
  companyDetails,
  fetchCompany,
  fetchCompanyRoles,
  rolesCompany,
  saveRole,
}) => {
  const useStyles = makeStyles((theme) => RolesStyle(theme));
  const classes = useStyles();
  const [companyName, setCompanyName] = useState('Select');
  const [role, setRole] = useState('');
  const [company, setCompany] = useState('Select');
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState('');
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({});
  const [deleteOpenDialog, setDeleteOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMesage, setAlertMessage] = React.useState('');
  const [disableSave, setDisableSave] = React.useState(false);
  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);
  const handleCompany = (e) => {
    fetchCompanyRoles(e.target.value);
    setCompanyName(e.target.value);
  };
  // Method to save or edit roles
  const onSaveRole = async () => {
    setDisableSave(true);
    const data = {
      name: role,
      company_id: company,
    };
    if (id) {
      data._id = id;
    }
    await saveRole(data);
    if (companyName !== 'Select') {
      await fetchCompanyRoles(companyName);
    }
    setCompany('Select');
    setRole('');
    setId('');
  };
  // Method to Enable or disable the roles
  const onDisable = async (item) => {
    const data = {
      _id: item._id,
      company_id: item.company_id,
      disabled: !item.disabled,
    };
    await saveRole(data);
    await fetchCompanyRoles(companyName);
  };
  // Method to fill the input fields for edit
  const editRole = (item) => {
    setDisableSave(false);
    setRole(item.name);
    setCompany(item.company_id);
    setId(item._id);
    setUpdate(true);
  };
  // Method to cancel the edit
  const cancel = () => {
    setDisableSave(false);
    setUpdate(false);
    setRole('');
    setId('');
    setCompany('Select');
  };
  // Method to open Dialog box for clone
  const handleCopy = (item) => {
    setOpen(true);
    setItem(item);
  };
  // Method to close Dialog box
  const handleClose = () => {
    setOpen(false);
    setItem({});
  };
  // Method to duplicate the role
  const duplicateConfirm = async () => {
    const data = {
      name: `${item.name}(Copy)`,
      company_id: item.company_id,
      duplicate_from: item._id,
    };
    await saveRole(data);
    if (companyName !== 'Select') {
      await fetchCompanyRoles(companyName);
    }
    setOpen(false);
  };
  // Method to close delete dialog bix
  const handleDeleteClose = () => {
    setDeleteOpenDialog(false);
  };
  // Method to delete role
  const deleteConfirm = async () => {
    setDeleteOpenDialog(false);
    const deleteStatus = await deleteRole({ id: item._id });
    if (!deleteStatus.data.success) {
      setAlertMessage(deleteStatus.data.message);
      setOpenAlert(true);
    }
    setDeleteOpenDialog(false);
    if (companyName !== 'Select') {
      await fetchCompanyRoles(companyName);
    }
    setItem({});
  };
  // Method to open delete dialog
  const handleDelete = (item) => {
    setDeleteOpenDialog(true);
    setItem(item);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    setAlertMessage('');
  };

  return (
    <div>
      <MuiThemeProvider theme={globalTheme}>
        {openAlert ? (
          <Alert
            variant="filled"
            severity="error"
            onClose={handleAlertClose}
            className={classes.errorAlert}
          >
            {alertMesage}
          </Alert>
        ) : null}
        <Grid container direction="row" spacing={3}>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Typography
              variant="subtitle1"
              align="left"
              className={`${classes.boldFont} ${classes.roleGrid}`}
            >
              <Box mb={0.5}>Roles</Box>
              <Box>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: '100%' }}
                >
                  <Select
                    labelId="select-role-type"
                    id="simple-select-role"
                    fullWidth
                    value={companyName}
                    onChange={handleCompany}
                    className={classes.select}
                  >
                    <MenuItem value="Select" selected>
                      Select Company
                    </MenuItem>
                    {companyDetails.map((ele) => (
                      <MenuItem key={ele._id} value={ele._id}>
                        {ele.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TableContainer
                  component={Paper}
                  className={`${classes.root} ${classes.transprentBackground}`}
                >
                  <Table aria-label="sims table">
                    <TableHead className={classes.thead}>
                      <TableRow>
                        <TableCell className={`${classes.heading} ${classes.headingBorder}`}>ROLE</TableCell>
                        <TableCell padding="none" className={classes.heading}>
                          ENABLE
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={`${classes.heading} ${classes.textRight}`}
                        >
                          ACTION
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.pySmall} />
                        <TableCell className={classes.pySmall} />
                        <TableCell className={classes.pySmall} />
                      </TableRow>
                      {rolesCompany.map((item) => (
                        <TableRow key={item._id}>
                          <TableCell
                            className={`${classes.bodyCell} ${classes.leftTdRadius}`}
                          >
                            {item.name}
                          </TableCell>

                          <TableCell
                            padding="none"
                            className={classes.bodyCell}
                          >
                            <Tooltip
                              title={
                              permissionCheck(
                                AdminsPermissions.roles.update.task,
                                AdminsPermissions.roles.update.type,
                              ) ? '' : "You don't have permission to enable/disable the roles"
                            }
                              placement="top"
                            >
                              <span>
                                <StyledSwitch
                                  color="primary"
                                  onClick={() => onDisable(item)}
                                  className={permissionCheck(
                                    AdminsPermissions.roles.update.task,
                                    AdminsPermissions.roles.update.type,
                                  ) ? classes.enableSwitch : classes.disableSwitch}
                                  checked={!item.disabled}
                                />
                              </span>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            padding="none"
                            className={`${classes.bodyCell} ${classes.rightTdRadius} ${classes.textRight}`}
                          >
                            <Box component="span" mr={1}>
                              <Tooltip title="Edit" placement="top">
                                <EditIcon
                                  style={{
                                    background: '#E2EAF9',
                                    padding: '.2rem',
                                    cursor: 'pointer',
                                  }}
                                  color="secondary"
                                  fontSize="small"
                                  onClick={() => editRole(item)}
                                />
                              </Tooltip>
                              <Tooltip title="Duplicate" placement="top">
                                <FileCopyIcon
                                  style={{
                                    background: '#E2EAF9',
                                    padding: '.2rem',
                                    margin: '0 .3rem',
                                    cursor: 'pointer',
                                  }}
                                  color="secondary"
                                  fontSize="small"
                                  onClick={() => handleCopy(item)}
                                />
                              </Tooltip>
                              <Tooltip title="Delete" placement="top">
                                <DeleteForeverIcon
                                  style={{
                                    background: '#E2EAF9',
                                    padding: '.2rem',
                                    cursor: 'pointer',
                                  }}
                                  color="secondary"
                                  fontSize="small"
                                  onClick={() => handleDelete(item)}
                                />
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle1"
              align="left"
              className={`${classes.boldFont} ${classes.addRoleGrid}`}
            >
              <Box mb={0.5}>Add Role</Box>
              <Box>
                <div className={classes.search}>
                  <InputBase
                    placeholder="Enter Role"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  />
                </div>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: '100%' }}
                >
                  <Box my={3} style={{ border: '1px #E8E2E4 solid' }} />
                  <Box mb={1}>
                    <Typography variant="body2" component="h3">
                      Select Company
                    </Typography>
                  </Box>
                  <Select
                    labelId="select-company-type"
                    id="simple-select-company"
                    fullWidth
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    className={classes.select}
                  >
                    <MenuItem value="Select" selected>
                      Select
                    </MenuItem>
                    {companyDetails.map((ele) => (
                      <MenuItem key={ele._id} value={ele._id}>
                        {ele.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Box my={2}>
                    {!update
                      ? (
                        <Tooltip
                          title={
                          permissionCheck(
                            AdminsPermissions.roles.create.task,
                            AdminsPermissions.roles.create.type,
                          ) ? '' : "You don't have permission to create new roles"
                          }
                          placement="top-end"
                          style={{ float: 'right' }}
                        >
                          <span>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={permissionCheck(
                                AdminsPermissions.roles.create.task,
                                AdminsPermissions.roles.create.type,
                              ) ? classes.addRoleButton : classes.disableAddBtn}
                              disableElevation
                              disabled={disableSave || (role === '' && companyName === 'Select')}
                              onClick={onSaveRole}
                            >
                              Save
                            </Button>
                          </span>
                        </Tooltip>
                      )
                      : (
                        <>
                          <Tooltip
                            title={
                          permissionCheck(
                            AdminsPermissions.roles.update.task,
                            AdminsPermissions.roles.update.type,
                          ) ? '' : "You don't have permission to update"
                          }
                            placement="top-end"
                            style={{ float: 'right' }}
                          >
                            <span>
                              <Button
                                className={permissionCheck(
                                  AdminsPermissions.roles.update.task,
                                  AdminsPermissions.roles.update.type,
                                ) ? classes.addRoleButton : classes.disableAddBtn}
                                variant="contained"
                                color="primary"
                                disabled={disableSave}
                                onClick={onSaveRole}
                              >
                                Update
                              </Button>
                            </span>
                          </Tooltip>
                          <Button
                            variant="contained"
                            className={classes.cancel}
                            disableElevation
                            onClick={cancel}
                          >
                            CANCEL
                          </Button>
                        </>
                      )}
                  </Box>

                </FormControl>
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={3} />
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Duplicate Role</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to duplicate this role
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Tooltip
              title={
              permissionCheck(
                AdminsPermissions.roles.create.task,
                AdminsPermissions.roles.create.type,
              ) ? '' : "You don't have permission to duplicate"
              }
              placement="top-end"
              style={{ float: 'right' }}
            >
              <span>
                <Button
                  onClick={() => duplicateConfirm()}
                  autoFocus
                  className={permissionCheck(
                    AdminsPermissions.roles.create.task,
                    AdminsPermissions.roles.create.type,
                  ) ? classes.duplicate : classes.disableDuplicate}
                >
                  Duplicate
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteOpenDialog}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Role</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to delete this role
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDeleteClose}
              className={classes.cancelButton}
            >
              Cancel
            </Button>

            <Tooltip
              title={
              permissionCheck(
                AdminsPermissions.roles.delete.task,
                AdminsPermissions.roles.delete.type,
              ) ? '' : "You don't have permission to delete"
              }
              placement="top-end"
              style={{ float: 'right' }}
            >
              <span>
                <Button
                  onClick={() => deleteConfirm()}
                  autoFocus
                  className={permissionCheck(
                    AdminsPermissions.roles.delete.task,
                    AdminsPermissions.roles.delete.type,
                  ) ? classes.duplicate : classes.disableDuplicate}
                >
                  Delete
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyDetails: state.admin.companyDetails,
  rolesCompany: state.admin.rolesCompany,
  roles: state.admin.roles,
});

const mapDispatchToProps = {
  fetchCompany,
  fetchCompanyRoles,
  saveRole,
};

Roles.propTypes = {
  fetchCompany: PropTypes.func,
  companyDetails: PropTypes.array,
  fetchCompanyRoles: PropTypes.func,
  rolesCompany: PropTypes.array,
  saveRole: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
