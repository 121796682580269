import {
  makeStyles, withStyles, Tabs, Avatar,
} from '@material-ui/core';

export const useTabContentStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cancelButton: {
    color: '#313387',
  },
  arrowDropDown: {
    backgroundColor: '#D7D7D7',
    margin: '3px 5px -5px 5px',
    borderRadius: '3px',
  },
  title: {
    backgroundColor: 'rgb(239,239,239)',
    padding: 2,
    borderRadius: 3,
    margin: 5,
  },
  editIcon: {
    color: '#5c77778c',
    fontSize: 20,
    cursor: 'pointer',
  },
  grid: {
    paddingTop: 8,
  },
  selectedCheckbox: {
    padding: 'inherit',
    color: 'rgb(26,77,158) !important',
  },
  checkbox: {
    padding: 'inherit',
  },
  span: {
    padding: 8,
    fontWeight: 600,
    fontSize: 14,
  },
  textFont: {
    fontSize: 13,
  },
  anim: {
    fontSize: 13,
    // '& .MuiInputBase-input': {
    //   animation: '$typewriter 4s steps(44) 1s 1 normal both, $blinkTextCursor 500ms steps(44) infinite normal',
    // },
  },
  gridItem: {
    padding: '5px 25px !important',
  },
  linkTag: {
    borderBottom: '1px dotted #949494',
    padding: 3,
  },
  '@keyframes typewriter': {
    from: {
      width: 0,
    },
    to: {
      width: '100%',
    },
  },
  '@keyframes blinkTextCursor': {
    from: {
      'border-right-color': 'black',
    },
    to: {
      'border-right-color': 'transparent',
    },
  },
  select: {
    '& .MuiNativeSelect-select': {
      fontSize: 12,
      color: '#646466',
      marginLeft: 20,
    },
    '& .MuiNativeSelect-icon': {
      left: '-6px',
    },
  },
}));

export const useNavbarStyles = makeStyles(() => ({
  navbar: {
    backgroundColor: '#ffffff',
    width: '100%',
  },
  cancelButton: {
    color: '#313387',
  },
}));

export const useBasicInfoStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  cancelButton: {
    color: '#313387',
  },
  demo1: {
    backgroundColor: 'rgb(223,230,242)',
  },
  background: {
    backgroundColor: 'rgb(38,65,114)',
    padding: '0px 5px 0px 12px !important',
  },
  bgContent: {
    backgroundColor: 'rgb(28,52,95)',
    padding: '10px 12px 10px 25px !important',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  topHeaderFont: {
    color: '#ffffffb3',
    fontSize: 11,
  },
  bottomHeaderFont: {
    color: '#FFFFFF',
    fontSize: 13,
  },
  height: {
    minHeight: '40rem',
    backgroundColor: '#FFFFFF',
  },
  tabPadding: {
    padding: '25px !important',
  },
  tabHeader: {
    backgroundColor: '#30565526',
    height: 40,
    minHeight: '0px !important',
  },
  tabs: {
    color: 'rgb(5, 37, 76)',
  },
  tabBody: {
    backgroundColor: '#e2eeef54',
    padding: 0,
  },
  txtAlign: {
    textAlign: 'right',
    fontSize: 12,
  },
  history: {
    fontSize: 12,
    lineHeight: 2,
  },
  historyPadding: {
    padding: 15,
  },
  button: {
    color: '#1d3cd4',
    fontSize: 12,
    width: 130,
    border: 'none',
    backgroundColor: '#bfd3d882',
  },
  icon: {
    padding: '0px',
    width: 30,
    height: 30,
    backgroundColor: 'rgb(70,93,135)',
    borderRadius: '11%',
    margin: '0px 6px',
  },
  center: {
    textAlign: 'center',
    marginTop: 10,
  },
  tabFont: {
    fontSize: 13,
  },
  moreButton: {
    background: '#9caabb87',
    padding: '5px !important',
    fontSize: 11,
    textAlign: 'center',
    margin: 5,
    borderRadius: 5,
    minWidth: 42,
    cursor: 'pointer',
  },
  buttons: {
    padding: '7px !important',
    textAlign: 'center',
    fontSize: 12,
  },
  select: {
    width: '100%',
    padding: theme.spacing(0, 1, 0, 1),
    margin: '8px 0px',
    height: 40,
    background: '#FFFFFF',
  },
  margin: {
    margin: '8px 0px',
    background: '#FFFFFF',
  },
  formFont: {
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: '#FFFFFF',
    borderRadius: 5,
    flexGrow: 1,
  },
  selectedButton: {
    backgroundColor: 'rgb(86,114,163)',
    color: '#FFFFFF',
    padding: '5px !important',
    fontSize: 11,
    textAlign: 'center',
    margin: 5,
    borderRadius: 5,
    minWidth: 42,
    cursor: 'pointer',
  },
  textFieldColor: {
    color: '#FFFFFF',
  },
  img: {
    height: 15,
    width: 12,
  },
  case: {
    fontWeight: 100,
    fontSize: 12,
    color: '#ffffffe8',
  },
  border: {
    borderBottom: '1px solid #ded7d7',
    padding: '10px 0px',
  },
  moreTitle: {
    background: 'rgb(223,230,242)',
    color: 'rgb(38,65,114)',
    padding: '7px 15px !important',
    margin: 5,
    borderRadius: 4,
    fontWeight: 600,
  },
  keywordSelectionGrid: {
    maxHeight: '75vh',
    overflowY: 'scroll',
  },
  download: {
    height: 20,
    color: 'white',
    marginTop: 6,
  },
  listAction: {
    right: 5,
  },
  toggleBtn: {
    height: 32,
    border: '1px solid #465d87',
    margin: '0px 6px',
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: '#465d87',
      color: '#fff',
      fontSize: 12,
    },
    '& .MuiToggleButton-root': {
      color: '#fff',
      fontSize: 12,
    },
  },
  tab: {
    padding: theme.spacing(0),
    minWidth: '125px',
    width: '120px',
    fontSize: '11px',
    minHeight: '30px',
    height: '30px',
    textTransform: 'none',
  },
  gridTab: {
    padding: 20,
  },
  footerButton: {
    position: 'sticky',
    bottom: 0,
    padding: 10,
    fontSize: 20,
    display: 'inline-flex',
    width: 'inherit',
    background: '#efefef',
    justifyContent: 'center',
  },
  fBtn: {
    margin: 5,
  },
}));

export const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    minHeight: '2px',
    '& .MuiTabs-fixed': {
      overflow: 'auto !important',
    },
  },
  indicator: {
    backgroundColor: 'rgb(70,117,211)',
  },
})(Tabs);

export const SmallAvatar = withStyles(() => ({
  root: {
    marginBottom: 5,
    width: 18,
    height: 18,
  },
}))(Avatar);
