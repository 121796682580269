/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
const env = {
  local: {
    API_BASE_URL: 'http://127.0.0.1:8585',
    /* These 3 are used in the PhysicianMap.jsx component, that is not used anywhere */
    /*
    GOOGLE_MAP_KEY: 'AIzaSyBG3o3uYCyRUQ56z1kok47J2wNdYN8HqW8',
    GOOGLE_PLACE_API: 'https://maps.googleapis.com/maps/api/js',
    GOOGLE_GEOCODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
    */
    MOCK_URL: 'localhost:4000',
    SF_API_BASE_URL: 'http://localhost:8080',
    hideSFRelatedData: false,
    snowFlyAudioMerge: true, //false,
  },
  dev: {
    API_BASE_URL: '',
    /* These 3 are used in the PhysicianMap.jsx component, that is not used anywhere */
    /*
    GOOGLE_MAP_KEY: 'AIzaSyBG3o3uYCyRUQ56z1kok47J2wNdYN8HqW8',
    GOOGLE_PLACE_API: 'https://maps.googleapis.com/maps/api/js',
    GOOGLE_GEOCODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
    */
    MOCK_URL: 'http://ec2-34-229-143-78.compute-1.amazonaws.com:4000',
    SF_API_BASE_URL: 'https://rlc.sfproxy.edstem.com',
    hideSFRelatedData: true,
    snowFlyAudioMerge: true,
  },
  stage: {
    API_BASE_URL: 'https://rlc-stage.api.edstem.com',
    /* These 3 are used in the PhysicianMap.jsx component, that is not used anywhere */
    /*
    GOOGLE_MAP_KEY: 'AIzaSyBG3o3uYCyRUQ56z1kok47J2wNdYN8HqW8',
    GOOGLE_PLACE_API: 'https://maps.googleapis.com/maps/api/js',
    GOOGLE_GEOCODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
    */
    MOCK_URL: 'http://ec2-34-229-143-78.compute-1.amazonaws.com:4000',
    SF_API_BASE_URL: 'https://rlc-stage.sfproxy.edstem.com',
    hideSFRelatedData: false,
    snowFlyAudioMerge: true,
  },
  prod: {
    API_BASE_URL: 'https://rlc.snowfly.com',
    /* These 3 are used in the PhysicianMap.jsx component, that is not used anywhere */
    /*
    GOOGLE_MAP_KEY: 'AIzaSyBG3o3uYCyRUQ56z1kok47J2wNdYN8HqW8',
    GOOGLE_PLACE_API: 'https://maps.googleapis.com/maps/api/js',
    GOOGLE_GEOCODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
    */
    MOCK_URL: 'http://ec2-34-229-143-78.compute-1.amazonaws.com:4000',
    SF_API_BASE_URL: 'https://sfproxy.rlc.snowfly.com',
    hideSFRelatedData: false,
    snowFlyAudioMerge: true,
  }
}

// Get token from local storage
const getToken = () => `Bearer ${localStorage.getItem('token')}`;

export default {
  getToken,
  ...env[process.env.REACT_APP_STAGE],
};