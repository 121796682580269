import axios from 'axios';
import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  CHECK_AUTHORIZATION,
  USER_LOGOUT,
  SAVE_PERMISSIONS,
  FETCH_FAILURE,
  UPDATE_LOGIN_STATUS,
  REGISTRATION_FAILURE,
  UPDATE_ROLE,
  RESET_NOTIFICATION,
  FETCH_NOTIFICATIONS,
} from './authTypes';
import config from '../../config';

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFail = (payload) => ({
  type: LOGIN_FAIL,
  payload,
});

// Check Authorization action
export const checkAuthorization = () => ({
  type: CHECK_AUTHORIZATION,
});

// User logout action

export const userLogout = () => ({
  type: USER_LOGOUT,
});

// user login actions
export const loginUser = (payload) => (dispatch) => {
  dispatch(loginRequest);
  axios({
    url: `${config.API_BASE_URL}/api/v1/login/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      task: null,
      type: null,
    },
    data: payload,
  }).then((res) => {
    dispatch(loginSuccess(res.data));
  }).catch((err) => {
    dispatch(loginFail(err.response.data));
  });
};

export const fetchFailure = (data) => ({
  type: FETCH_FAILURE,
  payload: data,
});

export const addPermissionDetails = (data) => ({
  type: SAVE_PERMISSIONS,
  payload: data,
});

export const fetchPermissionDetails = () => async (dispatch) => {
  dispatch(loginRequest);
  await axios.get(`${config.API_BASE_URL}/api/v1/permissions/user-details`, {
    headers: {
      Authorization: config.getToken(),
      task: null,
      type: null,
    },
  })
    .then((res) => dispatch(addPermissionDetails(res.data)))
    .catch((error) => dispatch(fetchFailure(error)));
};

export const updateLoginStatus = (payload) => ({
  type: UPDATE_LOGIN_STATUS,
  payload,
});

export const registrationFailed = (data) => ({
  type: REGISTRATION_FAILURE,
  payload: data,
});

export const registerUser = (data) => (dispatch) => {
  dispatch(loginRequest);
  axios({
    url: `${config.API_BASE_URL}/api/v1/users/register`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      task: null,
      type: null,
    },
    data,
  }).then((res) => {
    dispatch(registrationFailed(res.data));
  }).catch((err) => {
    dispatch(registrationFailed(err.response.data));
  });
};

export const updateRole = (payload) => ({
  type: UPDATE_ROLE,
  payload,
});
export const loginToAgentAccount = (payload) => (dispatch) => {
  dispatch(loginRequest);
  axios({
    url: `${config.API_BASE_URL}/api/v1/login/login-to-agent-account`,
    method: 'POST',
    headers: {
      task: null,
      type: null,
    },
    data: payload,
  }).then((res) => {
    dispatch(loginSuccess(res.data));
  }).catch((err) => {
    dispatch(loginFail(err.response.data));
  });
};
export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
});
export const fetchNotifyList = (data) => ({
  type: FETCH_NOTIFICATIONS,
  payload: data,
});
export const fetchNotification = (pageNumber, notificationListingPage) => async (dispatch) => {
  dispatch(loginRequest);
  const limit = 10;
  const skip = limit * pageNumber;
  await axios(`${config.API_BASE_URL}/api/v1/users/notification-listing?skip=${skip}&limit=${limit}&notificationListingPage=${notificationListingPage}`,
    {
      headers: {
        Authorization: config.getToken(),
        task: null,
        type: null,
      },
    })
    .then((res) => dispatch(fetchNotifyList(res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
