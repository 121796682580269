import { alpha } from '@material-ui/core/styles';

export const Admin = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  search: {
    display: 'flex',
    borderRadius: '5px',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: 10,
    width: 300,
    border: '2px solid #e1e1e1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    flexGrow: 1,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    pointerEvents: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
  select: {
    minWidth: 200,
    padding: theme.spacing(0, 1, 0, 1),
    marginRight: 10,
  },
  navbar: {
    backgroundColor: '#FFF',
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
  },
  tab: {
    borderRadius: '5%',
    color: 'black',
    backgroundColor: '#E2E2E2',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: '125px',
    width: '120px',
    fontSize: '1em',
    fontWeight: [600],
    minHeight: '30px',
    height: '30px',
    textTransform: 'none',
  },
  activeTab: {
    borderRadius: '5%',
    color: 'black',
    backgroundColor: '#F8DEE7',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: '125px',
    width: '120px',
    fontSize: '1em',
    fontWeight: [600],
    minHeight: '30px',
    height: '30px',
    textTransform: 'none',
  },
  tabList: {
    minHeight: '30px',
    height: '30px',
  },
  tabPanel: {
    padding: theme.spacing(1.5, 2, 1.5, 2),
  },
  createUserDiv: {
    marginTop: 12,
    background: '#f6f6f6',
  },
  breadcrumbs: {
    textDecoration: 'underline !important',
    color: '#3a383896',
    fontSize: 14,
  },
  paper: {
    boxShadow: 'none !important',
  },
  alertBlock: {
    display: 'flex',
  },
  alert: {
    padding: '0px 16px',
    marginRight: 5,
  },
  saveBtn: {
    float: 'right',
    margin: 14,
  },
  saveDisableBtn: {
    float: 'right',
    margin: 14,
    opacity: '0.5',
    pointerEvents: 'none',
  },
  paperHeight: {
    height: '100%',
  },
  groupHide: {
    display: 'none',
  },
  addButton: {
    color: 'white',
    minWidth: '10%',
    height: 36,
    fontSize: 12,
    whiteSpace: 'nowrap',
    paddingRight: 12,
    backgroundColor: '#284375',
    margin: '0px 5px',
    '&:hover': {
      backgroundColor: '#647087',
    },
  },
  disabledButton: {
    backgroundColor: '#284375',
    color: '#fff',
    height: 36,
    minWidth: '10%',
    opacity: '0.5',
    fontSize: 12,
    pointerEvents: 'none',
  },
});

export default Admin;
