export const Notification = () => ({
  cancelButton: {
    color: '#313387',
  },
  label: {
    color: '#232222fa',
    fontWeight: 500,
    padding: '5px 0px',
    fontSize: '15px',
  },
  textArea: {
    width: '100%',
    border: '1px solid #cdcbcb',
    borderRadius: 3,
    padding: 10,
    fontFamily: 'Proxima Nova',
    fontSize: 16,
    color: '#626262',
    '&:focus': {
      outline: 'none !important',
      borderColor: '#9a4d62',
    },
  },
  link: {
    color: '#5a5959',
    cursor: 'pointer',
    borderBottom: '1px solid #949090',
    fontSize: '15px',
  },
  notifyButton: {
    borderWidth: '2px',
    margin: '15px 2px',
    float: 'right',
    fontSize: '13px',
    height: '34px',
  },
  cancel: {
    color: 'rgb(136,38,71)',
    background: 'none !important',
    margin: '15px 2px',
    fontSize: '13px',
    float: 'right',
  },
  message: {
    fontSize: 16,
    fontWeight: 400,
  },
  subDetails: {
    fontSize: 14,
    color: '#919191',
  },
  card: {
    background: '#edf1f9',
  },
  content: {
    padding: 10,
    paddingBottom: '10px !important',
  },
  error: {
    width: '100%',
    border: '1px solid #f25a4a',
    marginTop: 10,
    padding: 10,
    borderRadius: 3,
    '&:focus': {
      outline: 'none !important',
      borderColor: '#9a4d62',
    },
  },
});

export default Notification;
