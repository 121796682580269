import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import SelectAvatars from '../../components/sim/selectAvatars/SelectAvatars.jsx';
import BasicInfo from '../../components/sim/BasicInfo.jsx';
import Keywords from '../../components/sim/keywords/Keywords.jsx';
import CreateLevels from '../../components/sim/createLevels/CreateLevels.jsx';

const CreateSims = ({ newSim, simProductType }) => {
  /* console.log('newSim = %o', newSim);
  console.log('simProductType = %o', simProductType ); */

  if (newSim.activeStep === 0) {
    return (
      <div>
        <BasicInfo />
      </div>
    );
  }
  if (newSim.activeStep === 1) {
    return (
      <div>
        <SelectAvatars />
      </div>
    );
  }
  if (newSim.activeStep === 2) {
    return (
      <div>
        <CreateLevels />
      </div>
    );
  }
  //if (newSim.activeStep === 3 && !simProductType.includes('Voice Only')) {
  //if ((newSim.activeStep === 3) && (newSim.product_id !== "5fd9e04bbddbcc04f024f09d")) {
  if (newSim.activeStep === 3 && simProductType.includes('Internal Data')) {
    return (
      <div>
        <Keywords />
      </div>
    );
  }
  return (
    <div>
      {/* redirect to sims page */}
      <Redirect to="/sims" />
    </div>
  );
};

CreateSims.propTypes = {
  newSim: PropTypes.shape({
    activeStep: PropTypes.number,
  }),
  simProductType: PropTypes.string
};

const mapStateToProps = (state) => ({
  newSim: state.sim.newSim,
  simProductType: state.sim.currentSimProductType,
});

export default connect(mapStateToProps)(CreateSims);
