export const Footer = (theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(3),
    display: 'flex',
    bottom: 0,
  },
  copyright: {
    flexGrow: 1,
    paddingLeft: '100px',
  },
  elements: {
    marginRight: theme.spacing(2),
  },
});

export default Footer;
