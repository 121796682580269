import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import NewSimMenuStyle from './NewSimMenu';

const getSteps = () => ['Basic Info', 'Select Avatars', 'Create Levels', 'Data Input'];

const QontoConnector = withStyles({
  root: {
    display: 'none',
  },
})(StepConnector);

const NewSimMenu = ({ newSim, simProductType }) => {

  const useStyles = makeStyles((theme) => NewSimMenuStyle(theme));
  const classes = useStyles();
  const { activeStep } = newSim;
  const steps = getSteps();
  return (
    <Toolbar className={classes.root}>
      <div>
        <Stepper
          activeStep={activeStep}
          connector={<QontoConnector />}
          className={classes.stepperRoot}
        >
          {steps.map((label, index) => {
            if (simProductType.includes('Voice Only') && index === 3) { return false; }
            return (
              <Step
                key={label}
                className={index <= activeStep ? classes.stepActive : classes.step}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div>
        {activeStep === 2 ? <Button variant="outlined" className={classes.tutorialBtn}>TUTORIAL</Button> : null}
      </div>
    </Toolbar>
  );
};

NewSimMenu.propTypes = {
  newSim: PropTypes.shape({
    activeStep: PropTypes.number,
  }),
  simProductType: PropTypes.string
};
const mapStateToProps = (state) => ({
  newSim: state.sim.newSim,
  simProductType: state.sim.currentSimProductType,
});

export default connect(mapStateToProps)(NewSimMenu);
