import {
  DELETE_COMPANY_ROLES_FAIL,
  DELETE_COMPANY_ROLES_REQUEST,
  DELETE_COMPANY_ROLES_SUCCESS,
  FETCH_COMPANY_FAIL,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_ROLES_FAIL,
  FETCH_COMPANY_ROLES_REQUEST,
  FETCH_COMPANY_ROLES_SUCCESS,
  FETCH_COMPANY_SUCCESS,
  FETCH_REQUEST,
  FETCH_FAILURE,
  FETCH_STATUS,
  FETCH_SUB_STATUS,
  FETCH_ROLES,
  FETCH_GROUPS,
  SAVE_USER,
  SAVE_GROUP,
  FETCH_EMPLOYEES,
  ADD_ROLES,
  EDIT_GROUP_IN_USER,
  RESET,
  SAVE_TOOLTIP,
  FETCH_NOTIFICATION,
} from './adminTypes';

const initialState = {
  companyDetails: [],
  rolesCompany: [],
  isLoading: false,
  data: [],
  status: null,
  userData: {
    first_name: '',
    last_name: '',
    title: '',
    email_id: '',
    phone_number: '',
    password: '',
    status: '',
    sub_status: '',
    role: '',
    schedule: '',
    duration: '',
    employment: '',
    workplace: '',
    joined_company: '',
    probation: '',
    promoted: '',
    groups: [],
    outsourcer: '',
  },
  selectStatus: {},
  selectSubStatus: {},
  selectRoles: [],
  selectGroups: [],
  notifications: {},
  roles: {
    name: '',
    company: '',
  },
  group: {
    name: '',
    description: '',
    owner_id: '',
  },
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        companyDetails: payload,
        isLoading: false,
      };
    case FETCH_COMPANY_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_COMPANY_ROLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_COMPANY_ROLES_SUCCESS:
      return {
        ...state,
        rolesCompany: payload,
        isLoading: false,
      };
    case FETCH_COMPANY_ROLES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_COMPANY_ROLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_COMPANY_ROLES_SUCCESS:
      return {
        ...state,
        rolesCompany: payload,
        isLoading: false,
      };
    case DELETE_COMPANY_ROLES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FAILURE:
      const status = payload.message ? payload.message.split(' ') : [];
      if (status[status.length - 1] === '403') {
        localStorage.setItem('redirectUrl', '/permissiondenied');
        window.location.href = '/permissiondenied';
        return {
          ...state,
          isLoading: false,
          permissionDenied: true,
        };
      }
      if (status[status.length - 1] === '401') {
        window.location.href = '/login';
        localStorage.clear('token');
        localStorage.clear('user');
        return {
          ...state,
          isLoading: false,
          isAuth: false,
          status: action.payload,
        };
      }
      return {
        ...state,
        isLoading: false,
        status: action.payload,
      };

    case FETCH_STATUS:
      return {
        ...state,
        isLoading: true,
        selectStatus: action.payload.data,
        status: action.payload.status,
      };
    case FETCH_SUB_STATUS:
      return {
        ...state,
        isLoading: true,
        selectSubStatus: action.payload.data,
        status: action.payload.status,
      };
    case FETCH_ROLES:
      return {
        ...state,
        isLoading: true,
        selectRoles: action.payload.data,
        status: action.payload.status,
      };
    case FETCH_GROUPS:
      return {
        ...state,
        isLoading: true,
        selectGroups: action.payload.data,
        status: action.payload.status,
      };
    case SAVE_USER:
      return {
        ...state,
        result: action.result,
      };
    case ADD_ROLES:
      return {
        ...state,
        roles: action.payload,
        selectRoles: [],
      };
    case SAVE_GROUP:
      return {
        ...state,
        group: action.result,
      };
    case FETCH_EMPLOYEES:
      return {
        ...state,
        employees: action.payload.data,
      };
    case EDIT_GROUP_IN_USER:
      return {
        ...state,
        userGroup: action.payload.data,
      };
    case RESET:
      return {
        ...state,
        rolesCompany: [],
        notifications: {},
      };
    case SAVE_TOOLTIP:
      return {
        ...state,
        tooltip: action.result,
      };
    case FETCH_NOTIFICATION:
      return {
        ...state,
        isLoading: true,
        notifications: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
