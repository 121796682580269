import { makeStyles } from '@material-ui/core/styles';

export const useCheckboxStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    margin: theme.spacing(1, 0, 0, 1),
  },
  checkbox: {
    marginRight: -10,
    justifyContent: 'flex-end',
  },
  divider: {
    marginRight: theme.spacing(2),
  },
  textFont: {
    fontSize: 12,
    color: '#696867',
  },
  select: {
    '& .MuiNativeSelect-select': {
      fontSize: 12,
      color: '#646466',
      marginLeft: 20,
    },
    '& .MuiNativeSelect-icon': {
      left: '-6px',
    },
  },
}));

export const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#1B1B1B',
  },
  tooltip: {
    backgroundColor: '#1B1B1B',
    width: '200px',
    padding: '8px',
  },
}));
