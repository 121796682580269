import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(4),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export const useBasicInfoStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  cancel: {
    color: 'rgb(136,38,71)',
    border: '2px solid rgb(136,38,71)',
    padding: '6px 30px 6px 30px',
    background: 'none !important',
    marginRight: 10,
  },
  nextButton: {
    padding: theme.spacing(1, 5, 1, 5),
    borderWidth: '2px',
  },
  colBorderRight: {
    borderRight: '2px solid #ccc7c763',
    paddingRight: 18,
  },
  colBorderLeft: {
    paddingLeft: 18,
  },
  select: {
    minWidth: 280,
    padding: theme.spacing(0, 1, 0, 1),
    marginTop: 10,
    height: 40,
  },
  radio: {
    paddingLeft: 'unset',
    color: '#CED4DA;',
  },
  radioColor: { color: '#CED4DA;' },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  margin: {
    marginTop: 10,
  },
  formHeight: {
    minHeight: '52rem',
  },
  browseBtn: {
    marginTop: 12,
  },
  browseDisableBtn: {
    marginTop: 12,
    pointerEvents: 'none',
    opacity: '0.5',
  },
  fileName: {
    marginTop: 20,
  },
  closeIcon: {
    display: 'inline-table',
    verticalAlign: 'middle',
    fontSize: 20,
    borderRadius: 15,
    marginLeft: 8,
    border: '2px solid #cc6487',
    padding: 1,
    color: '#882e47',
    cursor: 'pointer',
  },
  inputLable: {
    color: '#242324',
  },
  tagInput: {
    maxWidth: 450,
    minWidth: 400,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    overflow: 'hidden',
    paddingLeft: 5,
    paddingTop: 5,
    cursor: 'pointer',
    marginTop: 10,
    '& .react-tagsinput-tag': {
      borderRadius: 2,
      display: 'inline-block',
      fontSize: 14,
      fontWeight: '400',
      marginBottom: 5,
      marginRight: 5,
      padding: 5,
      background: '#F5E2E9',
      border: '1px solid #F5E2E9',
      color: 'black',
      fontFamily: 'inherit',
    },
    '& .react-tagsinput-remove': {
      fontSize: 20,
      color: '#882e47',
    },

  },
  description: {
    width: '100%',
    border: '1px solid #cdcbcb',
    marginTop: 10,
    borderRadius: 3,
    padding: '10px 14px',
    fontFamily: 'Proxima Nova',
    fontSize: 16,
    color: '#626262',
    '&:focus': {
      outline: 'none !important',
      borderColor: '#9a4d62',
    },
  },
  error: {
    width: '100%',
    border: '1px solid #f25a4a',
    marginTop: 10,
    borderRadius: 3,
    padding: '10px 14px',
    '&:focus': {
      outline: 'none !important',
      borderColor: '#9a4d62',
    },
  },
  captivatefilename: {
    fontSize: 11,
    padding: '0px 8px',
    color: '#000000',
    cursor: 'none',
    fontWeight: 200,
  },
  docs: {
    fontSize: 11,
    color: '#000000',
    cursor: 'none',
    fontWeight: 200,
  },
  linear: {
    width: '30%',
  },
}));
