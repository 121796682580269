/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { Grid, FormControlLabel, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import Badge from '@material-ui/core/Badge';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
import AssignmentTable from '../../components/dashboard/AssignmentTable.jsx';
import ScoreFilters from '../../components/dashboard/ScoreFilters.jsx';
import UserData from '../../components/dashboard/UserData.jsx';
import DateFilter from '../../components/dashboard/DateFilter.jsx';
import ScoreTable from '../../components/dashboard/scoreTable.jsx';
import {
  fetchAssignments,
  fetchUserData,
  fetchTotalAssignments,
  fetchScoreList,
  reset,
  fetchAssignmentsCount,
} from '../../redux/sims/actions';
import Export from '../../assets/images/icons/ExportIcon.png';
import AddAssignment from '../../components/dashboard/AddAssignment.jsx';
import {
  fetchAllAssignments,
  fetchScoreLists,
} from '../../services/result.service';
import DashboardPermissions from '../../permissions/dashboard';
import SimsPermissions from '../../permissions/sims';
import { permissionCheck } from '../../utils/permissionCheck';
import DashboardStyle from './Dashboard';

const Dashboard = ({
  userData,
  fetchUserData,
  assignments,
  fetchAssignments,
  fetchScoreList,
  allAssignmentCount,
  myAssignmentCount,
  completedAssignmentCount,
  readyForReviewCount,
  unopenedCompletedAssignments,
  unopenedPendingAssignments,
  scores,
  totalScore,
  pendingForReview,
  completedAssignCount,
  assignment_loading,
  dashboard_listing,
  permissions,
  userDetails,
  reset,
  products,
  fetchAssignmentsCount,
  countData,
}) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const useStyles = makeStyles((theme) => DashboardStyle(theme));
  const classes = useStyles();
  const [value, setValue] = React.useState('allAssignment');
  const [tabValue, setTabValue] = React.useState('myAssignment');
  const [showAddAssignment, setshowAddAssignment] = useState(false);
  const [inMyAssignmentTab, setInMyAssignmentTab] = useState(false);
  const [inReadyReviewTab, setInReadyReviewTab] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [text, setText] = React.useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [assign, setAssign] = useState([]);
  const [scoreList, setScoreList] = useState([]);
  const [finalResult, setFinalResult] = useState(true);
  const [highestLevel, setHighestLevel] = useState(false);
  const [permission, setPermission] = useState(false);
  const [viewPermission, setViewPermission] = useState('');
  const [userPermission, setUserPermission] = useState(false);
  const [order, setOrder] = React.useState(null);
  const [dateRange, setDateRange] = useState(false);
  const [orderBy, setOrderBy] = React.useState(null);
  const [sort, setSort] = React.useState(null);
  const [sortBy, setSortBy] = React.useState(null);
  const [filterDatas, setFilterDatas] = useState({});
  const [page, setPage] = useState(1);
  const [sortPage, setSortPage] = useState(1);
  localStorage.setItem('sims_id', '');
  useEffect(() => {
    if (
      permissionCheck(
        DashboardPermissions.user_account.view.task,
        DashboardPermissions.user_account.view.type,
      )
    ) {
      fetchUserData();
    }
  }, [fetchUserData, permissions]);

  if (
    permissionCheck(
      DashboardPermissions.all_assignments.view.task,
      DashboardPermissions.all_assignments.view.type,
    ) !== undefined
    && permissionCheck(
      DashboardPermissions.all_assignments.view.task,
      DashboardPermissions.all_assignments.view.type,
    )
    && !permission
  ) {
    setPermission(true);
  }
  if (
    permissionCheck(
      SimsPermissions.assignment.view.task,
      SimsPermissions.assignment.view.type,
    ) !== undefined
    && !viewPermission && (permissionCheck(
      SimsPermissions.assignment.view.task,
      SimsPermissions.assignment.view.type,
    )
      || permissionCheck(
        DashboardPermissions.review_assignments.view.task,
        DashboardPermissions.review_assignments.view.type,
      )
      || permissionCheck(
        DashboardPermissions.all_assignments.view.task,
        DashboardPermissions.all_assignments.view.type,
      ))
  ) {
    setViewPermission(
      permissionCheck(
        SimsPermissions.assignment.view.task,
        SimsPermissions.assignment.view.type,
      )
      || permissionCheck(
        DashboardPermissions.review_assignments.view.task,
        DashboardPermissions.review_assignments.view.type,
      )
      || permissionCheck(
        DashboardPermissions.all_assignments.view.task,
        DashboardPermissions.all_assignments.view.type,
      ),
    );
  }
  if (
    (permissionCheck(
      DashboardPermissions.company_name.view.task,
      DashboardPermissions.company_name.view.type,
    )
      || permissionCheck(
        DashboardPermissions.user_account.view.task,
        DashboardPermissions.user_account.view.type,
      )
      || permissionCheck(
        DashboardPermissions.user_name.view.task,
        DashboardPermissions.user_name.view.type,
      ))
    && !userPermission
  ) {
    setUserPermission(true);
  }

  useEffect(() => {
    if (viewPermission) {
      if (!permission) {
        // Check if all assignment permission is denied, so check for ready review option
        if (
          !permissionCheck(
            DashboardPermissions.all_assignments.view.task,
            DashboardPermissions.all_assignments.view.type,
          )
        ) {
          setValue('myAssignment');
          const tab = 'MY_ASSIGNMENT';
          setPage(1);
          fetchAssignments(pageNumber, textSearch, true, tab, orderBy, order, 0);
        }
      } else {
        setValue('allAssignment');
        setPage(1);
        const tab = 'All_ASSIGNMENT';
        fetchAssignments(pageNumber, textSearch, false, tab, orderBy, order, 0);
      }
      fetchAssignmentsCount();
    }
    // eslint-disable-next-line max-len
  }, [fetchAssignments, permission, viewPermission]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (viewPermission && permission) {
      if (tabValue === 'myAssignment') {
        if (value === 'myAssignment' && permission) {
          const tab = 'MY_ASSIGNMENT';
          setPage(1);
          fetchAssignments(pageNumber, textSearch, true, tab, orderBy, order, 0);
        } else if (value === 'allAssignment') {
          const tab = 'All_ASSIGNMENT';
          setPage(1);
          fetchAssignments(pageNumber, textSearch, false, tab, orderBy, order, 0);
        } else if (value === 'completed') {
          const tab = 'COMPLETED';
          setPage(1);
          fetchAssignments(pageNumber, textSearch, false, tab, orderBy, order, 0);
        } else if (value === 'readyForReview') {
          const tab = 'PENDING_REVIEW';
          setPage(1);
          fetchAssignments(pageNumber, textSearch, false, tab, orderBy, order, 0);
        }
      }
    }
    // eslint-disable-next-line max-len
  }, [pageNumber, textSearch, fetchAssignments, permission, viewPermission]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // setAssign((prev) => {
    //   if (
    //     prev.length
    //     && assignments.length
    //     && prev[0].assignment_id.toString()
    //     === assignments[0].assignment_id.toString()
    //   ) {
    //     return assignments;
    //   }
    //   return [...prev, ...assignments];
    // });
    setAssign(assignments);
  }, [assignments]);

  useEffect(() => {
    if (tabValue === 'score' && products.length) {
      let filterData;
      if (!filterDatas.product) {
        filterData = {
          product: products[0]._id,
          assigned: true,
        };
      } else {
        filterData = filterDatas;
      }
      setSortPage(1);
      fetchScoreList(pageNumber, textSearch, finalResult, highestLevel, filterData, sortBy, sort, 0);
    }
    // eslint-disable-next-line
  }, [pageNumber, textSearch, fetchScoreList, finalResult,highestLevel, tabValue, products.length]);

  useEffect(() => {
    setScoreList(scores);
    // setScoreList((prev) => [...prev, ...scores]);
  }, [scores]);
  const handleChange = (event) => {
    setPageNumber(0);
    setTabValue(event.currentTarget.value);
    setFilterDatas({});
    setAssign([]);
    setScoreList([]);
    setFinalResult(true);
    setHighestLevel(false);
    if (
      !permissionCheck(
        DashboardPermissions.all_assignments.view.task,
        DashboardPermissions.all_assignments.view.type,
      )
    ) {
      setValue('myAssignment');
    } else {
      setValue('allAssignment');
    }

    if (event.currentTarget.value === 'myAssignment') {
      if (value === 'allAssignment') {
        const myAssignment = false;
        setInMyAssignmentTab(false);
        setInReadyReviewTab(false);
        setPage(1);
        fetchAssignments(pageNumber, textSearch, myAssignment, null, orderBy, order, 0);
      } else {
        const myAssignment = true;
        setInMyAssignmentTab(true);
        setInReadyReviewTab(false);
        const tab = 'MY_ASSIGNMENT';
        setPage(1);
        fetchAssignments(pageNumber, textSearch, myAssignment, tab, orderBy, order, 0);
      }
    } else {
      setAssign([]);
    }
  };
  const refreshCount = async () => {
    setAssign([]);
    setPageNumber(0);
    setPage(1);
    await fetchAssignments(pageNumber, textSearch, false, null, orderBy, order, 0);
    await fetchAssignmentsCount();
  };
  const handleChangeAssignment = (event) => {
    setAssign([]);
    setPageNumber(0);
    if (tabValue === 'myAssignment') {
      setValue(event.currentTarget.value);
      if (event.currentTarget.value === 'allAssignment') {
        reset();
        const myAssignment = false;
        const page = 0;
        setInMyAssignmentTab(false);
        setInReadyReviewTab(false);
        setPage(1);
        fetchAssignments(page, textSearch, myAssignment, null, orderBy, order, 0);
      } else if (event.currentTarget.value === 'completed') {
        reset();
        const myAssignment = false;
        setInMyAssignmentTab(false);
        setInReadyReviewTab(false);
        const tab = 'COMPLETED';
        setPage(1);
        fetchAssignments(pageNumber, textSearch, myAssignment, tab, orderBy, order, 0);
      } else if (event.currentTarget.value === 'readyForReview') {
        reset();
        const myAssignment = false;
        setInReadyReviewTab(true);
        setInMyAssignmentTab(false);
        const tab = 'PENDING_REVIEW';
        setPage(1);
        fetchAssignments(pageNumber, textSearch, myAssignment, tab, orderBy, order, 0);
      } else {
        reset();
        const myAssignment = true;
        setInMyAssignmentTab(true);
        setInReadyReviewTab(false);
        const tab = 'MY_ASSIGNMENT';
        setPage(1);
        fetchAssignments(pageNumber, textSearch, myAssignment, tab, orderBy, order, 0);
      }
    }
  };
  const addAssignment = () => {
    setshowAddAssignment(true);
  };

  const handleClose = () => {
    setshowAddAssignment(false);
    setAssign([]);
    setPageNumber(0);
    if (value === 'allAssignment') {
      setInMyAssignmentTab(false);
      setInReadyReviewTab(false);
      setPage(1);
      fetchAssignments(pageNumber, textSearch, false, null, orderBy, order, 0);
    } else if (value === 'myAssignment') {
      setInMyAssignmentTab(true);
      setInReadyReviewTab(false);
      const tab = 'MY_ASSIGNMENT';
      setPage(1);
      fetchAssignments(pageNumber, textSearch, true, tab, orderBy, order, 0);
    } else if (value === 'completed') {
      setInMyAssignmentTab(false);
      setInReadyReviewTab(false);
      const tab = 'COMPLETED';
      setPage(1);
      fetchAssignments(pageNumber, textSearch, true, tab, orderBy, order, 0);
    } else if (value === 'readyForReview') {
      setInMyAssignmentTab(false);
      setInReadyReviewTab(true);
      const tab = 'PENDING_REVIEW';
      setPage(1);
      fetchAssignments(pageNumber, textSearch, true, tab, orderBy, order, 0);
    }
    fetchAssignmentsCount();
  };
  const handleClick = () => {
    if (finalResult) {
      setFinalResult(false);
      setScoreList([]);
    } else {
      setFinalResult(true);
      setScoreList([]);
    }
    if (highestLevel) {
      setHighestLevel(false);
    }
  };
  const handleHighestLevelReached = () => {
    setHighestLevel(!highestLevel);
    setScoreList([]);
    if (finalResult) {
      setFinalResult(false);
    }
  };
  const search = (e) => {
    if (e.key === 'Enter' || (e.type === 'mousedown')) {
      setScoreList([]);
      setAssign([]);
      setPageNumber(0);
      setTextSearch(text);
    }
    // setScoreList([]);
    // setTextSearch(e.target.value);
  };
  const handleScore = async () => {
    const pageNo = 0;
    setPageNumber(0);
    setScoreList([]);
    let filterData;
    if (!filterDatas.product) {
      filterData = {
        product: products[0]._id,
      };
    } else {
      filterData = filterDatas;
    }
    setSortPage(1);
    fetchScoreList(pageNo, textSearch, finalResult, highestLevel, filterData, sortBy, sort, 0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    return isAsc ? 'desc' : 'asc';
  };

  const sortProperties = (property) => {
    const ord = handleRequestSort(property);
    const ordBy = property;
    setPageNumber(0);
    setOrderBy(property);
    setAssign([]);
    let tab;
    if (value === 'allAssignment') {
      setPage(1);
      fetchAssignments(0, textSearch, false, null, ordBy, ord, 0);
    } else if (value === 'myAssignment') {
      tab = 'MY_ASSIGNMENT';
      setPage(1);
      fetchAssignments(0, textSearch, true, tab, ordBy, ord, 0);
    } else if (value === 'completed') {
      tab = 'COMPLETED';
      setPage(1);
      fetchAssignments(0, textSearch, true, tab, ordBy, ord, 0);
    } else if (value === 'readyForReview') {
      tab = 'PENDING_REVIEW';
      setPage(1);
      fetchAssignments(0, textSearch, true, tab, ordBy, ord, 0);
    }
  };

  const handleSort = (property) => {
    const isAsc = sortBy === property && sort === 'asc';
    setSort(isAsc ? 'desc' : 'asc');
    return isAsc ? 'desc' : 'asc';
  };

  const sorting = (property) => {
    const ord = handleSort(property);
    const ordBy = property;
    setPageNumber(0);
    setSortBy(property);
    setScoreList([]);
    let filterData;
    if (!filterDatas.product) {
      filterData = {
        product: products[0]._id,
      };
    } else {
      filterData = filterDatas;
    }
    if (pageNumber === 0) {
      const pageNum = 0;
      setSortPage(1);
      fetchScoreList(pageNum, textSearch, finalResult, highestLevel, filterData, ordBy, ord, 0);
    }
  };

  const excelDownLoad = () => {
    let myAssignment;
    let tab;
    if (tabValue === 'myAssignment') {
      if (value === 'allAssignment') {
        myAssignment = false;
      } else if (value === 'completed') {
        myAssignment = false;
        tab = 'COMPLETED';
      } else if (value === 'readyForReview') {
        myAssignment = false;
        tab = 'PENDING_REVIEW';
      } else {
        myAssignment = true;
        tab = 'MY_ASSIGNMENT';
      }
      let task = null;
      let type = null;
      if (tab === 'MY_ASSIGNMENT' || tab === 'COMPLETED') {
        task = null;
        type = null;
      } else if (tab === 'PENDING_REVIEW') {
        task = DashboardPermissions.review_assignments.view.task;
        type = DashboardPermissions.review_assignments.view.type;
      }
      fetchAllAssignments(myAssignment, tab, textSearch, task, type).then(
        (response) => {
          const fileName = value === 'allAssignment'
            ? 'All_Assignment'
            : value === 'myAssignment'
              ? 'My_Assignment'
              : value === 'completed'
                ? 'Completed_Assignments'
                : 'Ready_For_Review';
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(blob, `${fileName}.xlsx`);
        },
      );
    } else {
      if (!filterDatas.product) {
        filterDatas.product = products[0]._id;
      }
      const download = true;
      fetchScoreLists(textSearch, finalResult, highestLevel, download, filterDatas).then((response) => {
        const fileName = 'Score_List';
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(blob, `${fileName}.xlsx`);
      });
    }
  };
  const dateSelection = () => {
    setPageNumber(0);
    setDateRange(true);
  };
  const productChange = (filterData) => {
    setPageNumber(0);
    setScoreList([]);
    const pNum = 0;
    setFilterDatas(filterData);
    filterData.startDate = filterDatas.startDate !== undefined ? filterDatas.startDate : '';
    filterData.endDate = filterDatas.endDate !== undefined ? filterDatas.endDate : '';
    setSortPage(1);
    fetchScoreList(pNum, textSearch, finalResult, highestLevel, filterData, sortBy, sort, 0);
  };
  const handleDateRange = (evt) => {
    const { value } = evt.target;
    if (filterDatas.product) {
      setFilterDatas({
        ...filterDatas,
        [evt.target.name]: value,
      });
    } else {
      setFilterDatas({
        ...filterDatas,
        product: products[0]._id,
        [evt.target.name]: value,
      });
    }
  };

  const apply = () => {
    setScoreList([]);
    setSortPage(1);
    fetchScoreList(pageNumber, textSearch, finalResult, highestLevel, filterDatas, sortBy, sort, 0);
    setDateRange(false);
  };
  const filter = (type) => {
    if (!filterDatas.product) {
      setFilterDatas({
        ...filterDatas,
        product: products[0]._id,
      });
      filterDatas.product = products[0]._id;
    }
    if (type === 'today') {
      setFilterDatas({
        ...filterDatas,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      filterDatas.startDate = moment().format('YYYY-MM-DD');
      filterDatas.endDate = moment().format('YYYY-MM-DD');
    } else if (type === 'yesterday') {
      setFilterDatas({
        ...filterDatas,
        startDate: moment().add(-1, 'days').format('YYYY-MM-DD'),
        endDate: moment().add(-1, 'days').format('YYYY-MM-DD'),
      });
      filterDatas.startDate = moment().add(-1, 'days').format('YYYY-MM-DD');
      filterDatas.endDate = moment().add(-1, 'days').format('YYYY-MM-DD');
    } else if (type === 'last_7_days') {
      setFilterDatas({
        ...filterDatas,
        startDate: moment().add(-6, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      filterDatas.startDate = moment().add(-7, 'days').format('YYYY-MM-DD');
      filterDatas.endDate = moment().format('YYYY-MM-DD');
    } else if (type === 'last_week') {
      setFilterDatas({
        ...filterDatas,
        startDate: moment().day(-6).format('YYYY-MM-DD'),
        endDate: moment().day(0).format('YYYY-MM-DD'),
      });
      filterDatas.startDate = moment().day(-6).format('YYYY-MM-DD');
      filterDatas.endDate = moment().day(0).format('YYYY-MM-DD');
    } else if (type === 'month_to_date') {
      setFilterDatas({
        ...filterDatas,
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      filterDatas.startDate = moment().startOf('month').format('YYYY-MM-DD');
      filterDatas.endDate = moment().format('YYYY-MM-DD');
    } else if (type === 'previous_month') {
      setFilterDatas({
        ...filterDatas,
        startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      });
      filterDatas.startDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      filterDatas.endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    } else {
      setFilterDatas({
        ...filterDatas,
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      filterDatas.startDate = moment().startOf('year').format('YYYY-MM-DD');
      filterDatas.endDate = moment().format('YYYY-MM-DD');
    }
    setDateRange(false);
    setScoreList([]);
    setSortPage(1);
    fetchScoreList(pageNumber, textSearch, finalResult, highestLevel, filterDatas, sortBy, sort, 0);
  };
  const cancelDate = () => {
    setDateRange(false);
    setFilterDatas({
      ...filterDatas,
      startDate: '',
      endDate: '',
    });
    setScoreList([]);
    const filterData = filterDatas;
    filterData.startDate = '';
    filterData.endDate = '';
    setSortPage(1);
    fetchScoreList(pageNumber, textSearch, finalResult, highestLevel, filterData, sortBy, sort, 0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    const skip = (newPage - 1) * 10;
    if (value === 'allAssignment') {
      const tab = 'ALL_ASSIGNMENT';
      fetchAssignments(pageNumber, textSearch, false, tab, orderBy, order, skip);
    } else if (value === 'myAssignment') {
      const tab = 'MY_ASSIGNMENT';
      fetchAssignments(pageNumber, textSearch, false, tab, orderBy, order, skip);
    } else if (value === 'completed') {
      const tab = 'COMPLETED';
      fetchAssignments(pageNumber, textSearch, false, tab, orderBy, order, skip);
    } else if (value === 'readyForReview') {
      const tab = 'PENDING_REVIEW';
      fetchAssignments(pageNumber, textSearch, false, tab, orderBy, order, skip);
    }
  };
  const handleSortPageChange = (event, newPage) => {
    setSortPage(newPage);
    const skip = (newPage - 1) * 10;
    if (tabValue === 'score' && products.length) {
      let filterData;
      if (!filterDatas.product) {
        filterData = {
          product: products[0]._id,
        };
      } else {
        filterData = filterDatas;
      }
      fetchScoreList(pageNumber, textSearch, finalResult, highestLevel, filterData, sortBy, sort, skip);
    }
  };
  return (
    <div>
      {localStorage.getItem('backToDashboard')
        && !viewPermission
        && !userPermission ? (
          <h1 style={{ textAlign: 'center' }}>No Assignments</h1>
        ) : (
          <Grid container spacing={2}>
            {showAddAssignment && (
            <AddAssignment open={showAddAssignment} handleClose={handleClose} />
            )}
            <Grid item className={classes.grid3}>
              {tabValue === 'score'
                ? <ScoreFilters productChange={productChange} />
                : (
                  <UserData
                    userData={userData}
                    readyForReviewCount={pendingForReview}
                    countData={countData}
                  />
                )}

            </Grid>
            <Grid item className={classes.grid9}>
              {!permissionCheck(
                DashboardPermissions.all_assignments.view.task,
                DashboardPermissions.all_assignments.view.type,
              )
              && !permissionCheck(
                DashboardPermissions.review_assignments.view.task,
                DashboardPermissions.review_assignments.view.type,
              )
              && !permissionCheck(
                SimsPermissions.assignment.view.task,
                SimsPermissions.assignment.view.type,
              ) ? (
                <h2 style={{ textAlign: 'center' }}>
                  No Permission For View Assignments, Please Contact Your
                  Administrative!
                </h2>
                ) : (
                  <Paper elevation={0} className={classes.paper}>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={classes.title}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          {tabValue !== 'score' ? 'My Assignments' : 'My Scores'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <List
                          className={classes.padding}
                          style={{ float: 'right' }}
                        >
                          <ListItem>
                            <div className={classes.search}>
                              <InputBase
                                value={text}
                                placeholder="Search Assignments"
                                className={classes.inputSearch}
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => setText(e.target.value)}
                                onKeyDown={(e) => search(e)}
                              />
                              <div className={classes.searchBtn}>
                                <Button onMouseDown={(e) => search(e)}><SearchIcon /></Button>
                              </div>
                            </div>
                            {tabValue === 'score'
                              ? (
                                <div className={classes.search}>
                                  <InputBase
                                    value={filterDatas.startDate && filterDatas.endDate
                                      ? `${moment(filterDatas.startDate).format('DD-MM-YYYY')}-${moment(filterDatas.endDate).format('DD-MM-YYYY')}` : ''}
                                    placeholder="Select Date Range"
                                    className={classes.inputSearch}
                                    name="dateRange"
                                  />
                                  <div className={classes.searchBtn}>
                                    <Button onClick={dateSelection}><DateRangeIcon /></Button>
                                  </div>
                                </div>
                              ) : ''}
                            {tabValue !== 'score' && userDetails.role.toLowerCase() !== 'agent'
                              ? (
                                <div className={classes.assignmentDiv}>
                                  <Tooltip
                                    title={
                                  !permissionCheck(
                                    SimsPermissions.assignment.create.task,
                                    SimsPermissions.assignment.create.type,
                                  )
                                    ? 'you dont have permission to create'
                                    : ''
                                }
                                    placement="bottom"
                                  >
                                    <span>
                                      <Button
                                        variant="contained"
                                        disableElevation
                                        className={classes.addButton}
                                        onClick={addAssignment}
                                        disabled={
                                      !permissionCheck(
                                        SimsPermissions.assignment.create.task,
                                        SimsPermissions.assignment.create.type,
                                      )
                                    }
                                      >
                                        <span className={classes.plus}>+</span>
                                        {' '}
                                        ADD
                                        ASSIGNMENT
                                      </Button>
                                    </span>
                                  </Tooltip>
                                  <Button
                                    size="small"
                                    disableElevation
                                    className={classes.buttonSqr}
                                    onClick={excelDownLoad}
                                  >
                                    <img
                                      className={classes.img}
                                      height={30}
                                      src={Export}
                                      alt="export"
                                    />
                                  </Button>
                                </div>
                              ) : null}
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      className={classes.tabs}
                    >
                      {tabValue === 'myAssignment' ? (
                        <>
                          {permissionCheck(
                            DashboardPermissions.all_assignments.view
                              .task,
                            DashboardPermissions.all_assignments.view.type,
                          ) ? (
                            <Grid item xs={4} sm={2}>
                              <Button
                                variant="contained"
                                value="allAssignment"
                                onClick={handleChangeAssignment}
                                className={value === 'allAssignment' ? classes.active : classes.inActive}
                              >
                                All Assignment

                                (
                                {countData.allAssignmentCount}
                                )
                              </Button>
                            </Grid>
                            ) : null}
                          {permissionCheck(
                            SimsPermissions.assignment.view.task,
                            SimsPermissions.assignment.view.type,
                          )
                            || permissionCheck(
                              SimsPermissions.assignment.create.task,
                              SimsPermissions.assignment.create.type,
                            )
                            || permissionCheck(
                              SimsPermissions.assignment.delete.task,
                              SimsPermissions.assignment.delete.type,
                            ) ? (
                              <Grid item xs={4} sm={2}>
                                <Button
                                  variant="contained"
                                  value="myAssignment"
                                  onClick={handleChangeAssignment}
                                  className={value === 'myAssignment' ? classes.active : classes.inActive}
                                >
                                  My Assignments

                                  (
                                  {countData.myAssignmentCount}
                                  )
                                </Button>
                              </Grid>
                            ) : null}
                          {permissionCheck(
                            SimsPermissions.assignment.view.task,
                            SimsPermissions.assignment.view.type,
                          )
                            || permissionCheck(
                              SimsPermissions.assignment.create.task,
                              SimsPermissions.assignment.create.type,
                            )
                            || permissionCheck(
                              SimsPermissions.assignment.delete.task,
                              SimsPermissions.assignment.delete.type,
                            ) ? (
                              <Grid item xs={4} sm={2}>
                                <Button
                                  variant="contained"
                                  value="completed"
                                  onClick={handleChangeAssignment}
                                  className={value === 'completed' ? classes.active : classes.inActive}
                                >
                                  Completed

                                  (
                                  {countData.completedAssignmentCount}
                                  )
                                  {unopenedCompletedAssignments > 0
                                    ? (
                                      <Badge
                                        className={classes.badge}
                                        badgeContent={unopenedCompletedAssignments}
                                        max={999}
                                        showZero
                                      />
                                    )
                                    : ''}
                                </Button>
                              </Grid>
                            ) : null}
                          {permissionCheck(
                            DashboardPermissions.review_assignments.view
                              .task,
                            DashboardPermissions.review_assignments.view
                              .type,
                          ) ? (
                            <Grid item xs={4} sm={2}>
                              <Button
                                variant="contained"
                                value="readyForReview"
                                onClick={handleChangeAssignment}
                                className={value === 'readyForReview' ? classes.active : classes.inActive}
                              >
                                {user.role.toLowerCase() === 'agent' ? 'Pending Review' : 'Ready For Review'}

                                (
                                {countData.readyForReviewCount}
                                )
                                {unopenedPendingAssignments > 0 && user.role.toLowerCase() !== 'agent'
                                  ? (
                                    <Badge
                                      className={classes.badge}
                                      badgeContent={unopenedPendingAssignments}
                                      max={999}
                                      showZero
                                    />
                                  )
                                  : ''}
                              </Button>
                            </Grid>
                            ) : null}
                          {userDetails.role.toLowerCase() !== 'agent'
                            ? (
                              <Grid item xs={4} sm={2}>
                                <Button
                                  variant="contained"
                                  value="score"
                                  onClick={handleChange}
                                  className={
                                  tabValue === 'score' ? classes.active : classes.inActive
                                }
                                >
                                  SCORE
                                </Button>
                              </Grid>
                            ) : null}
                        </>
                      ) : null }
                    </Grid>
                    {tabValue === 'score'
                      ? (
                        <Grid
                          container
                          direction="row"
                          alignItems="flex-start"
                          className={classes.tabs}
                        >
                          <Grid item xs={4} sm={2}>
                            <Button
                              variant="contained"
                              value="myAssignment"
                              onClick={handleChange}
                              className={
                                tabValue !== 'score' ? classes.active : classes.inActive
                              }
                            >
                              Assignments
                            </Button>
                          </Grid>
                          <Grid item xs={4} sm={2}>
                            <Button
                              variant="contained"
                              className={classes.excel}
                              onClick={excelDownLoad}
                            >
                              Export Excel
                            </Button>
                          </Grid>
                          <Grid item xs={2} sm={2} className={classes.checkLabel}>
                            <FormControlLabel
                              className={classes.label}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value={highestLevel}
                                  checked={highestLevel}
                                  name="final_result"
                                  onChange={handleHighestLevelReached}
                                />
                                )}
                              label="Highest level reached"
                            />
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <FormControlLabel
                              className={classes.label}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value={finalResult}
                                  checked={finalResult}
                                  name="final_result"
                                  onChange={handleClick}
                                />
                                )}
                              label="Final result only"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    <Grid container className={classes.container}>
                      {dashboard_listing && assignments.length === 0 && tabValue !== 'score' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '70vh',
                            margin: 'auto',
                          }}
                        >
                          <CircularProgress color="primary" />
                        </div>
                      )
                        : tabValue === 'myAssignment'
                    && (value === 'myAssignment'
                      || 'allAssignment'
                      || 'completed'
                      || 'readyForReview') && (
                      <Grid item xs={12} className={classes.gridWidth}>
                        <AssignmentTable
                          assignments={assign}
                          inMyAssignmentTab={inMyAssignmentTab}
                          inReadyReviewTab={inReadyReviewTab}
                          assignment_loading={assignment_loading}
                          value={value}
                          sortProperties={sortProperties}
                          order={order}
                          orderBy={orderBy}
                          refreshCount={refreshCount}
                          total={
                            value === 'allAssignment'
                              ? allAssignmentCount
                              : value === 'myAssignment'
                                ? myAssignmentCount
                                : value === 'completed'
                                  ? completedAssignmentCount
                                  : readyForReviewCount
                          }
                          handlePageChange={handlePageChange}
                          addAssignment={addAssignment}
                          page={page}
                          allAssignmentCount={allAssignmentCount}
                        />
                      </Grid>
                        )}
                      {tabValue === 'score' && (
                      <Grid item xs={12} className={classes.scoreTable}>
                        <ScoreTable
                          scoreList={scoreList}
                          handleSortPageChange={handleSortPageChange}
                          sortPage={sortPage}
                          handleScore={handleScore}
                          totalScore={totalScore}
                          sorting={sorting}
                          sort={sort}
                          sortBy={sortBy}
                          assignment_loading={assignment_loading}
                        />
                      </Grid>
                      )}
                    </Grid>
                  </Paper>
                )}
            </Grid>
          </Grid>
        )}
      <DateFilter
        dateRange={dateRange}
        handleClose={handleClose}
        handleDateRange={handleDateRange}
        filterDatas={filterDatas}
        filter={filter}
        apply={apply}
        cancelDate={cancelDate}
      />
    </div>
  );
};

Dashboard.propTypes = {
  userData: PropTypes.object,
  fetchUserData: PropTypes.func,
  assignments: PropTypes.array,
  myAssignmentCount: PropTypes.number,
  allAssignmentCount: PropTypes.number,
  completedAssignmentCount: PropTypes.number,
  readyForReviewCount: PropTypes.number,
  fetchAssignments: PropTypes.func,
  fetchScoreList: PropTypes.func,
  scores: PropTypes.array,
  totalScore: PropTypes.number,
  pendingForReview: PropTypes.number,
  completedAssignCount: PropTypes.number,
  assignment_loading: PropTypes.bool,
  dashboard_listing: PropTypes.bool,
  permissions: PropTypes.array,
  userDetails: PropTypes.object,
  unopenedCompletedAssignments: PropTypes.number,
  unopenedPendingAssignments: PropTypes.number,
  reset: PropTypes.func,
  products: PropTypes.array,
  fetchAssignmentsCount: PropTypes.func,
  countData: PropTypes.object,
};
const mapStateToProps = (state) => ({
  isLoading: state.sim.isLoading,
  totalSims: state.sim.total,
  assignments: state.sim.assignments ? state.sim.assignments.assignments : [],
  userData: state.sim.userData,
  allAssignmentCount: state.sim.assignments
    ? state.sim.assignments.allAssignmentCount
    : 0,
  myAssignmentCount: state.sim.assignments
    ? state.sim.assignments.myAssignmentCount
    : 0,
  completedAssignmentCount: state.sim.assignments
    ? state.sim.assignments.completedAssignmentCount
    : 0,
  readyForReviewCount: state.sim.assignments
    ? state.sim.assignments.readyForReviewCount
    : 0,
  pendingForReview: state.sim.assignments
    ? state.sim.assignments.pendingForReview
    : 0,
  completedAssignCount: state.sim.assignments
    ? state.sim.assignments.completedAssignCount
    : 0,
  unopenedCompletedAssignments: state.sim.assignments
    ? state.sim.assignments.unopenedCompletedAssignments
    : 0,
  unopenedPendingAssignments: state.sim.assignments
    ? state.sim.assignments.unopenedPendingAssignments
    : 0,
  scores: state.sim.scores ? state.sim.scores.scores : [],
  totalScore: state.sim.scores ? state.sim.scores.total : 0,
  assignment_loading: state.sim.assignment_loading,
  dashboard_listing: state.sim.dashboard_listing,
  permissions: state.login.permissions,
  userDetails: state.login.user,
  products: state.sim.products ? state.sim.products : [],
  countData: state.sim.assignmentDataCount,
});

const mapDispatchToProps = {
  fetchAssignments,
  fetchUserData,
  fetchTotalAssignments,
  fetchScoreList,
  reset,
  fetchAssignmentsCount,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
