import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import {
  makeStyles, withStyles,
} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import InfiniteScroll from 'react-infinite-scroll-component';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InputBase from '@material-ui/core/InputBase';
import DeleteIcon from '@material-ui/icons/Delete';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import {
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  NativeSelect,
  DialogTitle,
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import moment from 'moment';
import {
  fetchEmployeeList,
} from '../../redux/sims/actions';
import noEmployees from '../../assets/images/noEmployees.png';
import { permissionCheck } from '../../utils/permissionCheck';
import AdminsPermissions from '../../permissions/admin';
import {
  fetchSelectRoles,
} from '../../redux/admin/action';
import { loginToAgentAccount } from '../../redux/authentication/action';
import { AssignTemporaryRole, DeleteTempRole, DeleteUserEmployee } from '../../services/result.service';
import EmployeeListStyle from './EmployeeList';
import ProfilePic from '../../assets/images/profile_pic.png';
import { Alert } from '@material-ui/lab';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
    },
  },
  input: {
    width: '15.5vw',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 13,
    fontWeight: '500',
    padding: 10,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow, TableCell);

const EmployeeList = ({
  empList, total,
  handleNext,
  createUser,
  employee_listing,
  fetchSelectRoles,
  selectRoles,
  addTempRole,
  viewPermission,
  deleteEmployee,
  loginToAgentAccount,
  sortProperties,
  sortBy,
  order,
}) => {
  const handleChange = () => {
    handleNext();
  };
  const useStyles = makeStyles((theme) => EmployeeListStyle(theme));
  const classes = useStyles();
  const history = useHistory();
  const [tempRole, setTempRole] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [tempRoleuser, setTempRoleUser] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteEmployeeModal, setDeleteEmployeeModal] = useState(false);
  const [selectedUser, setSelecteddUser] = useState({});
  const [viewTempRole, setViewTempRole] = useState(false);
  const [tempRoles, setTempRoles] = useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMesage, setAlertMessage] = React.useState('');
  const [sort, setSort] = useState(false);
  const handleClick = (row) => {
    history.push(`/admin/createuser?userId=${row._id}`);
  };
  const [tempRoleData, setTempRoleData] = useState({
    role: '',
    startDate: '',
    endDate: '',
  });
  useEffect(() => {
    fetchSelectRoles();
  }, [fetchSelectRoles]);
  useEffect(() => {
    if (empList.length && sort) {
      setSort(false);
    }
  }, [empList.length, sort]);
  useEffect(() => {
    if (selectRoles && roles.length) {
      setAllRoles(selectRoles);
    }
    if (allRoles.length) {
      setTempRoleData({
        ...tempRoleData,
        role: allRoles[0]._id,
      });
    }
  }, [selectRoles, roles.length]); // eslint-disable-line react-hooks/exhaustive-deps
  const openTempRole = (row) => {
    setTempRole(true);
    setTempRoleUser(row._id);
    const data = [];
    data.push(row.role_id);
    if (row.tempRoles && row.tempRoles.length) {
      // eslint-disable-next-line array-callback-return
      row.tempRoles.map((tmpRole) => {
        data.push(tmpRole.role);
      });
    }
    setRoles(data);
  };

  const handleClose = () => {
    setTempRole(false);
    setRoles([]);
    setTempRoleUser('');
  };
  const handleRoleChange = (evt) => {
    const { value } = evt.target;
    setTempRoleData({
      ...tempRoleData,
      [evt.target.name]: value,
    });
  };
  const AsssignTempRole = async () => {
    await AssignTemporaryRole(tempRoleData, tempRoleuser).then((res) => {
      if (res.data) {
        setTempRole(false);
        addTempRole();
      }
    });
  };
  const deleteTempRole = async () => {
    await DeleteTempRole(currentRole, tempRoleuser).then((res) => {
      setDeleteModal(false);
      setTempRoleUser('');
      setViewTempRole(false);
      setCurrentRole('');
      addTempRole();
    });
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setCurrentRole('');
  };
  const openDeleteModal = (tmprole) => {
    setCurrentRole(tmprole);
    setDeleteModal(true);
  };
  const openViewTempRoleModal = (row) => {
    setViewTempRole(true);
    setTempRoleUser(row._id);
    setTempRoles(row.tempRoles);
  };
  const handleCloseDeleteEmployeeModal = () => {
    setDeleteEmployeeModal(false);
    setSelecteddUser({});
  };
  const deleteEmployeeConfirm = (row) => {
    setSelecteddUser(row);
    setDeleteEmployeeModal(true);
  };
  const loginAsAgent = async(row) => {
    await loginToAgentAccount({id: row._id,email :row.email})
  }
  const deleteEmployeeUser = async () => {
    setDeleteEmployeeModal(false);
    await DeleteUserEmployee(selectedUser._id).then((res) => {
      if (!res.data.success) {
        setAlertMessage(res.data.message);
        setOpenAlert(true);
        window.scrollTo(0, 0);
      } else {
        deleteEmployee();
      }
    });
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    setAlertMessage('');
  };
  
  const today = moment(new Date()).format('YYYY-MM-DD');
  return (
    <>
    {openAlert ? (
        <Alert
          variant="filled"
          severity="error"
          onClose={handleAlertClose}
          className={classes.errorAlert}
        >
          {alertMesage}
        </Alert>
      ) : null}
      {(employee_listing && empList.length === 0) ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : ''}
      <div className={classes.outer}>
        {empList.length === 0 && !sort
          ? (
            <div className={classes.noData}>
              <img
                src={noEmployees}
                height={55}
                width={54}
                alt="logo"
              />
              <h3 className={classes.noAssign}>
                No Employees added in your list yet!
              </h3>
              <p className={classes.noAssignPara}>Let's add your first employee details</p>
              <Button
                variant="outlined"
                color="primary"
                className={classes.addButton}
                onClick={createUser}
              >
                <span className={classes.plus}>+</span>
                {' '}
                ADD NEW USER
              </Button>
            </div>
          )
          : (
            <div className={classes.inner}>
              <InfiniteScroll
                dataLength={empList.length}
                next={handleChange}
                hasMore={empList.length < total}
                loader={<p>Loading...</p>}
              >
                <TableContainer component={Paper} className={classes.root}>
                  <Table aria-label="sims table" className={classes.table}>
                    <TableHead className={classes.thead}>
                      <TableRow>
                        <TableCell padding="none" className={classes.hard_left_action}>
                          ACTIONS
                        </TableCell>

                        <TableCell
                          padding="none"
                          className={classes.hard_left_head}
                        >
                          <TableSortLabel
                            direction={sortBy === 'firstName' ? order : 'asc'}
                            active
                            IconComponent={ArrowDropDownIcon}
                            onClick={() => {sortProperties('first_name');
                            setSort(true);
                            }}
                          >
                            FIRST NAME
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.next_left_head}
                        >
                          <TableSortLabel
                            direction={sortBy === 'lastName' ? order : 'asc'}
                            active
                            IconComponent={ArrowDropDownIcon}
                            onClick={() => {sortProperties('last_name');
                              setSort(true);
                            }}
                          >
                            LAST NAME
                          </TableSortLabel>
                        </TableCell>
                        <TableCell padding="none" className={classes.heading}>
                          EMAIL ID
                        </TableCell>
                        <TableCell className={classes.role}>
                          <TableSortLabel
                            active
                            IconComponent={ArrowDropDownIcon}
                            direction={sortBy === 'role' ? order : 'asc'}
                            onClick={() => { sortProperties('role');
                              setSort(true);
                            }}
                          >
                            ROLE
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.permissions} padding="none">
                          PERMISSIONS
                        </TableCell>
                        <TableCell className={classes.tempRole}>
                          TEMP ROLES
                        </TableCell>
                        <TableCell padding="none" className={classes.heading}>
                            GROUPS
                        </TableCell>
                        <TableCell className={classes.heading}>
                          <TableSortLabel
                            active
                            IconComponent={ArrowDropDownIcon}
                            direction={sortBy === 'status' ? order : 'asc'}
                            onClick={() => { sortProperties('status');
                              setSort(true);
                            }}
                          >
                            STATUS
                          </TableSortLabel>
                        </TableCell>
                        <TableCell padding="none" className={classes.heading}>
                          <TableSortLabel
                            active
                            IconComponent={ArrowDropDownIcon}
                            direction={sortBy === 'title' ? order : 'asc'}
                            onClick={() => { sortProperties('title');
                              setSort(true);
                            }}
                          >
                            TITLE
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.heading}>
                          PHONE NUMBER
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                      {empList && empList.map((row, index) => (
                        <StyledTableRow
                          key={index}
                          className={classes.tableRow}
                        >
                          <TableCell
                            className={(index % 2) !== 0
                              ? classes.even_left_action : classes.hard_left_action_tbody}
                          >
                            <Tooltip
                              title={
                            !permissionCheck(
                              AdminsPermissions.employee_list.update.task,
                              AdminsPermissions.employee_list.update.type,
                            )
                              ? "You don't have permission to Edit"
                              : 'Edit'
                          }
                              placement="top"
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={permissionCheck(
                                    AdminsPermissions.employee_list.update.task,
                                    AdminsPermissions.employee_list.update.type,
                                  ) ? classes.buttonSqr : classes.btnDisableSqr}
                                  disableElevation
                                  onClick={() => handleClick(row)}
                                >
                                  <EditIcon
                                    style={{ fontSize: 15 }}
                                  />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip
                              title={
                            !permissionCheck(
                              AdminsPermissions.employee_list.delete.task,
                              AdminsPermissions.employee_list.delete.type,
                            )
                              ? "You don't have permission to Delete"
                              : 'Delete'
                          }
                              placement="top"
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={permissionCheck(
                                    AdminsPermissions.employee_list.delete.task,
                                    AdminsPermissions.employee_list.delete.type,
                                  ) ? classes.buttonEditSqr : classes.btnEditDisableSqr}
                                  disableElevation
                                  onClick={() => deleteEmployeeConfirm(row)}
                                >
                                  <DeleteIcon
                                    style={{ fontSize: 15 }}
                                  />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip
                              title="Login"
                              placement="top"
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={(row.role).toLowerCase() === 'agent' ? classes.buttonEditSqr : classes.btnLogin}
                                  onClick={()=>loginAsAgent(row)}>
                                  <HowToRegIcon style={{ fontSize: 15 }}/>
                                </Button>
                              </span>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            padding="none"
                            className={
                            (index % 2) !== 0 ? classes.even_left : classes.hard_left
                          }
                          >
                            <div className={classes.built}>
                              <Avatar
                                src={row?.profile_pic_url?row.profile_pic_url:ProfilePic}
                                alt="profile"
                                style={{ marginRight: '10px' }}
                                className={classes.small}
                              />
                              <div className={classes.firstNameCell}>{row.first_name}</div>
                            </div>
                          </TableCell>
                          <TableCell
                            padding="none"
                            className={
                            (index % 2) !== 0 ? classes.even_next_left : classes.next_left
                          }
                          >
                            {row.last_name}
                          </TableCell>
                          <TableCell className={classes.emailIdCell} padding="none">{row.email}</TableCell>
                          <TableCell className={classes.roleCell}>{row.role}</TableCell>
                          <TableCell align="left" className={classes.permissionsTableCell} onClick={() => viewPermission(row)}>VIEW</TableCell>
                          <TableCell align="left" style={{ textAlign: 'center' }}>
                            {row.tempRoles && row.tempRoles.length
                              ? (
                                <span
                                  className={classes.permissionsTableCell}
                                  onClick={() => openViewTempRoleModal(row)}
                                >
                                  VIEW TEMP ROLES
                                </span>
                              ) : ''}
                            <AddIcon
                              className={classes.addIcon}
                              onClick={() => openTempRole(row)}
                            />
                          </TableCell>
                          <TableCell className={classes.noWrap}>
                            { (row.groups.toString()).replace(/,/g, ', ')}
                          </TableCell>
                          <TableCell className={classes.statusCell}>{row.status}</TableCell>
                          <TableCell className={classes.titleCell} padding="none" align="left">{row.title}</TableCell>
                          <TableCell className={classes.phoneNumberCell} align="left">{row.phone_number}</TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfiniteScroll>
            </div>
          )}
      </div>
      <Dialog
        open={deleteEmployeeModal}
        onClose={handleCloseDeleteEmployeeModal}
        aria-labelledby="alert-dialog-title"
        className={classes.Deletedialog}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Employee</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.text}>
            {`Are you sure you want to delete employee - ${selectedUser.first_name} ${selectedUser.last_name}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteEmployeeModal}
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
          <Button
            autoFocus
            className={classes.deleteEmployee}
            onClick={deleteEmployeeUser}
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={tempRole}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        className={classes.dialog}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Assign Temporary Role</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4} className={classes.centerLabel}>
              Role
            </Grid>
            <Grid item xs={8}>
              <NativeSelect
                lableid="selectLable"
                id="selectRole"
                name="role"
                input={<BootstrapInput />}
                onChange={handleRoleChange}
                variant="outlined"
                required
                fullWidth
                className={classes.arrowIcon}
                IconComponent={KeyboardArrowDownIcon}
              >
                {allRoles.map((role) => (
                  <option key={role._id} value={role._id}>
                    {role.name}
                  </option>
                ))}
              </NativeSelect>
            </Grid>
            <Grid item xs={4} className={classes.centerLabel}>
              Start Date
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="date"
                onChange={handleRoleChange}
                type="date"
                name="startDate"
                defaultValue="dd/mm/yyyy"
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{
                  min: today,
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.centerLabel}>
              End Date
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="date"
                onChange={handleRoleChange}
                type="date"
                name="endDate"
                defaultValue="dd/mm/yyyy"
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{
                  min: today,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Tooltip
            title={
              !permissionCheck(
                AdminsPermissions.roles.update.task,
                AdminsPermissions.roles.update.type,
              )
                ? "You don't have permission to update the role"
                : ''
            }
            placement="top"
          >
            <span>
              <Button
                autoFocus
                className={classes.assignRole}
                onClick={AsssignTempRole}
                disabled={tempRoleData.role === ''
                  || tempRoleData.startDate === ''
                  || tempRoleData.endDate === ''
                  || !permissionCheck(
                    AdminsPermissions.roles.update.task,
                    AdminsPermissions.roles.update.type,
                  )}
              >
                Assign Role
          </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        className={classes.Deletedialog}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this temporary role
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteModal}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            className={classes.assignRole}
            onClick={deleteTempRole}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={viewTempRole}
        onClose={() => setViewTempRole(false)}
        aria-labelledby="alert-dialog-title"
        className={classes.dialog}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">View Temporary Role</DialogTitle>
        <DialogContent>
          <Table aria-label="sims table">
            <TableHead>
              <TableRow>
                <TableCell>Temp Role</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tempRoles && tempRoles.map((tmpRole) => (
                <StyledTableRow key={tmpRole.role}>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{tmpRole.roleName}</TableCell>
                  <TableCell>{tmpRole.startDate}</TableCell>
                  <TableCell>{tmpRole.endDate}</TableCell>
                  <TableCell>
                    <DeleteIcon
                      className={classes.delete}
                      onClick={() => openDeleteModal(tmpRole.role)}
                      fontSize="small"
                    />
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => { setViewTempRole(false); }}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

EmployeeList.propTypes = {
  empList: PropTypes.array,
  total: PropTypes.number,
  handleNext: PropTypes.func,
  createUser: PropTypes.func,
  fetchSelectRoles: PropTypes.func,
  employee_listing: PropTypes.bool,
  selectRoles: PropTypes.array,
  addTempRole: PropTypes.func,
  deleteEmployee: PropTypes.func,
  viewPermission: PropTypes.func,
  loginToAgentAccount: PropTypes.func,
};

const mapStateToProps = (state) => ({
  membersList: state.sim.membersList,
  employee_listing: state.sim.employee_listing,
  selectRoles: state.admin.selectRoles,
});

const mapDispatchToProps = { fetchEmployeeList, fetchSelectRoles, loginToAgentAccount };

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
