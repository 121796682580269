import React from 'react';
import { Checkbox, Input, NativeSelect } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useCheckboxStyles, useStylesBootstrap } from './KeywordsCheckbox';

const KeywordsCheckbox = ({
  name,
  value,
  handleCheckboxChange,
  handleInputChange,
  fieldTitleIndex,
  fieldIndex,
  scorable,
  save,
  fieldType,
  options,
}) => {
  const classes = useCheckboxStyles();

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  return (
    <FormControl className={classes.formControl}>
      <FormHelperText>{name}</FormHelperText>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        { fieldType.toLowerCase() === 'picklist'
          ? (
            <NativeSelect
              value={value}
              onChange={(e) => handleInputChange(e, fieldTitleIndex, fieldIndex)}
              className={classes.select}
              fullWidth
              required
              endAdornment={(
                <InputAdornment position="start">
                  <BootstrapTooltip
                    placement="top"
                    title="Please check this box for each answers if they are scorable"
                  >
                    <Checkbox
                      name={name}
                      value={name}
                      color="primary"
                      className={classes.checkbox}
                      checked={scorable}
                      onChange={(e) => handleCheckboxChange(e, fieldTitleIndex, fieldIndex)}
                    />
                  </BootstrapTooltip>
                </InputAdornment>
                  )}
            >
              { options && options.map((option) => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </NativeSelect>
          )
          : (
            <Input
              fullWidth
              value={value}
              className={classes.textFont}
              onBlur={save}
              onChange={(e) => handleInputChange(e, fieldTitleIndex, fieldIndex)}
              endAdornment={(
                <InputAdornment position="start">
                  <BootstrapTooltip
                    placement="top"
                    title="Please check this box for each answers if they are scorable"
                  >
                    <Checkbox
                      name={name}
                      value={name}
                      color="primary"
                      className={classes.checkbox}
                      checked={scorable}
                      onChange={(e) => handleCheckboxChange(e, fieldTitleIndex, fieldIndex)}
                    />
                  </BootstrapTooltip>
                </InputAdornment>
                  )}
            />
          )}
      </div>
    </FormControl>
  );
};

KeywordsCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleCheckboxChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  fieldTitleIndex: PropTypes.number,
  fieldIndex: PropTypes.number,
  scorable: PropTypes.bool,
  save: PropTypes.func,
  fieldType: PropTypes.string,
  options: PropTypes.array,
};

export default KeywordsCheckbox;
