import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import {
  Divider,
  Button,
  Typography,
  Box,
  Tooltip,
  CircularProgress,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import Levels from "./Levels.jsx";
import {
  fetchStats,
  getAssignmentStatus,
  saveSimTitle,
  resetStats,
  resetVideoStats,
  saveCurrentSimProductType,
} from "../../redux/sims/actions";
import { useStyles } from "./DetailsScreen";
import { Redirect } from 'react-router-dom';
import testSpeakerAudio from '../../assets/audio/daffy.mp3';

const DetailsScreen = ({
  sim,
  open,
  handleClose,
  stats,
  fetchStats,
  getAssignmentStatus,
  saveSimTitle,
  assignmentData,
  currentAssignmentStatus,
  resetStats,
  status,
  userDetails,
  resetVideoStats,
  saveCurrentSimProductType,
}) => {
  const classes = useStyles();
  const [enableRedirect, setEnableRedirect] = useState(false);
  const [playTutorial, setPlayTutorial] = useState(false);
  useEffect(() => {
    const userId =
      assignmentData?.assigned_to_id || assignmentData?.agent_user_id;
    fetchStats(sim._id, userId);
    if (sim.product_id && sim.product_id.name) {
      saveCurrentSimProductType(sim.product_id.name);
    }
  }, [sim._id, fetchStats, assignmentData, sim.product_id,saveCurrentSimProductType]);
  useEffect(() => {
    if (assignmentData && Object.keys(assignmentData).length > 0) {
      const payload = assignmentData?.reviewer
        ? assignmentData.reviewer
        : false;
      if (assignmentData.assignment_id) {
        getAssignmentStatus(assignmentData.assignment_id, payload);
      }
    }
    saveSimTitle(sim.title);
  }, [assignmentData, getAssignmentStatus, sim.title, saveSimTitle]);

  const playAudio = () => {
    const audioEl = document.getElementsByClassName("audio-element")[0];
    audioEl.play();
  };

  const closeDetailScreen = () => {
    resetStats();
    if (!assignmentData || userDetails.role.toLowerCase() !== 'agent') {
      handleClose(false);
    } else {
      setEnableRedirect(true);
    }
  };
  useEffect(() => {
    if (userDetails.role.toLowerCase() === 'agent') {
      resetVideoStats();
    }
  })

  if (enableRedirect && userDetails.role.toLowerCase() === 'agent') {
    return (
      <Redirect to={'/dashboard'} />
    );
  }
  if (localStorage.getItem('state')) localStorage.removeItem('state');
  return (
    <div>
      <Dialog
        onClose={closeDetailScreen}
        aria-labelledby="details-screen"
        open={open}
        fullWidth
        className={classes.root}
      >
        <div className={classes.modalBody}>
          <DialogTitle id="details-screen" className={classes.title}>
            <br />
            {sim.title}
          </DialogTitle>
          <Divider className={classes.divider} />
          <Box display="flex" justifyContent="space-between" mb={3}>
            <div>
              <Typography variant="body1" className={classes.description}>
                {sim.description}
              </Typography>
              <Typography variant="body1" className={classes.description}>
                {sim.brief_description}
              </Typography>
            </div>
            
            <div>
              {sim && sim.tutorial_video_url &&
                <Button
                  variant="contained"
                  className={classes.tutorialBtn}
                  size="small"
                  disableElevation
                  onClick={() => setPlayTutorial(true)}
                >
                  TUTORIAL VIDEO
                </Button>
             }
             
              <Tooltip
                title="If you hear the Daffy Duck, your speakers are good to go!"
                placement="bottom"
              >
                <span>
                  <Button
                    variant="contained"
                    className={classes.testSpeakerBtn}
                    size="small"
                    disableElevation
                    onClick={playAudio}
                  >
                    TEST SPEAKERS
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Box>
          <audio className="audio-element">
            <source src={testSpeakerAudio} />
          </audio>
          {stats && stats.result && stats.result.levels ? (
            stats.result.levels.map((level, index) => {
              const simType = sim.type ? sim.type : sim.sim_type
              return (
                <Levels
                  level={level}
                  key={level.level_id}
                  index={index}
                  simId={sim._id}
                  document={sim.documents ? sim.documents : ''}
                  hotLinks={sim.hot_links ? sim.hot_links : ''}
                  captivate_learning_video_url={sim.captivate_learning_video_url ? sim.captivate_learning_video_url : ''}
                  captivate_learning_url={sim.captivate_learning_url ?  sim.captivate_learning_url : ''}
                  assignmentData={assignmentData}
                  type={assignmentData?.type ? assignmentData.type : simType}
                  currentAssignmentStatus={currentAssignmentStatus}
                />
              )
            })
          ) : Object.keys(stats).length === 0 && (status === "404" || status === "500") ? (
            <Alert severity="warning">
              {" "}
              under progress! Level not yet drafted completely
            </Alert>
          ) : (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )}
        </div>
        <Button
          aria-label="close"
          className={classes.closeButton}
          onClick={closeDetailScreen}
        >
          <CloseIcon fontSize="small" />
        </Button>
      </Dialog>
      <Dialog
        open={playTutorial}
        onClose={() => { setPlayTutorial(false); }}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">Video Tutorial</DialogTitle>
        <DialogContent>
          <video
            width="1000"
            height="700"
            controls
            autoPlay
          >
            <source src={sim.tutorial_video_url} type="video/mp4" />
          </video>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPlayTutorial(false)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DetailsScreen.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  sim: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    brief_description: PropTypes.string,
    sim_type: PropTypes.string,
  }),
  stats: PropTypes.object,
  fetchStats: PropTypes.func,
  getAssignmentStatus: PropTypes.func,
  saveSimTitle: PropTypes.func,
  assignmentData: PropTypes.object,
  currentAssignmentStatus: PropTypes.object,
  resetStats: PropTypes.func,
  status: PropTypes.any,
  userDetails: PropTypes.object,
  resetVideoStats: PropTypes.func,
  saveCurrentSimProductType: PropTypes.func,
};

const mapStateToProps = (state) => ({
  status: state.sim.status,
  stats: state.sim.stats,
  currentAssignmentStatus: state.sim.currentAssignmentStatus,
  userDetails: state.login.user,
});

const mapDispatchToProps = {
  fetchStats,
  getAssignmentStatus,
  resetStats,
  saveSimTitle: saveSimTitle,
  resetVideoStats,
  saveCurrentSimProductType,
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailsScreen);
